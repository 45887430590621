import { IMerchStore } from ".";

export function resolveMerchstoreUrl(merchStore: IMerchStore): string {
  if(merchStore.customDomain) {
    if (merchStore.customDomain.split(".").length < 3) {
      return `https://www.${merchStore.customDomain}`
    } else {
      return `https://${merchStore.customDomain}`
    }
  }

  return `https://${merchStore.subdomain}.merchologysolutions.com`
}