import { MdErrorOutline } from 'react-icons/md';

import './styles.scss';

export type FormikInput = {
  id: string;
  type: string;
  name: string;
  label: string;
  value: string;
  defaultValue?: string;
  error?: string | null | undefined;
  disabled?: boolean;
  onBlur: (e: React.ChangeEvent<any>) => void;
  onChange: (e: React.ChangeEvent<any>) => void;
}

export const FormikInput = (props: FormikInput) => {
  const { id, name, value, type, label, error, disabled, onChange, onBlur } = props;
  return (
    <>
      <div className='form-input-container flex flex-col w-full form-input mb-5'>
        {!!value &&
          <label htmlFor={name} className={`textbox-label`} >
            {props.label}
          </label>
        }
        <input
          id={id}
          name={name}
          type={type}
          disabled={disabled}
          placeholder={label || ''}
          defaultValue={value || ''}
          autoComplete='off'
          autoCorrect='off'
          autoCapitalize='off'
          spellCheck='false'
          onBlur={onBlur}
          onChange={onChange}
          className={`form-input w-full ${!value ? 'no-label' : ''} ${error ? 'error' : ''}`}
        />
      </div>
      {error && (
        <div className='flex flex-row items-center mb-2 text-red-500'>
          <MdErrorOutline />
          <span className='text-xs ml-2'>{error}</span>
        </div>
      )}
    </>
  )
}
