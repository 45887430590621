import Modal from "react-responsive-modal";
import axios from "axios";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { buildCheckoutFields } from "@merchstores/admin/components/MerchStoreGiftCards/GiftCardOrderCheckout";
import { IGiftCardSelection } from "@merchstores/admin/components/MerchStoreGiftCards/GiftCardOrderCheckout";
import { requestCheckoutUrl } from "@merchstores/admin/components/MerchStoreGiftCards/GiftCardOrderCheckout";
import { getMerchStoreGiftCardsStatus } from "@merchstores/admin/api/merchstores/giftCards";
import { ICheckoutStatus } from "@merchstores/admin/components/MerchStoreOrderCheckout";
import { ICombinedOrder } from "@merchstores/admin/types/shopifyCombinedOrder";
import { FormInputNumber } from "@merchstores/shared/elements/FormInputNumber";
import { IOrderItem } from "@merchstores/admin/types/merchstoreOrder";
import { IMerchStore } from "@merchstores/admin/components/MerchStore";
import { FormSelect } from "@merchstores/shared/elements/FormSelect";
import { CTA } from "@merchstores/shared/elements/Cta";
import "@merchstores/admin/components/AdminModal/AdminModal.scss";

interface IGiftCardProps {
  open: boolean;
  onClose?(): void;
  storeCode: string;
  storeSubdomain: string;
  merchStore: IMerchStore;
  combinedOrder?: ICombinedOrder;
  isMobile: boolean;
}

interface IGiftCardVariant {
  id: string;
  title: string;
  sku: string;
  price: string;
}

interface IGiftCardValueOption {
  displayText: string;
  value: string;
  variant: IGiftCardVariant;
}

export const GiftCardModal: React.FC<IGiftCardProps> = (
  props: IGiftCardProps
) => {
  const [selectValue, setSelectValue] = useState("");
  const [cardQuantity, setCardQuantity] = useState(1);
  const [availableGiftCardVariants, setAvailableGiftCardVariants] = useState([]);
  const [currentCardVariant, setCurrentCardVariant] = useState(null);
  const [, setGiftCardsStatus] = useState(null);

  const merchStore = props.merchStore;

  const {
    register,
    setValue,
    formState: { errors },
  } = useForm();


  const setGiftCardVariants = async () => {
    try {
      const { data } = await axios.post("/.netlify/functions/fetchGiftCardVariants");
      const giftCardVariants = data.giftCardVariants;

      const cardsStatus = await getMerchStoreGiftCardsStatus(merchStore.storeCode);
      setGiftCardsStatus(cardsStatus);

      let currentCardVariantFound = null;

      if (cardsStatus.currentCardVariantId) {
        currentCardVariantFound = giftCardVariants.find(
          (variant: IGiftCardVariant) => variant.id === cardsStatus.currentCardVariantId
        )
      }

      if (currentCardVariantFound) {
        setCurrentCardVariant(currentCardVariantFound);
      }

      configureGiftCardValueOptions(giftCardVariants);
    } catch (err) {
      console.error(`Unable to fetch gift card variants`, err);
    }
  };

  useEffect(() => {
    setGiftCardVariants();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const configureGiftCardValueOptions = (
    availableGiftCardVariants: IGiftCardVariant[]
  ): IGiftCardValueOption[] => {
    const options: IGiftCardValueOption[] = [];
    availableGiftCardVariants.map((variant) => {
      const displayText = String(variant.title).trim().replace(/\.00$/g, "");

      options.push({
        displayText: displayText,
        value: variant.id,
        variant: variant
      });
    });
    return setAvailableGiftCardVariants(options);
  };

  const addGiftCards = (
    selectedValue: IGiftCardValueOption,
    giftCardQuantity: number,
    merchStore: IMerchStore
  ) => {
    const orderItems: Array<IOrderItem> = [
      {
        variantId: selectedValue.variant.id,
        quantity: giftCardQuantity,
      }
    ];

    const giftCardValue = selectedValue.variant.price;
    const giftCardSelection = {
      giftCardValue,
      giftCardQuantity
    }

    proceedToCheckout(orderItems, merchStore, giftCardSelection);
  };

  async function proceedToCheckout(
    orderItems: Array<IOrderItem>,
    merchStore: IMerchStore,
    giftCardSelection: IGiftCardSelection
  ): Promise<ICheckoutStatus> {

    const giftCardsStatus = await getMerchStoreGiftCardsStatus(merchStore.storeCode);
    const checkoutFields = buildCheckoutFields(orderItems, merchStore, giftCardsStatus, giftCardSelection);

    const checkoutResponse = await requestCheckoutUrl(checkoutFields).catch((err: unknown) => {
      const failedResponse: ICheckoutStatus = {
        checkoutUrl: '',
        errors: [{
          code: 'UNEXPECTED_ERROR',
          field: '',
          message: String(err)
        }]
      };

      return failedResponse;
    });

    if (checkoutResponse.checkoutUrl) {
      window.location.assign(checkoutResponse.checkoutUrl);
    }

    return checkoutResponse;
  }

  useEffect(() => {
    onQuantityChange(cardQuantity);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardQuantity]);

  const onQuantityChange = (value: number) => {
    if (cardQuantity > 0) {
      setCardQuantity(value);
    } else {
      setCardQuantity(1);
    }
  };

  const onModalClose = () => {
    setSelectValue("");
    setCardQuantity(1);
    if (props.onClose) {
      props.onClose();
    }
  };

  return (
    <Modal
      open={props.open}
      onClose={onModalClose}
      center
      classNames={{ modal: "admin-modal" }}
      blockScroll
    >
      <h2 className="text-sm text-merch-dark-gray font-bold">
        {currentCardVariant ?
          "Buy Additional Gift Cards" : "Buy Gift Cards"}
      </h2>
      
      <p className="text-base">
          Choose your gift card value and quantity. You will be redirected to checkout to complete your purchase.
      </p>

      <div className="flex flex-row justify space-x-3 mt-6 mb-6">
        <FormSelect
          name="gift-card-value"
          label="Value"
          register={register}
          errors={errors}
          setValue={setValue}
          emptyText={"$0"}
          default={"$0"}
          options={availableGiftCardVariants}
          onChange={setSelectValue}
          selectStyle="two"
        />
        <FormInputNumber
          name="gift-card-quantity"
          label="Quantity"
          required={true}
          positiveOnly={true}
          integerOnly={true}
          register={register}
          errors={errors}
          defaultValue={cardQuantity}
          onChange={onQuantityChange}
        />
      </div>
      <div className="flex flex-col">
        <CTA
          formSubmit={false}
          disabled={!cardQuantity || !selectValue}
          size={"large"}
          type={"primary"}
          classes="gift-card-add-checkout-button w-full"
          onClick={() => {
            addGiftCards(selectValue, cardQuantity, merchStore);
          }}
        >
          Continue to Checkout
        </CTA>
        <CTA
          formSubmit={false}
          size={"large"}
          type={"secondary"}
          classes="gift-card-add-cancel-button w-full	mt-2"
          onClick={onModalClose}
        >
          Cancel
        </CTA>
      </div>
    </Modal>
  );
};
