interface ITrackingLinkProps {
  trackingCode: string,
  children?: string | HTMLElement | JSX.Element
}

const buttonStyles = [ 
  `flex rounded-md font-bold items-center justify-center`,
  `text-12 md:text-14 px-2.5`,
  `border border-gray-300 shadow-sm`,
  `bg-white`,
  `min-w-standard-button py-2 text-sm w-full`,
  `text-merch-orange`,
  `hover:bg-gray-50`
].join(' ');

export const TrackingLink = (props: ITrackingLinkProps): JSX.Element => {
  const trackingUrl = `https://www.fedex.com/fedextrack/?trknbr=${props.trackingCode}`;

  return (
    <a
      className={buttonStyles}
      href={trackingUrl}
      target="_blank"
      rel="noreferrer">
      {props.children ? props.children : 'Track Order'}
    </a>
  );
}