import { Indicator } from ".";
import { MdClear } from 'react-icons/md';

export const FilterIndicator = (props: Indicator) => {
    let { title } = props;
    const { onRemove } = props;

    if(title === 'active') {
        title = 'Active'
    } else if(title === 'draft') {
        title = 'Draft'
    } else if(title === 'closed') {
        title = 'Closed'
    } else if(title === 'pending') {
        title = 'Pending'
    } else if(title === 'submitted') {
        title = 'Submitted'
    }

    return (
        <div className="filters-chosen py-0.5 px-1.5 rounded">
            <span className="cursor-default">{title}</span>
            <span className="cursor-pointer px-0.5" onClick={onRemove}><MdClear size={16} /></span>
        </div>
    )
}