import BigNumber from "bignumber.js";

interface IOrderWithSubtotalPrice
{
  subtotalPrice: string;
}

interface IOrderWithTotalPrice
{
  totalPrice: string;
}

interface IOrderWithTotalTax
{
  totalTax: string;
}

interface IOrderWithItemsCount
{
  itemsCount?: number;
}

export function sumOrderListSubtotal(orders: Array<IOrderWithSubtotalPrice>) : BigNumber
{
  let subtotal = new BigNumber(0);

  orders.forEach(order => {
    subtotal = subtotal.plus(new BigNumber(order.subtotalPrice));
  });

  return subtotal;
}

export function sumOrderListTotal(orders: Array<IOrderWithTotalPrice>) : BigNumber
{
  let total = new BigNumber(0);

  orders.forEach(order => {
    total = total.plus(new BigNumber(order.totalPrice));
  });

  return total;
}

export function sumOrderListTotalTax(orders: Array<IOrderWithTotalTax>) : BigNumber
{
  let totalTax = new BigNumber(0);

  orders.forEach(order => {
    totalTax = totalTax.plus(new BigNumber(order.totalTax));
  });

  return totalTax;
}

export function sumOrderListItemsCount(orders: Array<IOrderWithItemsCount>) : BigNumber
{
  let totalItems = new BigNumber(0);

  orders.forEach(order => {
    totalItems = totalItems.plus(new BigNumber(order.itemsCount || 0));
  });

  return totalItems;
}

export default {
  sumOrderListTotal,
  sumOrderListSubtotal,
  sumOrderListTotalTax,
  sumOrderListItemsCount
}