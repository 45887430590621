import { useMutation, UseMutationResult } from "react-query";
import { reassignMerchStoreMemberGiftCard } from "@merchstores/admin/api/merchstores/giftCards";
import {
  IReassignMemberGiftCardParameters,
  IReassignMemberGiftCardResult,
} from "@merchstores/admin/types/giftCards";

type UseReassignMemberGiftCardResult = UseMutationResult<
  IReassignMemberGiftCardResult,
  unknown,
  IReassignMemberGiftCardParameters,
  unknown
>;

const useReassignMemberGiftCard = (): UseReassignMemberGiftCardResult => {
  return useMutation((params: IReassignMemberGiftCardParameters) =>
    reassignMerchStoreMemberGiftCard(params)
  );
}

export default useReassignMemberGiftCard;