import "./ProductSearch.scss";
import axios from "axios";
import { toast } from 'react-toastify';
import { useState, useEffect } from "react";
import { isNil } from "lodash";
import { Table } from "@merchstores/shared/elements/Table";
import { Checkbox } from "@merchstores/shared/elements/Checkbox";
import { Logo } from "@merchstores/shared/elements/Logo";
import { CTA } from "@merchstores/shared/elements/Cta";
import Modal from "react-responsive-modal";
import { SearchBar } from "@merchstores/shared/elements/SearchBar";
import { useContext } from "react";
import { Context } from "@merchstores/admin/context";
import { Loading } from "@merchstores/shared/components/Loading";
import { AiOutlineEye } from "react-icons/ai"
import { MerchStoreProductEdit } from "@merchstores/admin/components/CreateEditMerchStore/MerchStoreProductEdit";
import { IMerchStoreProduct } from "@merchstores/admin/components/CreateEditMerchStore/MerchStoreProductList";
import { IProductResult } from "@merchstores/admin/api/products/MerchStoreProducts";

const MIN_STOCK = 250;
const MAX_PRODUCTS_PER_STORE_FALLBACK = 50;
const MAX_PRODUCTS_PER_STORE = process.env.REACT_APP_MAX_PRODUCTS_PER_STORE || MAX_PRODUCTS_PER_STORE_FALLBACK;
const EMPTY_IMAGE_DATA_URL = '/shopify_blank_image_xs.png';

interface IProductSearchProps {
  open: boolean,
  onClose?(): void,
  storeCode: string,
  storeSubdomain: string,
  onProductsAdded?(products: IProductResult[]): void,
  disabledProductIds: string[],
  merchStore: any,
  isMobile: boolean,
  merchStoreProductsCount: number
}

interface IProduct {
  id: string,
  handle: string,
  image: string,
  imageThumbnail?: string;
  title: string,
  totalInventory: number,
}

export function resolveTotalProductsAllowed(): number {
  let maxProductsLimit;

  try {
    maxProductsLimit = parseInt(String(MAX_PRODUCTS_PER_STORE));
  } catch (err: unknown) {
    // If not a number is in the env var we set it to 50
  }

  return maxProductsLimit ? maxProductsLimit : MAX_PRODUCTS_PER_STORE_FALLBACK;
}

export async function getBaseProductDetails(baseProductId: string) {
  const productDetailsResponse = await axios.post('/.netlify/functions/fetchBaseProductDetails', {
    productId: baseProductId
  });

  if (!productDetailsResponse || !productDetailsResponse.data || !productDetailsResponse.data.success) {
    return null;
  }

  return productDetailsResponse.data.product;
}


export const ProductSearch: React.FC<IProductSearchProps> = (props: IProductSearchProps) => {
  const { userRole } = useContext(Context);
  const [productsList, setProductsList] = useState(undefined);
  const [productsTableData, setProductsTableData] = useState(undefined);
  const [addingProducts, setAddingProducts] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedProductPreviewId, setSelectedProductPreviewId] = useState(null);
  const [selectedProductPreviewData, setSelectedProductPreviewData] = useState(null) as [IMerchStoreProduct, any];
  const [isProductPreviewModalOpen, setIsProductPreviewModalOpen] = useState(false);

  const fetchProducts = async (queryValue: string) => {
    setLoading(true);
    await axios.post('/.netlify/functions/fetchBaseProducts', {
      query: queryValue,
    })
      .then(res => {
        setProductsList(res.data);
      })
      .catch(err => {
        console.error('fetchBaseProducts error:', err);
      })
      .finally(() => {
        setLoading(false);
      })
  }

  useEffect(() => {
    if (!isNil(selectedProductPreviewId)) {
      getBaseProductDetails(selectedProductPreviewId)
        .then(baseProduct => {
          const { id, title, handle, description, tags, images, variants } = baseProduct;
          const previewBaseProduct = {
            id,
            title,
            handle,
            tags,
            artworkSelected: 1,
            descriptionHtml: description,
            images: images.map(({ id, originalSrc }: any) => ({ id, src: originalSrc, altText: '' })),
            variants
          };
          setSelectedProductPreviewData(previewBaseProduct)
        })
    }
  }, [selectedProductPreviewId])


  const addProducts = () => {
    const selectedProducts = getSelectedProducts();

    setAddingProducts(true);
    const selectedProductIds: string[] = [];
    const selectedProductTitles: string[] = [];
    selectedProducts.forEach((product: IProduct) => {
      selectedProductIds.push(product.id);
      selectedProductTitles.push(product.title);
    });
    axios.post('/.netlify/functions/cloneProducts-background', {
      productIds: selectedProductIds,
      productTitles: selectedProductTitles,
      subdomain: props.storeSubdomain,
      storeCode: props.storeCode,
    })
    if (selectedProductIds.length) {
      toast.info(
        'Your products are being added to your store, but it may take a few minutes for them to be available to edit.'
      );
    }
    localStorage.setItem('productsSelected', JSON.stringify([]));
    if (props.onClose) {
      props.onClose();
    }
    if (props.onProductsAdded) {
      const productsAdded: IProductResult[] = [];
      selectedProducts.forEach((productAdded: IProduct) => {
        productsAdded.push({
          ...productAdded,
          id: `gid://shopify/Product/${productAdded.id}`,
          handle: '',
          tags: [],
          images: [
            {
              altText: '',
              id: '',
              src: productAdded.image,
            }
          ],
          descriptionHtml: "",
          productType: "",
          vendor: "",
          price: {
            amount: "",
            currency: ""
          },
          compareAtPrice: {
            amount: "",
            currency: ""
          },
          variants: [],
          createdAt: ""
        });
      })
      props.onProductsAdded(productsAdded);
    }

    setAddingProducts(false);
  }

  const getSelectedProducts = () => {
    const currentSelectionString = localStorage.getItem('productsSelected');
    if (currentSelectionString) {
      return JSON.parse(currentSelectionString);
    }
    else {
      return []
    }
  }

  const setSelectedCheckboxes = () => {
    const selectedProducts = getSelectedProducts();
    const checkboxDivs = document.querySelectorAll("div.product-checkbox") as NodeListOf<HTMLDivElement>;
    checkboxDivs.forEach(div => {
      const input = div.querySelector("input.product-checkbox") as HTMLInputElement;
      const svg = div.querySelector("svg");
      const inputValueSplit = input.value.split('-');
      if (selectedProducts.map((product: any) => product.id).includes(inputValueSplit[0])) {
        svg?.classList.add('visible');
        svg?.classList.remove('invisible');
      }
    });
  }

  const onSearchChange = (query: string) => {
    fetchProducts(query);
  }

  const onProductCheckboxChange = (newValue: boolean, id: string, value: string, ev?: any) => {
    let currentSelection = getSelectedProducts();
    const productObject = JSON.parse(Buffer.from(value, 'base64').toString());
    const totalSelectedProducts = props.merchStoreProductsCount + currentSelection.length;
    if (newValue) {
      const totalProductsAllowed = resolveTotalProductsAllowed();

      if (totalSelectedProducts < totalProductsAllowed) {
        currentSelection.push(productObject);
      } else {
        toast.error(`You can't select more than ${totalProductsAllowed} products.`);
        // In order to de-select the checkbox we need to re-trigger the click event. 
        // Since the checkbox selection is managed by react state, we need to delay the call a little.
        setTimeout(() => {
          const checkboxId = ev.target.id;
          const checkboxElement = document.querySelectorAll(`#${checkboxId}`) as NodeListOf<HTMLDivElement>;
          checkboxElement[0].click();
        }, 200)
      }
    } else {
      currentSelection = currentSelection.filter((product: any) => product.id !== productObject.id);
    }
    localStorage.setItem('productsSelected', JSON.stringify(currentSelection));
  }

  const onModalClose = () => {
    localStorage.setItem('productsSelected', JSON.stringify([]));
    if (props.onClose) {
      props.onClose();
    }
  }

  const handleProductPreviewModalOpened = (product: any) => {
    setIsProductPreviewModalOpen(true);
    setSelectedProductPreviewId(product.id);
  }

  const handleProductPreviewModalClosed = () => {
    setIsProductPreviewModalOpen(false);
    setSelectedProductPreviewId(null);
    setSelectedProductPreviewData(null);
  }

  useEffect(() => {
    fetchProducts('');
  }, [userRole])

  const afterTableRender = () => {
    setSelectedCheckboxes();
  }

  useEffect(() => {
    if (productsList) {

      const productsTableData = productsList.map((product: IProduct) => {

        const isProductAlreadyAdded = (
          props.disabledProductIds ?
            props.disabledProductIds.includes(product.id) :
            false
        );
        const productDisabled = isProductAlreadyAdded || product.totalInventory < MIN_STOCK;
        return {
          tr: {
            class: "",
            data: {
              productSelector: {
                desktopOnly: false,
                value: <Checkbox
                  id={product.id}
                  className={'product-checkbox'}
                  onChange={onProductCheckboxChange}
                  disabled={productDisabled}
                  isSelected={isProductAlreadyAdded ? isProductAlreadyAdded : undefined}
                  value={btoa(JSON.stringify({ ...product, descriptionHtml: '' }))}
                />
              },
              productImage: {
                desktopOnly: false,
                value: <Logo classes="product-thumbnail" imgUrl={product.imageThumbnail ? product.imageThumbnail : EMPTY_IMAGE_DATA_URL} />
              },
              productTitle: {
                desktopOnly: false,
                value: <div className={productDisabled ? 'opacity-50' : ''}>{product.title}</div>
              },
              productStock: {
                desktopOnly: true,
                value: product.totalInventory < MIN_STOCK ?
                  <div className='product-out-of-stock mr-10'>OUT OF STOCK</div> :
                  ''
              },
              productPreview: {
                desktopOnly: false,
                value: <CTA
                  size={'small'}
                  type={'secondary'}
                  onClick={() => { handleProductPreviewModalOpened(product) }}
                >
                  <AiOutlineEye color={'#999999'} />
                </CTA>
              }
            }
          }
        }
      });
      setProductsTableData(productsTableData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productsList, props.disabledProductIds])

  const productPreviewModalStyles = { modal: "rounded-3xl" };
  return (
    <Modal open={props.open} onClose={onModalClose} center classNames={{ modal: "product-search-modal" }} blockScroll>
      <Modal
        open={isProductPreviewModalOpen && !isNil(selectedProductPreviewId) && !isNil(selectedProductPreviewData)}
        classNames={productPreviewModalStyles}
        closeOnEsc={true}
        onClose={handleProductPreviewModalClosed}
        showCloseIcon={false}
        center>
        <MerchStoreProductEdit
          isViewMode={true}
          onProductPreviewClosed={handleProductPreviewModalClosed}
          selectedProduct={selectedProductPreviewData}
          setSelectedProduct={setSelectedProductPreviewData}
          isMobile={false}
          artworkOptions={props.merchStore.artworkOptions}
          userRole={userRole}
          storeCode={props.merchStore.storeCode}
          subdomain={props.merchStore.subdomain}
          fetchCollection={() => { null }}
          deleteProduct={() => { null }}
        />
      </Modal>
      <h2>Add Products</h2>
      <div>
        <div>
          <SearchBar placeholder="Product Search" onChange={onSearchChange} />
        </div>
        <div className='product-search-list-container'>
          {loading
            ? <Loading isLoading={loading} />
            : productsTableData && productsTableData.length > 0
              ? <Table
                name="member-list"
                filter={true}
                data={productsTableData}
                itemsName="products"
                displayFooter={false}
                usePagination={false}
                afterRender={afterTableRender}
              />
              : <div className='w-full text-center mt-20'>No products found</div>
          }
        </div>
      </div>
      <div className="block md:hidden my-5">
        <CTA
          disabled={addingProducts}
          formSubmit={false}
          size={'standard'}
          type={'primary'}
          classes="product-add-button mb-3"
          onClick={addProducts}
        >
          {!addingProducts ? `Add Products` : 'Adding...'}
        </CTA>
        <CTA
          formSubmit={false}
          size={'standard'}
          type={'secondary'}
          classes="product-cancel-button"
          onClick={onModalClose}
        >
          Cancel
        </CTA>
      </div>
      <div className="hidden md:flex justify-between m-5">
        <CTA
          formSubmit={false}
          size={'standard'}
          type={'secondary'}
          classes="product-cancel-button"
          onClick={onModalClose}
        >
          Cancel
        </CTA>
        <CTA
          disabled={addingProducts}
          formSubmit={false}
          size={'standard'}
          type={'primary'}
          classes="product-add-button"
          onClick={addProducts}
        >
          {!addingProducts ? `Add Products` : 'Adding...'}
        </CTA>
      </div>
    </Modal>
  )
}
