import './styles.scss';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { CTA } from '@merchstores/shared/elements/Cta';
import { FilterIndicator } from '@merchstores/shared/elements/FilterIndicator';
import { SortDropdown } from '@merchstores/shared/elements/SortDropdown';
import { FilterDropdown } from '@merchstores/shared/elements/FilterDropdown';
import { Table } from '@merchstores/shared/elements/Table';
import { Checkbox } from '@merchstores/shared/elements/Checkbox';
import { MdModeEdit } from 'react-icons/md';
import { useCallback } from 'react';
import { GroupOrderMemberListProps } from '.';
import { IGroupOrderMember } from '../';
import { InviteGroupOrderMember } from '../../InviteGroupOrderMember';
import { TrackingLink } from './TrackingLink';

export function GroupOrderMemberList(
  props: GroupOrderMemberListProps
): JSX.Element {
  const [membersList, setMembersList] = useState(undefined);
  const [membersTableData, setMembersTableData] = useState(undefined);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [orderError] = useState(false);
  const {
    order,
    uniqueId,
    fetchMembers,
    setSortOptionChosen,
    handleSubmissionEditOpen,
  } = props;

  const memberAndSubmissionDetails = props.memberAndSubmissionDetails || [];

  const [statusFilterOptions, setStatusFilterOptions] = useState([]);
  const [statusFilterChosen, setStatusFilterChosen] = useState('');

  const memberCheckboxChanged = useCallback(
    (newValue: boolean, id: string) => {
      let newSelectedMembers = selectedMembers;
      if (newValue) {
        newSelectedMembers = newSelectedMembers.concat(id);
      } else {
        newSelectedMembers = newSelectedMembers.filter((m: string) => m !== id);
      }
      setSelectedMembers(newSelectedMembers);
    },
    [selectedMembers]
  );

  const selectAllChanged = (newValue: boolean /*, id: string*/) => {
    if (newValue) {
      const allMemberIds = membersList.map(
        (m: IGroupOrderMember) => m.memberId
      );
      setSelectedMembers(allMemberIds);
    } else {
      setSelectedMembers([]);
    }
  };

  function onDeleteClick(): void {
    const deleteConfirmation = window.confirm(
      'Are you sure you want to delete these members?'
    );
    if (deleteConfirmation) {
      axios
        .post('/.netlify/functions/deleteGroupOrderMembers', {
          // TODO: pass real member Ids
          memberIds: selectedMembers,
        })
        .then((res) => {
          if (res.status === 200) {
            fetchMembers(props.uniqueId);
            setSelectedMembers([]);
          } else {
            alert(`An error ocurred deleting the member, please try again.`);
          }
        })
        .catch((err) => {
          alert(`An error ocurred deleting the member, please try again.`);
          console.error('An error ocurred deleting the member:', err);
        });
    }
  }

  function onDownloadClick(): void {
    const selectedMembersObjs = membersList.filter(
      (member: IGroupOrderMember) => {
        return selectedMembers.includes(member.memberId);
      }
    );
    const flattenedMembers = selectedMembersObjs.map((member: any) => {
      const newMember = {
        id: member._id,
        groupOrderId: member.groupOrderId,
        memberId: member.memberId,
        recipientId: member.recipientId,
        email: member.email,
        invited: member.invited,
        submission: member.submission ? member.submissionId : false,
        timestamp: member.submission?.timestamp,
        selectedArtwork: member.submission?.selectedArtwork,
        selectedPackage: member.submission?.selectedPackage,
        selectedStyle: member.submission?.selectedStyle,
        size: member.submission?.size,
        address1: member.submission?.address?.address1,
        address2: member.submission?.address?.address2,
        city: member.submission?.address?.city,
        company: member.submission?.address?.company,
        country: member.submission?.address?.country,
        countryIso: member.submission?.address?.countryIso,
        firstName: member.submission?.address?.firstName,
        lastName: member.submission?.address?.lastName,
        phone: member.submission?.address?.phone,
        state: member.submission?.address?.state,
        zip: member.submission?.address?.zip,
      };
      return newMember;
    });
    const headerKeySet: Set<string> = new Set();
    flattenedMembers.forEach((member: IGroupOrderMember) => {
      Object.keys(member).forEach((key) => headerKeySet.add(key));
    });
    const headers = Array.from(headerKeySet);
    const rows = [] as string[][];
    flattenedMembers.forEach((member: IGroupOrderMember) => {
      const rowsKeySet: Set<string> = new Set();
      Object.values(member).forEach((value) => rowsKeySet.add(value));
      rows.push(Array.from(rowsKeySet));
    });
    const csvRows = [] as string[];
    csvRows.push(headers.join(','));
    csvRows.push(...rows.map((row) => row.join(',')));
    const csvString = csvRows.join('\n');
    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    const date = new Date();
    const formattedDate = date.toISOString().split('T')[0];
    a.setAttribute(
      'download',
      `${order.groupOrderId}-export-${formattedDate}.csv`
    );
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  const checkShouldUpdate = () => {
    let shouldUpdate = false;

    memberAndSubmissionDetails.every(
      (memberWithSubmission: IGroupOrderMember) => {
        const memberMatch = membersList.filter((member: IGroupOrderMember) => {
          return (
            member.memberId === memberWithSubmission.memberId &&
            member.email === memberWithSubmission.email
          );
        });

        if (memberMatch[0].submissionId !== memberWithSubmission.submissionId) {
          shouldUpdate = true;
          return false;
        }
        return true;
      }
    );
    if (shouldUpdate) {
      fetchMembers(uniqueId);
    }
  };

  useEffect(() => {
    setMembersList(memberAndSubmissionDetails);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order.groupOrderId, statusFilterChosen, fetchMembers]);

  useEffect(
    () => {
      if (
        membersList &&
        membersList.length === memberAndSubmissionDetails.length
      ) {
        if (memberAndSubmissionDetails) {
          checkShouldUpdate();
        }
        const membersTableData = membersList.map(
          (member: IGroupOrderMember) => {
            if (memberAndSubmissionDetails) {
              memberAndSubmissionDetails.every(
                (memberWithSubmission: IGroupOrderMember) => {
                  if (
                    memberWithSubmission.submissionId === member.submissionId
                  ) {
                    member.submission = memberWithSubmission.submission;
                    return false;
                  } else {
                    return true;
                  }
                }
              );
            }

            const viewEditSubmissionAction = (): void => {
              if (!member.submissionId) {
                console.error(`Missing member submissionId`);
                return;
              }

              if (handleSubmissionEditOpen) {
                handleSubmissionEditOpen(
                  member.submissionId,
                  member.email,
                  member.submission,
                  readOnlySubmission
                );
              }
            };

            const readOnlySubmission =
              order.status === 'closed' && member.submissionId !== undefined;
            return {
              tr: {
                class: '',
                data: {
                  memberSelector: {
                    desktopOnly: false,
                    value: (
                      <Checkbox
                        isSelected={selectedMembers.includes(member.memberId)}
                        id={member.memberId}
                        className={`member-checkbox ${
                          selectedMembers.includes(member.memberId)
                            ? 'seleccionado'
                            : ''
                        }`}
                        onChange={memberCheckboxChanged}
                      />
                    ),
                  },
                  memberEmail: {
                    desktopOnly: false,
                    value: member.email,
                  },
                  memberId: {
                    desktopOnly: true,
                    value: member.memberId,
                  },
                  trackingStatus: {
                    desktopOnly: false,
                    value: member.trackingCode ? (
                      <TrackingLink trackingCode={member.trackingCode} />
                    ) : (
                      ''
                    ),
                  },
                  memberStatus: {
                    desktopOnly: false,
                    value: (
                      <div
                        className={`member-icon ${
                          member.submissionId ? 'green' : 'yellow'
                        }`}
                      ></div>
                    ),
                  },
                  actions: {
                    desktopOnly: true,
                    value: (
                      <CTA
                        classes={!member.submissionId ? 'opacity-50' : ''}
                        size="standard"
                        type="secondary"
                        onClick={viewEditSubmissionAction}
                      >
                        View{!readOnlySubmission ? '/Edit' : ''}
                      </CTA>
                    ),
                  },
                  actionsMobile: {
                    mobileOnly: true,
                    value: (
                      <div className="mx-2">
                        <CTA
                          size="small"
                          type="secondary"
                          icon={<MdModeEdit />}
                          onClick={viewEditSubmissionAction}
                        ></CTA>
                      </div>
                    ),
                  },
                },
              },
            };
          }
        );
        if (statusFilterChosen) {
          const submittedArray: IGroupOrderMember[] = [];
          const pendingArray: IGroupOrderMember[] = [];
          for (const member of membersTableData) {
            const memberStatus =
              member.tr.data.memberStatus.value.props.className;
            if (
              statusFilterChosen === 'submitted' &&
              memberStatus === 'member-icon green'
            ) {
              submittedArray.push(member);
            } else if (
              statusFilterChosen === 'pending' &&
              memberStatus === 'member-icon yellow'
            ) {
              pendingArray.push(member);
            }
          }
          setMembersTableData(
            statusFilterChosen === 'submitted' ? submittedArray : pendingArray
          );
        } else {
          setMembersTableData(membersTableData);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      membersList,
      selectedMembers,
      memberAndSubmissionDetails,
      handleSubmissionEditOpen,
      memberCheckboxChanged,
      statusFilterChosen,
    ]
  );

  useEffect(() => {
    /** 'Status' filter options */
    setStatusFilterOptions([
      {
        title: 'Submitted',
        id: 'statusFilterSubmitted',
        action: () => {
          setStatusFilterChosen('submitted');
        },
      },
      {
        title: 'Pending',
        id: 'statusFilterPending',
        action: () => {
          setStatusFilterChosen('pending');
        },
      },
    ]);
  }, []);

  const sortOptions = [
    {
      title: 'Member Name A - Z',
      id: 'name_a-z',
      action: () => {
        setSortOptionChosen('nameDesc');
      },
    },
    {
      title: 'Member Name Z - A',
      id: 'name_z-a',
      action: () => {
        setSortOptionChosen('nameAsc');
      },
    },
    {
      title: 'Status (Pending First)',
      id: 'status_pending',
      action: () => {
        setSortOptionChosen('statusPending');
      },
    },
    {
      title: 'Status (Submitted First)',
      id: 'status_submitted',
      action: () => {
        setSortOptionChosen('statusSubmitted');
      },
    },
  ];

  return (
    <div>
      <InviteGroupOrderMember
        order={props.order}
        memberAndSubmissionDetails={props.memberAndSubmissionDetails}
        display={props.showAddMember}
        onMembersAdded={props.onMembersAdded}
      />
      {orderError ? (
        <h3>TODO: BUILD AN ERROR VIEW</h3>
      ) : membersTableData ? (
        <>
          {membersTableData.length > 0 && (
            <>
              <div
                className={
                  'flex items-start justify-between dropdowns-container px-4 md:px-0'
                }
              >
                <div className="filter-dropdowns">
                  <FilterDropdown
                    title="Status"
                    list={statusFilterOptions}
                    chosenFilter={statusFilterChosen}
                    btnClass="status-members-page"
                  />
                </div>
                <div className="sort-orders-container">
                  <SortDropdown
                    title="Sort"
                    list={sortOptions}
                    defaultSort="name_a-z"
                  />
                </div>
              </div>
              <div className="filters-chosen-container px-4 md:px-0">
                {statusFilterChosen && (
                  <FilterIndicator
                    title={statusFilterChosen}
                    onRemove={() => setStatusFilterChosen('')}
                  />
                )}
              </div>
              <div className="flex mb-10 h-30">
                <Checkbox
                  isSelected={selectedMembers && selectedMembers.length > 0}
                  label={
                    selectedMembers && selectedMembers.length > 0
                      ? `${selectedMembers.length} selected`
                      : 'Select all'
                  }
                  className="member-checkbox items-center"
                  onChange={selectAllChanged}
                />
                {selectedMembers && selectedMembers.length > 0 ? (
                  <>
                    <CTA type="secondary" size="small" onClick={onDeleteClick}>
                      Delete
                    </CTA>
                    <div className="flex ml-2">
                      <CTA
                        type="secondary"
                        size="small"
                        onClick={onDownloadClick}
                      >
                        Download CSV
                      </CTA>
                    </div>
                  </>
                ) : (
                  ''
                )}
              </div>
            </>
          )}
          <div className="member-list-container">
            <Table
              name="member-list"
              filter={true}
              data={membersTableData}
              itemsName="members"
            />
          </div>
        </>
      ) : (
        ''
      )}
    </div>
  );
}
