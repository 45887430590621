import SummaryCard from "./SummaryCard";
import { moneyFormat } from "@merchstores/shared/components/Money";
import BigNumber from "bignumber.js";

interface GiftCardsBalanceSummaryProps {
  balanceSummary: {
    totalPurchasedBalance: string;
    totalAssignableBalance: string;
    totalAssignedBalance: string;
    totalUnassignedBalance: string;
    totalDisabledBalance: string;
    totalRedeemedBalance: string;
    totalRemainingBalance: string;
  }
}

const GiftCardsBalanceSummary = (props: GiftCardsBalanceSummaryProps): JSX.Element | null => {

  const { balanceSummary } = props;

  const totalPurchasedBalance = new BigNumber(balanceSummary.totalPurchasedBalance);
  const totalAssignableBalance = new BigNumber(balanceSummary.totalAssignableBalance);
  const totalAssignedBalance = new BigNumber(balanceSummary.totalAssignedBalance);
  const totalUnassignedBalance = new BigNumber(balanceSummary.totalUnassignedBalance);
  const totalDisabledBalance = new BigNumber(balanceSummary.totalDisabledBalance);
  const totalRedeemedBalance = new BigNumber(balanceSummary.totalRedeemedBalance);
  const totalRemainingBalance = new BigNumber(balanceSummary.totalRemainingBalance);

  const isPurchasedGreaterThanZero = totalPurchasedBalance.isGreaterThan(0);
  const isAssignableGreaterThanZero = totalAssignableBalance.isGreaterThan(0);
  const isAssignedGreaterThanZero = totalAssignedBalance.isGreaterThan(0);
  const isUnassignedGreaterThanZero = totalUnassignedBalance.isGreaterThan(0);
  const isDisabledGreaterThanZero = totalDisabledBalance.isGreaterThan(0);
  const isRedeemedGreaterThanZero = totalRedeemedBalance.isGreaterThan(0);
  const isRemainingGreaterThanZero = totalRemainingBalance.isGreaterThan(0);

  return (
    <div className="grid px-6 w-full items-center mb-30 gap-5 md:grid-cols-3 sm:px-0 sm:flex-row sm:grid-cols-1">
      {isPurchasedGreaterThanZero && <SummaryCard
        key="totalPurchasedBalance"
        heading={moneyFormat(String(balanceSummary.totalPurchasedBalance), 2)}
        description="Total Purchased" 
        tooltipContent={
          `The total value of all gift cards related to this store. 
          Includes disabled, refunded, credited, assignable, assigned, 
          and redeemed gift cards.`
        }
      />}
      {isAssignableGreaterThanZero && <SummaryCard
        key="totalAssignableBalance"
        heading={moneyFormat(String(balanceSummary.totalAssignableBalance), 2)}
        description="Total Assignable" 
        tooltipContent={"The total value of all unassigned gift cards that have been purchased for the MerchStore."}
      />}
      {isAssignedGreaterThanZero && <SummaryCard
        key="totalAssignedBalance"
        heading={moneyFormat(String(balanceSummary.totalAssignedBalance), 2)}
        description="Total Assigned" 
        tooltipContent={"The total remaining value of all gift cards that have been allocated to a store member."}
      />}
      {isUnassignedGreaterThanZero && <SummaryCard
        key="totalUnassignedBalance"
        heading={moneyFormat(String(balanceSummary.totalUnassignedBalance), 2)}
        description="Total Unassigned" 
        tooltipContent={"The total value of gift cards that have been unassigned, but cannot be reassigned. Contact support for more information."}
      />}
      {isDisabledGreaterThanZero && <SummaryCard
        key="totalDisabledBalance"
        heading={moneyFormat(String(balanceSummary.totalDisabledBalance), 2)}
        description="Total Disabled" 
        tooltipContent={"The total value of gift cards that have been disabled, refunded, and/or credited."}
      />}
      {isRedeemedGreaterThanZero && <SummaryCard
        key="totalRedeemedBalance"
        heading={moneyFormat(String(balanceSummary.totalRedeemedBalance), 2)}
        description="Total Redeemed" 
        tooltipContent={"The total value of gift cards that have been redeemed on the MerchStore to date."}
      />}
      {isRemainingGreaterThanZero && <SummaryCard
        key="totalRemainingBalance"
        heading={moneyFormat(String(balanceSummary.totalRemainingBalance), 2)}
        description="Total Remaining" 
        tooltipContent={"The total value of assigned and assignable gift cards."}
      />}
    </div>
  );
};

export default GiftCardsBalanceSummary;