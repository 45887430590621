import { useEffect } from "react";
import { useState } from "react";
import { IGroupMemberLayoutProps, IPackageProps, IArtworkProps } from "../index";
import { Card } from "@merchstores/shared/elements/Card";
import { CTA } from "@merchstores/shared/elements/Cta";
import { Gallery } from "@merchstores/shared/elements/Gallery";
import './styles.scss';
import { FormSelect, IFormOptionProps } from "@merchstores/shared/elements/FormSelect";
import { useForm } from "react-hook-form";
import Markdown from 'markdown-to-jsx';

import Modal from "react-responsive-modal";
import { ShippingForm } from "@merchstores/admin/components/ShippingForm";
import { useHistory } from "react-router-dom";
import { getLocalDateText } from '../../DateTime/DateTimeFormat';
import { Loading } from "@merchstores/shared/components/Loading";

import { 
    MemberSubmissionManagedAddressForm 
} from "@merchstores/admin/components/GroupOrderMemberSubmission/MemberSubmissionManagedAddressForm";

export const GroupMemberPDP = (props: IGroupMemberLayoutProps) => {
    const { register, setValue, formState: { errors } } = useForm();

    const [loading, setLoading] = useState(true);
    const [errorText, setErrorText] = useState("");
    const [groupOrder, setGroupOrder] = useState({});
    const [selectedPackage, setSelectedPackage] = useState({});
    const [packageOptions, setPackageOptions] = useState([]);
    const [packageStyles, setPackageStyles] = useState([]);
    const [artworkOptions, setArtworkOptions] = useState([]);
    const [expandArtwork, setExpandArtwork] = useState(false);
    const [imagesOptions, setImagesOptions] = useState([]);
    const [shippingFormOpen, setShippingFormOpen] = useState(false);
    const [selectedSize, setSelectedSize] = useState(null);
    const [selectedStyle, setSelectedStyle] = useState(null);
    const [selectedArtwork, setSelectedArtwork] = useState(null);
    const history = useHistory();

    useEffect(() => {
        if(props.groupOrder !== groupOrder) {
            setGroupOrder(props.groupOrder);
        }
    }, [props.groupOrder]);

    useEffect(() => {
        if (groupOrder.packages && groupOrder.packages.length > 0) {
            selectPackage({ value: groupOrder.packages[0].title, displayText: groupOrder.packages[0].title });
            setImagesOptions(groupOrder.packages[0].images);
            setLoading(false);
            setErrorText("");
        } else {
            setErrorText("This Group order has no packages.");
        }
    }, [groupOrder]) // eslint-disable-line react-hooks/exhaustive-deps


    const selectPackage = (option: IFormOptionProps) => {
        const selectedPackageTitle = option.value;

        const selectedPackage = groupOrder.packages.find((p: IPackageProps) => p.title === selectedPackageTitle) as IPackageProps;
        if (selectedPackage) {
            setSelectedPackage(selectedPackage);

            if (selectedPackage.sizes) {
                let options = selectedPackage.sizes.split(",");
                options = options.map(o => o.trim());
                setPackageOptions(options);
                if (options.length > 0) {
                    setSelectedSize(options[0]);
                }

            } else {
                setPackageOptions([]);
            }
            if (selectedPackage.styleRequired && selectedPackage.styles) {
                let styles = selectedPackage.styles.split(",");
                styles = styles.map(s => s.trim());

                setPackageStyles(styles);
                if (styles.length > 0) {
                    setSelectedStyle(styles[0]);
                }
            } else {
                setPackageStyles([]);
            }

            if (selectedPackage.artwork && selectedPackage.artwork.length > 0) {
                const artwork = selectedPackage.artwork;

                setArtworkOptions(artwork);
                setSelectedArtwork(artwork[0].logo);
            }

            if (selectedPackage.images && selectedPackage.images.length > 0) {
                setImagesOptions(selectedPackage.images);
            } else {
                setImagesOptions([]);
            }
        }
    }

    const expandFunction = () => {
        setExpandArtwork(true);
    }

    const closeExpandFunction = () => {
        setExpandArtwork(false);
    }

    const onOpenModal = () => setShippingFormOpen(true);
    const onCloseModal = () => setShippingFormOpen(false);

    const onFormSubmitted = () => {
        history.push({
            pathname: "/thank-you-page",
            state: { groupOrder }
        });
    }

    if (errorText !== "") return <p>{errorText}</p>
    if (loading) return <Loading isLoading={true} />

    return <>
        <div className="relative group-member-page-pdp-container">
            <div className="flex items-center justify-center flex-col mt-4 mb-8 px-4 max-w-4xl">
                <h1 className="text-center font-bold">
                    <Markdown>{groupOrder.storeMessage || ''}</Markdown>
                </h1>
                <p className="text-center">
                    <Markdown>{groupOrder.storeSubMessage || ''}</Markdown>
                </p>
            </div>
            <div className={`group-member-page-details-container ${expandArtwork ? "expanded" : ""}`}>
                <Card>
                    <div className={`group-member-page-details flex flex-col md:flex-row`}>
                        <div className={`order-artwork ${expandArtwork ? "expanded" : ""}`}>
                            <Gallery 
                                images={imagesOptions.map((a: any) => a.url)} 
                                hasThumbnails={true} 
                                expandFunction={expandFunction} 
                                closeExpandFunction={closeExpandFunction} 
                                selectedPackage={selectedPackage}
                            />
                        </div>
                        <div className="order-details flex flex-col bg-merch-light-gray bg-opacity-40 w-full h-full">
                            <div className="mb-2">
                                <FormSelect 
                                    register={register} 
                                    errors={errors} 
                                    setValue={setValue} 
                                    name="order-status" 
                                    label="Select your package:"
                                    options={
                                        groupOrder.packages?.map((package_: IPackageProps) => {
                                            return { value: package_.title, displayText: package_.title } 
                                        })
                                    }
                                    onChange={(option: IFormOptionProps) => selectPackage(option)} 
                                    default={selectedPackage?.title} 
                                    disabled={groupOrder.status === "closed"}
                                />
                            </div>
                            <div className="form-row">
                                <label className="font-bold">Package description:</label>
                                <p className="whitespace-pre-wrap">{selectedPackage.description}</p>
                            </div>
                            {packageOptions && packageOptions.length > 0 &&
                                <div className="mb-2">
                                    <FormSelect 
                                        register={register} 
                                        errors={errors} 
                                        setValue={setValue} 
                                        name="package_options" 
                                        label="Select your size:" 
                                        options={
                                            packageOptions.map((option: string) => {
                                                return { value: option, displayText: option } 
                                            })
                                        }
                                        default={packageOptions[0]}
                                        onChange={(option) => setSelectedSize(option.value)} 
                                        disabled={groupOrder.status === "closed"}
                                    />
                                </div>
                            }
                            {packageStyles && packageStyles.length > 0 &&
                                <div className="mb-2">
                                    <FormSelect 
                                        register={register} 
                                        errors={errors} 
                                        setValue={setValue}
                                        name="package_styles" 
                                        label="Select your style:" 
                                        options={packageStyles.map((option: string) => { return { value: option, displayText: option } })} 
                                        default={packageStyles[0]}
                                        onChange={(option) => setSelectedStyle(option.value)} 
                                        disabled={groupOrder.status === "closed"} />
                                </div>
                            }
                            {artworkOptions && artworkOptions.length > 0 &&
                                <div className="mb-4">
                                    <FormSelect register={register} errors={errors} setValue={setValue} name="artwork-options" label="Select your artwork:"
                                        options={artworkOptions.map((artwork: IArtworkProps) => {
                                            const splitted = artwork.logo.split("/")
                                            const fileName = splitted[splitted.length - 1];
                                            return { value: artwork.logo, displayText: fileName };
                                        })}
                                        artwork={true}
                                        default={artworkOptions[0].logo}
                                        onChange={(option) => setSelectedArtwork(option.value)}
                                        disabled={groupOrder.status === "closed"} />
                                </div>
                            }
                            <CTA 
                                type="primary" 
                                size="standard" 
                                classes="text-sm" 
                                onClick={onOpenModal} 
                                disabled={groupOrder.status === "closed"}
                            >
                                Proceed to Shipping Details
                            </CTA>
                            {groupOrder.status === "closed" ?
                                <div className="flex flex-col items-center mt-5"><p>This group order is closed.</p></div>
                                : <>
                                    {
                                        groupOrder.closeDate && <div className="flex flex-col items-center mt-5">
                                            <p>This group order closes on:</p>
                                            <p className="text-lg font-bold">{getLocalDateText(groupOrder.closeDate)}</p>
                                        </div>
                                    }
                                </>
                            }
                        </div>
                        <Modal open={shippingFormOpen} onClose={onCloseModal} center classNames={{ modal: "shipping-form-modal" }}>
                            { /* HERE:SOL-158 */ }
                            { groupOrder && groupOrder.managedShippingAddresses ?
                                <MemberSubmissionManagedAddressForm
                                    order={groupOrder}
                                    selectedPackage={{ ...selectedPackage, selectedSize, selectedArtwork, selectedStyle }} 
                                    onCloseModal={onCloseModal}
                                    onFormSubmitted={onFormSubmitted}
                                    readOnly={false}
                                />
                                :
                                <ShippingForm 
                                    selectedPackage={{ ...selectedPackage, selectedSize, selectedArtwork, selectedStyle }} 
                                    order={groupOrder} 
                                    onCloseModal={onCloseModal} 
                                    onFormSubmitted={onFormSubmitted} 
                                    title="Your Group Order Details" 
                                    readOnly={false}/>
                            }
                        </Modal>
                    </div>
                </Card>
            </div>

        </div>
    </>
}