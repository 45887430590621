
const CheckoutGiftCardRemovePayload = {
  "name": "CheckoutGiftCardRemovePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "checkout": "Checkout",
    "checkoutUserErrors": "CheckoutUserError",
    "userErrors": "UserError"
  },
  "implementsNode": false
};
export default CheckoutGiftCardRemovePayload;