import { IStorefrontGraphQLQuery } from "./StorefrontClient"

export interface ICheckoutCustomAttribute {
  key: string,
  value: string
}

export interface ICheckoutLineItem {
  variantId: string,
  quantity: number,
  customAttributes: Array<ICheckoutCustomAttribute>
}

export interface ICheckoutFields {
  lineItems: Array<ICheckoutLineItem>,
  customAttributes: Array<ICheckoutCustomAttribute>,
  shippingAddress?: IMailingAddressInput
}

export interface IMailingAddressInput {
  firstName: string,
  lastName: string,
  address1: string,
  address2?: string,
  city: string,
  company?: string,
  zip: string,
  province: string,
  country: string,
  phone?: string
}


export function buildCheckoutCreateMutation(checkoutFields: ICheckoutFields) : IStorefrontGraphQLQuery
{
  const query = `
  mutation checkoutCreate($input: CheckoutCreateInput!) {
    checkoutCreate(input: $input) {
      checkout {
        id
        webUrl
      }
      checkoutUserErrors {
        code
        field
        message
      }
      queueToken
    }
  }`

  const variables = {
    input: checkoutFields
  }

  return {
    query, variables
  }
}

export function buildCheckoutAddShippingAddressMutation(
  checkoutId: string, 
  shippingAddress: IMailingAddressInput
) : IStorefrontGraphQLQuery
{
  const query = `
    mutation checkoutShippingAddressUpdateV2($shippingAddress: MailingAddressInput!, $checkoutId: ID!) {
      checkoutShippingAddressUpdateV2(
        shippingAddress: $shippingAddress
        checkoutId: $checkoutId
      ) {
        checkout {
          id
        }
        checkoutUserErrors {
          code
          field
          message
        }
      }
    }
  `

  const variables = {
    checkoutId, shippingAddress
  }

  return {
    query, variables
  }
}