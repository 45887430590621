import axios from "axios";

import { MERCHSTORES_MERCHSTORE_UPDATE } from "@merchstores/admin/api/routes";
import { MERCHSTORES_MERCHSTORE_GET_BY_ID } from "@merchstores/admin/api/routes";

export async function fetchMerchStoreInternalOrderNotes(storeCode: string) {
  const response = await axios.post(
    MERCHSTORES_MERCHSTORE_GET_BY_ID,
    {
      storeCode: storeCode
    },
    {
      validateStatus: null // always return response
    }
  );

  return {
    storeCode: response.data.storeCode,
    internalOrderNotes: response.data.internalOrderNotes
  };
}

export async function updateMerchStoreInternalOrderNotes(storeCode: string, internalOrderNotes: string) {
  const response = await axios.post(
    MERCHSTORES_MERCHSTORE_UPDATE,
    {
      mutationType: 'updateInternalOrderNotes',
      mutationData: {
        storeCode: storeCode,
        internalOrderNotes: internalOrderNotes
      }
    },
    {
      validateStatus: null // always return response
    }
  );

  return {
    storeCode: response.data.storeCode,
    internalOrderNotes: response.data.internalOrderNotes
  };
}
