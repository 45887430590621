import axios from "axios";
import { toast } from 'react-toastify';
import { DateTime } from "luxon";

export function triggerCloseOrderAction(orderId : string)
{
  const closeDate = DateTime.now().toISO();

  return axios
    .post(
      '/.netlify/functions/closeOrder', 
      { 
        orderId: orderId,
        closeDate: closeDate
      }
    )
    .then(() => {
      toast.success(`Order ${orderId} is now closed.`);
    })
    .catch(err => {
      toast.error(`Error closing order ${orderId}.`);
      console.error(err);
    });
}

export default {
  triggerCloseOrderAction
}