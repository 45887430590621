import axios from 'axios';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { Modal } from 'react-responsive-modal';
import { setNetlifySubdomain, SubdomainReturnMessage } from '.';
import { IMerchStore } from '../MerchStore';
import { CTA } from '@merchstores/shared/elements/Cta';
import { FormInput } from '@merchstores/shared/elements/FormInput';
import { useForm } from 'react-hook-form';
import { IconContext } from 'react-icons';
import { AiOutlineCloseCircle, AiOutlineCheckCircle } from 'react-icons/ai';
import './styles.scss';

export interface ICustomSubdomainModalProps {
  merchStore: IMerchStore;
  setMerchStoreDetails: (arg0: IMerchStore) => void;
  open: boolean;
  onClose(): any;
}

export const CustomSubdomainModal = (props: ICustomSubdomainModalProps): JSX.Element => {
  const { merchStore, setMerchStoreDetails } = props;
  const defaultDomain = merchStore.subdomain;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [indicatorOption, setIndicatorOption] = useState(null);
  const onSubmit = (domain: any) => {
    handleSubdomainSubmit(domain[Object.keys(domain)[0]])
  };

  const handleSubdomainSubmit = async (userInput: string) => {
    const subdomainResp = await setNetlifySubdomain(merchStore, userInput);
    if (subdomainResp) {
      if (subdomainResp.message === SubdomainReturnMessage.success) {
        const updatedMerchStore = merchStore;
        updatedMerchStore.customDomain = userInput;
        axios
          .post('/.netlify/functions/updateMerchStore', {
            mutationType: 'updateMerchStore',
            merchStoreData: updatedMerchStore,
          })
          .then(() => {
            setMerchStoreDetails(updatedMerchStore);
            toast.success('Domain verified successfully');
            setIndicatorOption('success');
          })
          .catch((err) => {
            console.error(err);
            toast.error(
              'There was an error setting your custom domain. Please try again or contact support.'
            );
            setIndicatorOption('error');
          });
      } else if (
        subdomainResp.message === SubdomainReturnMessage.invalidEntry
      ) {
        toast.error('Invalid domain');
        setIndicatorOption('error');
      } else {
        toast.error('We were unable to verify your custom domain');
        setIndicatorOption('error');
      }
    }
  };
  const { open, onClose } = props;

  return (
    <Modal
      open={open}
      onClose={onClose}
      center
      classNames={{ modal: 'domain-modal' }}
      blockScroll="false"
      className="w-full"
    >
      <div className="w-full custom-domain">
        <h1 className="font-bold text-xl">Add Custom Domain</h1>
        <br />
        <p className="">
          Follow the instructions below to associate your own custom domain with your MerchStore storefront.
        </p>
        <br />
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex wrapper">
            <FormInput
              name="domain"
              type="text"
              required={merchStore ? true : false}
              label="Custom Domain"
              register={register}
              errors={errors}
              defaultValue={merchStore.customDomain ? `${merchStore.customDomain}` : ''}
            ></FormInput>
            {(merchStore.customDomain || indicatorOption === 'success') ? (
              <IconContext.Provider value={{ color: 'green', size: '1.5em', className: 'svg-location' }}>
                <div className="">
                  <AiOutlineCheckCircle />
                </div>
              </IconContext.Provider>
            ) : (indicatorOption === 'error') ? (
              <IconContext.Provider value={{ color: 'red', size: '1.5em', className: 'svg-location' }}>
                <div className="">
                  <AiOutlineCloseCircle />
                </div>
              </IconContext.Provider>
            ) : (
              <div></div>
            )}
          </div>
          <br />
          <div className="">
            <CTA
              type={(merchStore.customDomain || indicatorOption === 'success') ? 'verified' : 'primary'}
              size={'large'}
              stretch={true}
              formSubmit={true}
              classes={"h-12"}
            >
              {(merchStore.customDomain || indicatorOption === 'success') ? 'Domain Verified' : 'Verify Domain'}
            </CTA>
          </div>
        </form>
      </div>
      <br />
      <p className="">
        The status above does not necessarily indicate that your custom
        domain&apos;s DNS provider settings are correctly configured. It only
        shows if any custom domains are associated with your storefront. If
        your custom domain is still not valid even after verifying, please
        check your DNS provider settings. Depending on your DNS provider,
        changes to DNS records can take several hours to propagate and take
        effect for the entire internet.
      </p>
      <br />
      <p className="">
        Do not include the http or https protocol when verifying your custom domain.
      </p>
      <h6 className="">
        <a
          href="https://docs.netlify.com/domains-https/custom-domains/configure-external-dns/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Read more about using custom domains with Netlify here.
        </a>
      </h6>
      <br />
      <details>
        <summary className="font-bold">Instructions for Subdomains</summary>
        <p className="">
          Do not include the http or https protocol when verifying your custom
          domain.
        </p>
        <br />
        <p className="">
          If using a subdomain, such as <strong><code>store.exampledomain.com</code></strong> :{' '}
        </p>
        <br />
        <p>
          Find your DNS provider&apos;s DNS record settings for your apex
          domain. In those settings:
        </p>
        <br />
        <ol className="list-decimal list-inside">
          <li>
            Add an CNAME record with your subdomain as the host (
            <code>store</code> in this example).
          </li>
          <li>
            Point the record to your Netlify subdomain,{' '}
            <code>{defaultDomain}</code>
          </li>
          <li>
            Save your settings. It may take a full day for the settings to
            propagate across the global Domain Name System.
          </li>
          <li>
            Enter your custom domain in the input box above and click Verify.
          </li>
        </ol>
      </details>
      <br />
      <div className="underline"></div>
      <br />
      <details>
        <summary className="font-bold">Instructions for Apex Domains</summary>
        <h6 className="">
          If using an apex domain, such as <code>exampledomain.com</code> :{' '}
        </h6>
        <br />
        <p>
          Find your DNS provider&apos;s DNS record settings for your apex
          domain. In those settings:
        </p>
        <br />
        <ol className="list-decimal list-inside">
          <li>
            Add an A record. Either leave the &quot;Name&quot; field blank or
            enter <code>@</code>. Follow your provider&apos;s instructions for
            this process. Point this record to Netlify&apos;s load balancer IP
            address: <code>75.2.60.5</code>
          </li>
          <li>
            Add a CNAME record, using <code>www</code> for the &quot;Name&quot;
            field. Point the record to your Netlify subdomain,{' '}
            <code>{defaultDomain}</code>
          </li>
          <li>
            Save your settings. It may take a full day for the settings to
            propagate across the global Domain Name System.
          </li>
          <li>
            Enter your custom domain in the input box above and click Verify.
          </li>
        </ol>
      </details>
    </Modal>
  );
};
