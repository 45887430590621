

import { MdModeEdit } from 'react-icons/md';
import { IAdminHeaderProps } from './index';
import "./styles.scss";

export const AdminHeader = (props: IAdminHeaderProps) => { // Define the actual props needed in the index file
    return (
        <header className="admin mb-1 pt-3 px-5 lg:px-7 lg: pt-0 w-full flex justify-center">
            <div className="w-full flex justify-between items-center max-w-7xl">
                <div className="flex flex-col pr-7 title-container">
                    <div className="subtitle"><p>{props.subtitle}</p></div>
                    <div className="title mr-2 flex items-end ">
                        <h1>{props.title}</h1>
                        {props.editableAction && <button className="edit" onClick={props.editableAction}><MdModeEdit color="#4B4B5580" /></button>}
                    </div>
                </div>
                {props.children}
            </div>
        </header>
    )
}