import { userHasRole, Role, ROLE_SUPERUSER, ROLE_MERCHOLOGIST } from "@merchstores/admin/context/Roles";


const StatusFilter = {
  title: "Active",
  id: "statusFilterActive",
  value: "active"
}

const StatusDraft = {
  title: "Draft",
  id: "statusFilterDraft",
  value: "draft"
}

const StatusClosed = {
  title: "Closed",
  id: "statusFilterClosed",
  value: "closed"
}

const StatusPendingOrders = {
  title: "Pending Order",
  id: "statusPendingOrder",
  value: "pending_order"
}

const StatusDeleted = {
  title: "Deleted",
  id: "statusDeleted",
  value: "deleted"
}

export function buildStatusFilterOptions(role: Role) 
{
  if (userHasRole(role, [ROLE_SUPERUSER, ROLE_MERCHOLOGIST]))
  {
    return [
      StatusFilter,
      StatusDraft,
      StatusClosed,
      StatusPendingOrders,
      StatusDeleted
    ]
  }

  return [
    StatusFilter,
    StatusDraft,
    StatusClosed
  ]
}


const AdminPayer = {
  title: "Admin",
  id: "paymentFilterAdmin",
  value: "admin_payer"
}

const IndividualPayer = {
  title: "Individual",
  id: "paymentFilterIndividual",
  value: "individual_payer"
}

export const buildPaymentsDropdownFilterOptions = () => {
  return [
    AdminPayer,
    IndividualPayer
  ]
}


export const buildContactDropdownFilterOptions = (contactEmails: string[]) => {
  contactEmails = Array.from(new Set(contactEmails));
  contactEmails = contactEmails.sort((a: string, b: string) => a.localeCompare(b)).filter(Boolean);

  const dropdownOptions = contactEmails.map( (contactEmail: string) => {
      return {
        title: `${contactEmail}`,
        id: `${contactEmail}`
    }
  });

  return dropdownOptions;
}

export const createCustomerContactFilters = (contactEmails: string[]) => {
  return buildContactDropdownFilterOptions(contactEmails);
}

export const createMerchologistFilters = (contactEmails: string[]) => {
  return buildContactDropdownFilterOptions(contactEmails);
}

export function getFilterValue(filterState?: {title?:string, value?: string}): string|null {
  if (!filterState)
  {
    return null;
  }

  return filterState.value || filterState.title || '';
}