
import { Cloudinary } from "cloudinary-core";
import { cloudinaryApplyWidthHeight, getPublicIdFromUrl } from "./Cloudinary";
//Standarize sizing to display the mockup url over the product image

export const IMAGE_LG_TRANSFORMATION_WIDTH = 1024;
export const IMAGE_LG_TRANSFORMATION_HEIGHT = 1024;

export const IMAGE_MD_TRANSFORMATION_WIDTH = 480;
export const IMAGE_MD_TRANSFORMATION_HEIGHT = 480;

/*
  The LogoMockup component provides methods to return a product image with a logo overlayed on top of it. 
  It checks for valid props, and if there are errors with the required props,
  it will return the product image with no overlay.
  To generate an logo mockup image the product must have the tag 
  LogoArea=<img_x_percentage>-<img_y_percentage>-<logo_area_width>
  Use the methods generateLargeCloudinaryMockupUrl and generateMediumCloudinaryMockupUrl 
  to standrize the size of the produt images with logo mockup across all Merchstores and GOT.
*/
export const generateCloudinaryMockupUrl = (
  imgHeight: number,
  imgWidth: number,
  tags: string[],
  logoUrl: string,
  imgUrl: string,
  cloudinarySource?: "merchstores" | "group-order-tool",
  logoSourceWidth?: number,
  logoSourceHeight?: number
) => {
  const cloudinaryCore = new Cloudinary({
    cloud_name: "merchology",
    upload_preset: cloudinarySource ? cloudinarySource : "merchstores",
  });
  let validImage = false;
  if (imgHeight && imgWidth && tags && logoUrl && imgUrl) {
    validImage = true;
  }
  let areaData = "";
  let mockupDimensions = {
    logoWidth: 0,
    logoX: 0,
    logoY: 0,
  };

  tags.every((tag: string) => {
    const tagSplits = tag.split("=");
    if (tagSplits[0] === "LogoArea") {
      areaData = tagSplits[1];
      return false;
    } else {
      return true;
    }
  });
  if (areaData) {
    const areaDataSplits = areaData.split("-");
    if (areaDataSplits.length !== 3) {
      console.error(
        `Unable to generate logo mockup, LogoArea tag not properly formatted. ` +
          `Should be <img_x_percentage>-<img_y_percentage>-<logo_area_width>, is currently ${areaData}`
      );
      validImage = false;
    } else {
      const logoW = Math.round(imgWidth * (parseInt(areaDataSplits[2]) / 100));
      const logoH = logoW;

      let imgW = 0;
      let imgH = 0;

      if (logoSourceWidth && logoSourceHeight && logoSourceWidth > logoSourceHeight) {
        imgW = Math.round(logoW)
        const auxScale = imgW/logoSourceWidth;
        imgH = Math.round(logoSourceHeight * auxScale);
      } else if (logoSourceWidth && logoSourceHeight) {
        imgH = Math.round(logoW);
        const auxScale = imgH/logoSourceHeight;
        imgW = Math.round(logoSourceWidth * auxScale);
      } else {
        if (imgWidth < imgHeight) { // rectangular product image parameters
          imgW = logoW;
          imgH = imgHeight * (logoW / imgWidth);
        } else { // default case
          imgH = logoH;
          imgW = imgWidth * (logoH / imgHeight);
        }
      }

      //const adjustLogoXValue = adjustLogoXSetting === "width" ? logoW : 0;
      let x = Math.round(imgWidth * (parseInt(areaDataSplits[0]) / 100)) /*- adjustLogoXValue*/;
      let y = Math.round(imgHeight * (parseInt(areaDataSplits[1]) / 100));

      if (imgW < logoW) {
        x += Math.round((logoW - imgW) / 2);
      }
      if (imgH < logoW) {
        y += Math.round((logoW - imgH) / 2);
      }
      
      mockupDimensions = {
        logoWidth: logoW,
        logoX: x,
        logoY: y,
      };
    }
  } else {
    console.error("Unable to generate logo mockup, no LogoArea tag present");
    validImage = false;
  }

  const isCloudinaryImage = imgUrl.includes("cloudinary.com");

  const transformedUrl = validImage
    ? cloudinaryCore.url(
        isCloudinaryImage
          ? cloudinaryApplyWidthHeight(imgUrl, imgWidth, imgHeight)
          : imgUrl,
        {
          transformation: [
            {
              // Force base image to be the expected size ie: 480x480, 1024x1024
              crop: 'fit',
              width: imgWidth,
              height: imgHeight
            },
            {
              width: mockupDimensions.logoWidth,
              gravity: "center",
              overlay: getPublicIdFromUrl(logoUrl),
              effect: "make_transparent",
            },
            {
              crop: "crop",
              flags: "layer_apply",
              gravity: "north_west",
              x: mockupDimensions.logoX,
              y: mockupDimensions.logoY,
              format: "png",
            },
          ],
          type: "fetch",
        }
      )
    : imgUrl;

    return transformedUrl;
};

export const generateLargeCloudinaryMockupUrl = (
  tags: string[],
  logoUrl: string,
  imgUrl: string,
  cloudinarySource?: "merchstores" | "group-order-tool",
  logoSourceWidth?: number,
  logoSourceHeight?: number
) => {
  return generateCloudinaryMockupUrl(
    IMAGE_LG_TRANSFORMATION_HEIGHT,
    IMAGE_LG_TRANSFORMATION_WIDTH,
    tags,
    logoUrl,
    imgUrl,
    cloudinarySource,
    logoSourceWidth,
    logoSourceHeight
  )
}

export const generateMediumCloudinaryMockupUrl = (
  tags: string[],
  logoUrl: string,
  imgUrl: string,
  cloudinarySource?: "merchstores" | "group-order-tool",
  logoSourceWidth?: number,
  logoSourceHeight?: number
) => {
  return generateCloudinaryMockupUrl(
    IMAGE_MD_TRANSFORMATION_HEIGHT,
    IMAGE_MD_TRANSFORMATION_WIDTH,
    tags,
    logoUrl,
    imgUrl,
    cloudinarySource,
    logoSourceWidth,
    logoSourceHeight
  )
}

export const cloudinaryResizeLarge = (
  url: string
) => {
  return cloudinaryApplyWidthHeight(
    url,
    IMAGE_LG_TRANSFORMATION_WIDTH,
    IMAGE_LG_TRANSFORMATION_HEIGHT
  )
}


export async function getImageInformation(imageUrl: string): Promise<HTMLImageElement>{
  return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve(img);
      img.onerror = () => reject();
      img.src = imageUrl;
  });
}