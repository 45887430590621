import { OrderType } from "@merchstores/shared/components/MerchStore";
import { IMerchStore } from "@merchstores/admin/components/MerchStore";
import { fetchShopifyOrders } from "./ShopifyOrders";
import { fetchPendingIndividualOrders } from ".";
import Calculator from "./OrderListCalculator";
import { ACTIVE_ORDER_ID } from "./ActiveOrder";
import BigNumber from "bignumber.js";
import { isPastDate } from "@merchstores/admin/lib/DateTime";
import {
  ICombinedOrder,
  ICombinedOrdersList,
} from "@merchstores/admin/types/shopifyCombinedOrder";
import { OrderStatus } from "@merchstores/admin/types/merchstoreOrder";
import { IShopifyOrdersData } from "@merchstores/admin/types/shopifyOrder";

/*
  The ACTIVE ORDER is a virtual entity, is not a real shopify order,
  all the information created by this functions is for the only purpose
  of showing summary data in the Order Details view.
*/

export async function createVirtualCombinedOrder(
  merchStore: IMerchStore
): Promise<ICombinedOrder> {
  let defaultCustomerName = "";
  const officeAddress = merchStore.officeAddress;

  if (officeAddress) {
    defaultCustomerName =
      officeAddress.firstName + " " + officeAddress.lastName;
  }

  const pendingIndividualOrders = await fetchPendingIndividualOrders(
    merchStore.storeCode
  );
  const subtotalPrice = Calculator.sumOrderListSubtotal(
    pendingIndividualOrders
  ).toString();

  const activeCombinedOrder: ICombinedOrder = {
    id: ACTIVE_ORDER_ID,
    name: "Active",
    status: OrderStatus.pending,
    subtotalPrice: subtotalPrice,
    totalPrice: subtotalPrice,
    totalShippingPrice: new BigNumber("0.00").toString(),
    totalTax: Calculator.sumOrderListTotalTax(
      pendingIndividualOrders
    ).toString(),
    createdAt: merchStore.nextProcessingDate
      ? merchStore.nextProcessingDate
      : "",
    processingDate: merchStore.nextProcessingDate,
    isProcessingPending: true, // all active orders are pending processing, only the shopify combined are processed ones
    isProcessingDueDate: merchStore.nextProcessingDate
      ? isPastDate(merchStore.nextProcessingDate)
      : false,
    customer: {
      displayName: defaultCustomerName, // defined by admin?
      email: "", // defined by admin?
    },
    itemsCount: Calculator.sumOrderListItemsCount(pendingIndividualOrders)
      .integerValue()
      .toNumber(),
    alwaysOpen: merchStore.alwaysOpen,
  };

  return activeCombinedOrder;
}

const cache = new Map<string, ICombinedOrdersList>();

export async function listCombinedOrders(
  merchStore: IMerchStore
): Promise<ICombinedOrdersList> {
  const storeCode = merchStore.storeCode;
  const cachedResult = cache.get(storeCode);

  if (cachedResult) {
    return cachedResult;
  }

  const activeCombinedOrder = await createVirtualCombinedOrder(merchStore);

  const shopifyOrdersData = await fetchCombinedOrders(merchStore.storeCode);

  const shopifyCombinedOrders: Array<ICombinedOrder> =
    shopifyOrdersData.orders.map((order) => {
      const merchstoreCloseDateTag = order.tags.find((tag) =>
        /close_date=\d{4}-\d{2}-\d{2}/.test(tag)
      );
      const closedAt = merchstoreCloseDateTag
        ? merchstoreCloseDateTag.replace("close_date=", "")
        : order.closedAt;
      const shopifyCloseDate = order.closedAt;
      const processingDate = shopifyCloseDate || merchStore.nextProcessingDate;
      const isProcessingPending = false;
      const isProcessingDueDate = false;
      const subtotalPrice =
        order.currentSubtotalPriceSet?.presentmentMoney?.amount ?? '0.00';

      return {
        ...order,
        status: merchstoreCloseDateTag
          ? OrderStatus.closed
          : OrderStatus.active,
        closedAt: closedAt,
        subtotalPrice: subtotalPrice,
        processingDate: processingDate,
        isProcessingPending: isProcessingPending,
        isProcessingDueDate,
      };
    });

  const allOrders: Array<ICombinedOrder> = [
    activeCombinedOrder as ICombinedOrder,
  ].concat(shopifyCombinedOrders);

  const combinedOrdersData = {
    orders: allOrders,
    hasNextPage: shopifyOrdersData.hasNextPage,
    hasPreviousPage: shopifyOrdersData.hasPreviousPage,
    cursor: shopifyOrdersData.cursor,
  };

  cache.set(storeCode, combinedOrdersData);

  return combinedOrdersData;
}

export async function countCombinedOrders(
  merchStore: IMerchStore
): Promise<number> {
  const ordersData = await listCombinedOrders(merchStore);
  return ordersData.orders.length;
}

export async function sumCombinedOrders(
  merchStore: IMerchStore
): Promise<string> {
  const ordersData = await listCombinedOrders(merchStore);
  return Calculator.sumOrderListTotal(ordersData.orders).toString();
}

export async function fetchCombinedOrders(
  storeCode: string
): Promise<IShopifyOrdersData> {
  return fetchShopifyOrders({
    storeCode: storeCode,
    orderType: OrderType.COMBINED,
    sorting: "dateDesc",
  });
}
