import { useContext } from "react";
import { useQuery, UseQueryOptions, UseQueryResult } from "react-query";
import { getMerchStoreGiftCardsStatus } from "@merchstores/admin/api/merchstores/giftCards";
import { IMerchStoreGiftCardsStatus } from "@merchstores/admin/types/merchstore";
import { StoreContext } from "@merchstores/admin/context/Store";
import { QueryKey } from "@merchstores/admin/types/reactQuery";

type QueryOptions = UseQueryOptions<
  IMerchStoreGiftCardsStatus,
  unknown,
  IMerchStoreGiftCardsStatus,
  QueryKey.GIFT_CARDS_STATUS
>;

const useGiftCardsStatus = (
  storeCode: string,
  options?: QueryOptions
): UseQueryResult<IMerchStoreGiftCardsStatus> => {
  const { setGiftCardsStatus } = useContext(StoreContext);
  return useQuery(
    QueryKey.GIFT_CARDS_STATUS,
    () => getMerchStoreGiftCardsStatus(storeCode),
    {
      onSuccess(data) {
        setGiftCardsStatus(data);
        return data;
      },
      ...options,
    }
  );
};

export default useGiftCardsStatus;
