
export const ADDRESS_FIELDS = [
    {
        id: "company",
        label: "Company",
    },
    {
        id: "address1",
        label: "Street Address",
    },
    {
        id: "address2",
        label: "Apt, Suite, etc.",
    },
    {
        id: "city",
        label: "City",
    },
    {
        id: "state",
        label: "State",
    },
    {
        id: "country",
        label: "Country",
    },
    {
        id: "zip",
        label: "Postal Code",
    },
    {
        id: "phone",
        label: "Phone",
    },
]

export const MOCK_DATA = [
    {
        locationName: "",
        recipientId: "",
        groupOrderId: "",
        locationDisplayName: "Minneapolis - Target HQ",
        company: "Target",
        address1: "126 N 3rd St.",
        address2: "Suite #407",
        phone: '555-555-5555',
        email: "example@range-digital.com",
        zip: "55401",
        city: "Minneapolis",
        state: "Minnesota",
        country: "United States",
        isInactive: true,
        isDefault: true,
    },
    {
        locationName: "",
        recipientId: "",
        groupOrderId: "",
        locationDisplayName: "Minneapolis - Target HQ",
        company: "Target",
        address1: "126 N 3rd St.",
        address2: "Suite #407",
        phone: '555-555-5555',
        email: "example@range-digital.com",
        zip: "55401",
        city: "Minneapolis",
        state: "Minnesota",
        country: "United States",
        isInactive: true,
        isDefault: true,
    }
]