import { useState, useCallback, useEffect, useRef } from "react";
import { debounce } from "lodash";
import { MdSearch } from 'react-icons/md';
import { ISearchBoxProps } from ".";

export const SearchBox = (props: ISearchBoxProps) => {
    const debounceTime = 1000; // milliseconds
    const [collapsed, setCollapsed] = useState(true);
    const [searchValue, setSearchValue] = useState('');

    const doNothing = () => null;
    const searchFn = props.onSearch ? props.onSearch : doNothing;

    const debouncedSearch = debounce(searchFn, debounceTime);
    const searchInput = useRef(null);

    useEffect(() => {
        if (!collapsed) {
            searchInput.current?.focus();
        }
    }, [collapsed]);

    const onChange = (event: React.FormEvent<HTMLInputElement>) => {
        // cancel previews calls
        debouncedSearch.cancel();
        const searchInputText = String(event.currentTarget.value || '');
        setSearchValue(searchInputText);
        debouncedSearch(searchInputText.trim());
    }

    const onChangeReactCallback = useCallback(onChange, []);

    return (
        <div className="merchstore-search-box w-full text-merch-dark-gray font-bold rounded-md flex">
        {collapsed &&
            <button
                type="button"
                onClick={() => setCollapsed(!collapsed)}
                // eslint-disable-next-line max-len
                className="text-merch-dark-gray text-2xl font-bold p-2 relative border-solid border-1 border-gray-300 bg-white rounded-md shadow-sm"
            >
                <MdSearch />
            </button>
        }
        {!collapsed && (
            <>
                <input
                    type="text"
                    className="w-full pt-2 pr-12 pb-2 pl-3 md:text-14 text-12 text-merch-dark-gray font-bold rounded-md"
                    placeholder="Search"
                    ref={searchInput}
                    value={searchValue}
                    onChange={onChangeReactCallback}
                />
                <div className="p-2 text-2xl relative right-11">
                    <MdSearch />
                </div>
            </>)
        }
        </div>
    );
}
