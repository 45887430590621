import { IMerchStore } from "@merchstores/admin/components/MerchStore";
import BuyGiftCardButton from "../BuyGiftCardButton";

interface Props {
    merchStore: IMerchStore;
}

const EmptyState = (props: Props): JSX.Element => {
    const { merchStore } = props;
    return (
        <div className="flex items-center justify-between rounded-lg border border-gray-200 pl-3 py-5 shadow">
            <p className="text-sm mr-3 font-bold">You haven’t purchased any gift cards</p>
            <BuyGiftCardButton merchStore={merchStore} type="flat" />
        </div>
    );
};

export default EmptyState;