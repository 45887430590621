import ExternalLink from "../../../assets/common/external_link.svg";

export const LoginHeader = () => { 
    const isGroupOrderTool = /group-order/ig.test(window.location.href);
    
    const gotHeaderClass = 'bg-got-login-banner login-header bg-initial';

    const merchStoresHeaderClass = 'bg-merchstores-login-banner login-header bg-initial';

    const headerClass = isGroupOrderTool ? gotHeaderClass : merchStoresHeaderClass

    const titleText = isGroupOrderTool ? 'Group Order Tool' : 'MerchStores'

    return (
        <div className={headerClass}>
            <div className="flex justify-between items-center">
                <h4 className="text-lg text-white">{titleText}</h4>
                <a href="https://www.merchology.com" target="_blank" rel="noreferrer"><img alt="External Link to Merchology" src={ExternalLink} /></a>
            </div>
            <h1 className="font-bold text-3xl leading-8 text-white">Dashboard</h1>
        </div>
    )
}