import { isEmpty } from "lodash";
import {
  ICheckoutStatus,
  buildCheckoutFields,
  requestCheckoutUrl,
} from "@merchstores/admin/components/MerchStoreOrderCheckout/ShopifyCheckout";
import { ISubmissionValidationAddress } from ".";

export const ADDRESS_INVALID_FORMAT_ERROR =
  "We could not verify this address. Please double check " +
  "your inputs or reach out to your group administrator for support.";
const INVALID_PROVINCE_IN_COUNTRY = "INVALID_PROVINCE_IN_COUNTRY";
const INVALID_STATE_IN_COUNTRY = "INVALID_STATE_IN_COUNTRY";
const INVALID_REGION_IN_COUNTRY = "INVALID_REGION_IN_COUNTRY";
const INVALID_FOR_COUNTRY_AND_PROVINCE = "INVALID_FOR_COUNTRY_AND_PROVINCE";
const UNITED_STATES_COUNTRY = "United States";

const internationalAddressIgnoreErrors = [
  INVALID_PROVINCE_IN_COUNTRY,
  INVALID_STATE_IN_COUNTRY,
  INVALID_REGION_IN_COUNTRY,
  INVALID_FOR_COUNTRY_AND_PROVINCE,
];

export const validateGOTShippingAddress = async (
  shippingAddress: ISubmissionValidationAddress
): Promise<ICheckoutStatus> => {
  const checkoutFields = buildCheckoutFields([], {
    officeAddress: {
      ...shippingAddress,
      province: !isEmpty(shippingAddress.state) ? shippingAddress.state : "-",
    },
    shipToOffice: true,
    netlifySiteId: "",
    storeCode: "",
    status: "",
    subdomain: "",
  });

  const UNEXPECTED_CHECKOUT_CREATE_MUTATION_ERROR_CODE =
    "UNEXPECTED_CHECKOUT_CREATE_MUTATION_ERROR";
  try {
    const validateShippingErrors = await requestCheckoutUrl(checkoutFields);

    const isOutsideUS = shippingAddress.country !== UNITED_STATES_COUNTRY;
    const { errors } = validateShippingErrors;
    const parsedShippingErrors = errors.filter((error) => {
      if (
        isOutsideUS &&
        internationalAddressIgnoreErrors.includes(error.code)
      ) {
        return false;
      }
      return true;
    });

    return { ...validateShippingErrors, errors: parsedShippingErrors };
  } catch (err: unknown) {
    console.error(err);
    return {
      checkoutUrl: "",
      errors: [
        {
          code: UNEXPECTED_CHECKOUT_CREATE_MUTATION_ERROR_CODE,
          variantId: "",
          message: ADDRESS_INVALID_FORMAT_ERROR,
        },
      ],
    };
  }
};
