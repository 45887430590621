import Collapsible from "react-collapsible";
import axios from "axios";
import { useState, useContext, useEffect } from "react";
import { RiDeleteBin5Line } from "react-icons/ri";
import { useForm } from "react-hook-form";
import { MdFileUpload } from "react-icons/md";
import { toast } from "react-toastify";
import { parse } from "papaparse";
import { FormInput } from '@merchstores/shared/elements/FormInput';
import { Checkbox } from "@merchstores/shared/elements/Checkbox";
import { StoreContext } from "@merchstores/admin/context/Store";
import { CTA } from "@merchstores/shared/elements/Cta";
import { IMemberProps } from "../CreateEditGroupOrder";
import { InviteMerchStoreMemberProps } from '.';
import GiftCardsStatusArea from "./GiftCardsStatusArea";
import { IRequestGiftCardsSummaryResult } from "@merchstores/admin/components/MerchStoreGiftCards/GiftCardsSummary";
import { FormSelect, IFormOptionProps } from "@merchstores/shared/elements/FormSelect";
import { moneyFormat } from "@merchstores/shared/components/Money";

export const InviteMerchStoreMember = (props: InviteMerchStoreMemberProps): JSX.Element => {
  const { state } = useContext(StoreContext);
  const { register, handleSubmit, setValue, getValues, formState: { errors } } = useForm();
  const [expand, setExpand] = useState(props.display);
  const [submitting, setSubmitting] = useState(false);
  const [isAssignGiftCardsSelected, setIsAssignGiftCardsSelected] = useState(false);
  const [showGiftCardsError, setShowGiftCardsError] = useState(false);
  const [missingGiftCards, setMissingGiftCards] = useState(0);
  const [selectedAmount, setSelectedAmount] = useState('');
  const { giftCardsSummary } = props;

  const formSelectOptions = (giftCardsSummary: IRequestGiftCardsSummaryResult) => {
    return giftCardsSummary && giftCardsSummary.availableGiftCards
      ? giftCardsSummary.availableGiftCards.map(giftCard => ({
          value: giftCard.cardValue,
          displayText: `${moneyFormat(giftCard.cardValue)}`
        }))
      : [];
  };

  //Checks if the url is for creating a new order, and expands if true.
  if (props.order && window.location.href.indexOf("group-orders/new") > -1) {
    setExpand(true);
  }

  useEffect(() => {
    if (expand !== props.display) {
      setExpand(props.display);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.display]);

  const handleFileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    // validate that the CSV is a single column of emails
    const validateCSV = (csvData: any) => {
      let valid = true;
      csvData.every((row: any) => {
        if (row.length > 1) {
          valid = false;
          toast.error(
            "Uploaded CSV files should be a single column of email addresses. " +
            "Please check the format of your CSV and re-upload"
          );
          return false;
        } else {
          return true;
        }
      })
      return valid;
    }
    const files = e.target.files;
    if (files?.length) {
      parse(files[0], {
        complete: (results: any) => {
          if (validateCSV(results.data)) {
            const currentValue = getValues("emailInput").trim();
            let emailStr = ""
            results.data.forEach((emailEntry: any, index: number) => {
              if (index === 0) {
                if (currentValue.length) {
                  if (currentValue.indexOf(emailEntry[0]) === -1) {
                    if (currentValue[currentValue.length - 1] === ",") {
                      emailStr += `${currentValue} ${emailEntry[0]}`;
                    } else {
                      emailStr += `${currentValue}, ${emailEntry[0]}`;
                    }
                  } else {
                    emailStr += currentValue;
                  }
                } else {
                  emailStr += emailEntry[0];
                }
              } else {
                if (currentValue.length) {
                  if (currentValue.indexOf(emailEntry[0]) === -1) {
                    emailStr += `, ${emailEntry[0]}`;
                  }
                } else {
                  emailStr += `, ${emailEntry[0]}`;
                }
              }
            })
            setValue("emailInput", emailStr);
          }
        }
      });
    }
  }

  const addToString = (targetString: string, stringToAdd: string) => {
    if (!targetString.length) {
      targetString += stringToAdd;
    } else {
      targetString += `, ${stringToAdd}`;
    }
    return targetString;
  }

  const checkEmails = (emailList: Array<string>) => {
    let emailErrors = false;
    let invalidEmails = "";
    /**
     * this email validation leverages the built in email validation of the HTML5 email input type, with a fallback to
     * a very basic email regex. It's not bulletproof, but if it wouldn't pass the email input's validation,
     * it's good enough for our use case 
     */
    const validateEmail = (email: string) => {
      const input = document.createElement('input');
      input.type = 'email';
      input.required = true;
      input.value = email;
      return typeof input.checkValidity === 'function' ? input.checkValidity() : /\S+@\S+\.\S+/.test(email);
    }
    emailList.forEach((email: string) => {
      if (!validateEmail(email)) {
        emailErrors = true;
        invalidEmails = addToString(invalidEmails, email);
      }
    })
    // if there are invalid emails, allow the user to remove or correct them
    if (invalidEmails.length) {
      toast.error(`The following emails are invalid, please correct or remove before resubmitting: ${invalidEmails}`);
      setSubmitting(false);
    }
    return emailErrors;
  }

  const removeDuplicates = (emailList: Array<string>) => {
    let duplicateEmails = "";
    let parsedEmailString = "";
    const memberList = props.memberAndSubmissionDetails.map((member: IMemberProps) => {
      return member.email;
    })
    const parsedEmails = emailList.filter((email: string) => {
      if (memberList.includes(email)) {
        duplicateEmails = addToString(duplicateEmails, email);
      } else {
        parsedEmailString = addToString(parsedEmailString, email);
        return email;
      }
    })
    // if any of the emails are already added to the order, remove them automatically before sending to API
    if (duplicateEmails.length) {
      toast.warning(
        `The following emails have already been invited to the order and were automatically removed: ${duplicateEmails}`
      );
      setValue("emailInput", parsedEmailString);
    }

    const uniqueParsedEmails = Array.from(new Set(parsedEmails));

    return uniqueParsedEmails;
  }

  const onSubmit = async ($form: any) => {
    setSubmitting(true);
    const emailInput = $form.emailInput

    if (emailInput === '') {
      toast.error("Email field cannot be empty.")
      setSubmitting(false);
      return false;
    }

    const emailArray: Array<string> = emailInput
      .split(',')
      .map(function (email: string) {
        return email.trim().toLowerCase();
      })
      .filter(Boolean);
    const emailErrors = checkEmails(emailArray);

    const parsedEmails = removeDuplicates(emailArray);

    if (!emailErrors && !parsedEmails.length) {
      toast.error("Email field must contain at least one email.");
      setSubmitting(false);
      return false;
    }

    if (isAssignGiftCardsSelected) {
      const matchingCard = giftCardsSummary.availableGiftCards.find(card => card.cardValue === selectedAmount);
      if (!matchingCard || matchingCard.cardCount < emailArray.length) {
        const missingCards = matchingCard ? Math.abs(matchingCard.cardCount - emailArray.length) : emailArray.length;
        setMissingGiftCards(missingCards);
        setShowGiftCardsError(true);
        setSubmitting(false);
        return false;
      }
    }

    if (parsedEmails.length && !emailErrors) {
      axios
        .post("/.netlify/functions/uploadMerchStoreMember", {
          params:
          {
            emailArray: parsedEmails,
            storeCode: props.order.storeCode,
          }
        }).then(res => {
          // if any members were unable to add, reset the input values to those values so a user can attempt to resubmit
          if (res.data.memberErrors.length) {
            toast.error("Some members were unable to be added, please try again.");
          } else {
            if (props.onMembersAdded) {
              props.onMembersAdded(res.data.membersAdded, isAssignGiftCardsSelected);
              setIsAssignGiftCardsSelected(false);

            }
            toast.success("Members added successfully")
            setSubmitting(false);
            clearForm();
            // Assign gift cards here if needed.
          }
        })
        .catch((err) => {
          toast.error("An unexpected error has occurred, please try again in a few minutes.", { autoClose: false });
          console.error(err);
        });
    }

  };

  const clearForm = () => {
    setValue("emailInput", "");
  }

  const handleAssignGiftCardsChange = () => {
    setIsAssignGiftCardsSelected(!isAssignGiftCardsSelected);
  };

  const handleFormChange = () => {
    setShowGiftCardsError(false);
  };

  return (
    <div>
      {/* In order for Collapsible to close, it must have a transition time greater than 1. */}
      {/* Collapsible element has issues with TS defs */}
      {/* eslint-disable */}
      {/* @ts-ignore */}
      <Collapsible open={expand} transitionTime={1}>
        <div>
          <div className="flex w-full justify-between mb-4">
            <div className="text-sm text-merch-dark-gray font-bold flex items-center">Add Members</div>
            <input
              type="file"
              accept=".csv"
              id="member-hidden-input"
              className="hidden bg-right-top group-order-form-select p-3 border border-gray-300 rounded-md"
              onChange={(e) => handleFileInput(e)}>
            </input>
            <div className="flex flex-col">
              <CTA
                type="tertiary"
                size="standard"
                icon={<MdFileUpload size="20px" />}
                onClick={() => {
                  const fileInput = document.getElementById("member-hidden-input") as HTMLInputElement;
                  fileInput.click();
                }}
              >
                Upload CSV &#160;
              </CTA>
            </div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormInput
              type="textarea"
              textArea={true}
              name="emailInput"
              id="emailInput"
              register={register}
              errors={errors}
              required={false}
              placeholder="Add email addresses separated by commas"
              inputStyle="two"
              onChange={handleFormChange}
            />
            <br />
            {showGiftCardsError && (
              <GiftCardsStatusArea
                order={props.order}
                missingGiftCards={missingGiftCards}
                onChange={handleFormChange}
              />
            )}
            <div className='flex mb-4 items-center gap-3'>
              <div className="w-1/3">
                {state?.giftCardsStatus?.enabled && (
                  <Checkbox
                    label="Assign Gift Cards"
                    className="items-center"
                    onChange={handleAssignGiftCardsChange}
                    isSelected={isAssignGiftCardsSelected}
                    tooltip="Checking this box and selecting a card value will assign a gift card to each member added."
                  />
                )}
              </div>
              <div className="w-1/3">
                {state?.giftCardsStatus?.enabled && (
                  // TODO: Fix styles so dropdown doesn't get cut off
                  <FormSelect
                    name="assignGiftCardSelect"
                    classes="w-full"
                    register={register}
                    errors={errors}
                    disabled={!isAssignGiftCardsSelected}
                    emptyText="Value"
                    setValue={setValue}
                    label=""
                    options={formSelectOptions(giftCardsSummary)}
                    onChange={(selectedOption: IFormOptionProps) => {
                      setSelectedAmount(selectedOption.value);
                    }}
                  />
                )}
              </div>
              <div className="flex w-full justify-end">
                <CTA
                  type={'primary'}
                  size={'standard'}
                  formSubmit={true}
                  disabled={submitting || showGiftCardsError || (isAssignGiftCardsSelected && !selectedAmount)}
                  classes="mr-3"
                >
                  {submitting ? "Saving..." : "Save"}
                </CTA>
                <CTA
                  type={'secondary'}
                  size={'standard'}
                  icon={<RiDeleteBin5Line />}
                  onClick={clearForm}
                />
              </div>
            </div>
          </form>
        </div>
      </Collapsible>
    </div>
  );
};