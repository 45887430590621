export const draftMerchStoreOption = {
    value: "draft", 
    displayText: "Draft"
}

export const activeMerchStoreOption = {
    value: "active", 
    displayText: "Active"
}

export const closeMerchStoreOption = {
    value: "closed", 
    displayText: "Closed"
}

export function newMerchStoreStatusOptions() : {value: string, displayText: string}[]
{
    return [
        draftMerchStoreOption, 
        activeMerchStoreOption,
        closeMerchStoreOption
    ];
}

export function buildMerchStoreStatusOptions(MerchStoreStatus : string) : {value: string, displayText: string}[]
{
    const isActiveOrClosed = ['active', 'closed'].includes(MerchStoreStatus);

    if (isActiveOrClosed)
    {
        return [
            activeMerchStoreOption, 
            closeMerchStoreOption
        ];
    }

    return [
        draftMerchStoreOption, 
        activeMerchStoreOption,
        closeMerchStoreOption
    ]
}
