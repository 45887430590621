import axios, {AxiosResponse} from "axios";
import { toast } from 'react-toastify';

const LAMBDA_HTTP_OK = 200;
const LAMBDA_HTTP_STARTED = 202;

export async function exportGOTRecipientsToNetsuite(
    groupOrderId: string, 
    notificationEmail: string
): Promise<boolean>
{
  return axios
      .post('/.netlify/functions/pushGotOrdersToNetsuite-background', {
          groupOrderId: groupOrderId,
          notificationEmail: notificationEmail
      })
      .then((res: AxiosResponse) => {
          const processStarted = [LAMBDA_HTTP_OK, LAMBDA_HTTP_STARTED].includes(res.status);
          toast.success(`Started recipients syncing with Netsuite. Check your email for details.`);
          return processStarted;
      })
      .catch(err => {
          toast.error(`Error starting syncing with Netsuite.`);
          console.error(err);
          return false;
      });
}

export default {
    exportGOTRecipientsToNetsuite
}
