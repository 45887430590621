import { useQuery, UseQueryResult } from "react-query";
import { fetchShopifyOrders } from "@merchstores/admin/api/orders";
import { QueryKey } from "@merchstores/admin/types/reactQuery";
import {
  IShopifyOrdersParams,
  IShopifyOrdersData,
} from "@merchstores/admin/types/shopifyOrder";

const useOrders = (
  params: IShopifyOrdersParams
): UseQueryResult<IShopifyOrdersData> => {
  return useQuery(QueryKey.SHOPIFY_GIFT_CARDS_ORDERS, () =>
    fetchShopifyOrders(params)
  );
};

export default useOrders;
