import tagline from '../../assets/common/2021_Merchology_LogoLockups-01.svg'
import './styles.scss';

export function Footer(): JSX.Element {
  return (
    <footer className="branding-footer-container">
      <div className="branding-footer-links">
        <a className="branding-footer-tagline" href="https://www.merchology.com/" target="_blank" rel="noopener noreferrer">
          <img src={tagline} alt="Merchology. Your logo + brands you love." width="150px" />
        </a>
        <a href="https://www.merchology.com/pages/about-us" target="_blank" rel="noopener noreferrer">About Us</a>
        <a href="https://www.merchology.com/pages/merchsolutions" target="_blank" rel="noopener noreferrer">FAQ</a>
        <a href="https://www.merchology.com/pages/contact-us" target="_blank" rel="noopener noreferrer">Contact Us</a>
        <a href="https://www.merchology.com/pages/shipping-info" target="_blank" rel="noopener noreferrer">Shipping Info</a>
        <a href="https://www.merchology.com/pages/collections" target="_blank" rel="noopener noreferrer">Ideas</a>
      </div>
    </footer>
  )
}