import { useEffect } from "react";
import { useState } from "react";
import { IDropdownProps, IFilterDropdownOption } from ".";

import { MdArrowDropDown, MdDone } from 'react-icons/md';
import { generateReactKey } from "@merchstores/shared/react/ReactKey";
import { useRef } from "react";

import './styles.scss';

export const FilterDropdown = (props: IDropdownProps) => {

    const { title, list, chosenFilter, btnClass, searchable } = props;
    const [listOpen, setListOpen] = useState(false);
    const [headerTitle, setHeaderTitle] = useState(title);
    const wrapperRef = useRef(null);
    const [listValues, setListValues] = useState(list);


    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousemove', handleClickOutside);
        }
    });

    const restoreList = () => {
        setListValues(list);
    }

    const toggleList = () => {
        setListOpen(!listOpen);
        restoreList();
    }

    const closeList = () => {
        setListOpen(false);
        restoreList();
    }

    const selectItem = (item: any) => {
        if (!item) 
        {
            return false;
        }

        const { title } = item;
        setListOpen(false);
        if (item.action) {
            item.action();
        } else {
            setHeaderTitle(title);
        }
        restoreList();
    }
    

    const handleClickOutside = (event: any) => {
        if (wrapperRef && wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            closeList();
        }
    }

    const applySearchFilter = (event: React.FormEvent<HTMLInputElement>): boolean => {
        const searchValue = String(event.currentTarget.value || '').trim();

        if (!searchValue)
        {
            return false;
        }

        const tokenizedSearchValues = searchValue
            .split(/\s+/g, 4)
            .map(val => val.toLocaleLowerCase());
        
        const filteredValues = list.filter( (item: IFilterDropdownOption) => {
            return tokenizedSearchValues.every(searchVal => item.title.toLocaleLowerCase().includes(searchVal))
        });

        if (filteredValues.length === 0)
        {
            filteredValues.push({
                title: 'No results.',
                id: 'no-results'
            })
        }

        setListValues(filteredValues);

        return true;
    }

    const chosenFilterText = (chosenFilter: any) => Object.prototype.hasOwnProperty.call(chosenFilter, 'title') ? chosenFilter.title : String(chosenFilter); 

    return (
        <div className={`filter-container ${btnClass} ${listOpen ? "open" : ""}`} id={""} ref={wrapperRef}>

            <button
                type="button"
                className={`filter-header ${btnClass} text-merch-dark-gray font-bold rounded-md`}
                onClick={toggleList}
            >
                <div className={`filter-header-title md:text-14 text-12 ${chosenFilter && "chosen"}`}>{headerTitle}</div>
                <div className="desktop"><MdArrowDropDown /></div>
                <div className="mobile"><MdArrowDropDown /></div>
            </button>
            {listOpen && (
                <div
                    role="list"
                    className="filter-list pb-2"
                >
                    {searchable ?
                    <input 
                        className="filter-list-title pt-3 px-4 pb-2 text-merch-dark-gray font-medium" 
                        placeholder={headerTitle}
                        onChange={applySearchFilter}
                    ></input>
                    :
                    <span className="filter-list-title pt-3 px-4 pb-2" onClick={closeList}>{headerTitle}</span>
                    }

                    {listValues.map((item: IFilterDropdownOption) => (
                        item.title !== "" && <div 
                            className="flex nowrap justify-between items-center w-full hover:bg-merch-dark-gray 
                                hover:bg-opacity-5 w-full py-1.5 px-4 cursor-pointer" 
                            onClick={() => selectItem(item)}
                        >
                            <button
                                type="button"
                                className={`filter-list-item text-merch-dark-gray font-medium ${item.title}`}
                                key={generateReactKey(item.id)}
                            >
                                {item.title}
                                {' '}
                            </button>
                            <div className="pl-2">
                                { chosenFilter && chosenFilterText(chosenFilter) === item.title.toLowerCase() && <MdDone color="#FF671D"/>}
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    )
}
