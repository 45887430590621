import { useContext } from "react";
import { Context } from "@merchstores/admin/context";
import { isMobile } from "react-device-detect";
import { IOrderDetailsView, OrderType } from "@merchstores/admin/types/merchstoreOrder";
import { IMerchStore, MerchStoreTab } from "@merchstores/admin/types/merchstore";
import { Loading } from "@merchstores/shared/components/Loading";
import { StoreContext } from "@merchstores/admin/context/Store";
import { MerchStoreOrderList } from "../../MerchStoreOrderList"
import useOrders from "@merchstores/admin/hooks/orders/useOrders";
import History from "../MerchStoreGiftCardsTab/GiftCardsOrders/History";

import { userHasRole, Role, ROLE_SUPERUSER, ROLE_MERCHOLOGIST } from "@merchstores/admin/context/Roles";

import { 
    InternalOrderNotesForm,
    fetchMerchStoreInternalOrderNotes, 
    updateMerchStoreInternalOrderNotes 
} from "@merchstores/admin/components/MerchStoreInternalOrderNotes";


async function getInternalOrderNotes(storeCode: string)
{
    const notesData = await fetchMerchStoreInternalOrderNotes(
        storeCode
    );

    return notesData.internalOrderNotes;
}

interface Props {
    merchStore: IMerchStore;
    selectedOrderId: string;
    setSelectedOrderDetails: (orderDetailsView: IOrderDetailsView) => void;
}

const MerchStoreOrdersTab = (props: Props): JSX.Element => {
    const { userRole } = useContext(Context);

    const { merchStore, selectedOrderId, setSelectedOrderDetails } = props;
    const { data, isLoading } = useOrders({
        storeCode: merchStore.storeCode,
        orderType: OrderType.GIFT_CARD
    });
    const { state } = useContext(StoreContext)
    const showGiftCardsHistory = data && data.orders?.length > 0 && state?.giftCardsStatus?.enabled;

    const showInternalOrderNotes = userHasRole(new Role(userRole), [ ROLE_SUPERUSER, ROLE_MERCHOLOGIST ]);


    if (isLoading) {
        return (
            <Loading isLoading={true} />
        );
    }

    return (
        <div>
            { showInternalOrderNotes &&
                <InternalOrderNotesForm 
                    merchStore={merchStore}
                    getOrderNotes={ () => getInternalOrderNotes(merchStore.storeCode) }
                    updateOrderNotes={ updateMerchStoreInternalOrderNotes }
                    allowEdit={true}/>
            }

            <div className="text-sm text-merch-dark-gray font-bold mb-2.5 pl-6 md:pl-0">
                Processing History
            </div>
            <MerchStoreOrderList
                isMobile={isMobile}
                merchStore={merchStore}
                selectedOrderId={selectedOrderId}
                setSelectedOrderDetails={setSelectedOrderDetails}
            />
            {showGiftCardsHistory && (
                <div className="mt-8">
                    <History
                        orders={data?.orders}
                        tab={MerchStoreTab.ORDERS}
                        storeCode={merchStore?.storeCode}
                        setSelectedOrderDetails={setSelectedOrderDetails}
                    />
                </div>
            )}
        </div>
    );
};


export default MerchStoreOrdersTab;