import { Checkbox } from "@merchstores/shared/elements/Checkbox";
import { Loading } from "@merchstores/shared/components/Loading";
import { useState } from "react";
import axios from "axios";
import { IMerchStoreGiftCardsStatus } from "@merchstores/admin/types/merchstore";

interface Props {
  storeCode: string;
  giftCardsStatus: IMerchStoreGiftCardsStatus;
}


const PreApprovedPaymentMethodCheckbox = (props: Props): JSX.Element => {
  const { storeCode, giftCardsStatus } = props;

  const statusPreApprovedPaymentMethod = giftCardsStatus?.preApprovedPaymentMethod ?? false;
  const statusPreApprovedPaymentMethodLongTerm = giftCardsStatus?.preApprovedPaymentMethodLongTerm ?? false;

  const [hasPreApprovedPaymentMethod, setPreApprovedPaymentMethod] = useState(statusPreApprovedPaymentMethod);
  const [hasPreApprovedPaymentMethodLongTerm, setPreApprovedPaymentMethodLongTerm] = useState(statusPreApprovedPaymentMethodLongTerm);

  const isLoading = !storeCode;

  if (isLoading) {
    return (
      <Loading isLoading={true} />
    );
  }

  function handlePreApprovedPaymentMethodChange(preApprovedPaymentMethod: boolean) {
    setPreApprovedPaymentMethod(preApprovedPaymentMethod);
    setPreApprovedPaymentMethodLongTerm(false);

    axios.post("/.netlify/functions/updateMerchStore", {
      mutationType: 'updateGiftCardsPreApprovedPaymentMethod',
      merchStoreData: {
        'storeCode': storeCode,
        'giftCardsStatus': {
          'preApprovedPaymentMethod': preApprovedPaymentMethod,
          'preApprovedPaymentMethodLongTerm': false
        }
      }
    })
  }

  function handlePreApprovedPaymentMethodLongTermChange(preApprovedPaymentMethod: boolean) {
    setPreApprovedPaymentMethod(false);
    setPreApprovedPaymentMethodLongTerm(preApprovedPaymentMethod);

    axios.post("/.netlify/functions/updateMerchStore", {
      mutationType: 'updateGiftCardsPreApprovedPaymentMethod',
      merchStoreData: {
        'storeCode': storeCode,
        'giftCardsStatus': {
          'preApprovedPaymentMethod': false,
          'preApprovedPaymentMethodLongTerm': preApprovedPaymentMethod
        }
      }
    })
  }

  return (
    <div className="mt-8 mb-8">
      <Checkbox
        label="Pre-Approved Alternate Payment Method (one time)"
        className="items-center mt-5"
        onChange={handlePreApprovedPaymentMethodChange}
        isSelected={hasPreApprovedPaymentMethod}
        tooltip={(
          <p>
            Check this option to allow the <i><b>next</b></i> gift card purchase to process with a 
            pre-approved, alternate payment method.
          </p>
        )}
      />
      <Checkbox
        label="Pre-Approved Alternate Payment Method (multiple transactions)"
        className="items-center mt-5"
        onChange={handlePreApprovedPaymentMethodLongTermChange}
        isSelected={hasPreApprovedPaymentMethodLongTerm}
        tooltip={(
          <p>
            Check this option to allow <i><b>all</b></i> gift card purchases to process with a 
            pre-approved, alternate payment method. This option will stay active until manually disabled.
          </p>
        )}
      />
      <p className="mt-2 text-md opacity-50 italic">
        {hasPreApprovedPaymentMethod || hasPreApprovedPaymentMethodLongTerm ?
          `Pre-approved alternate payment method option will be available for ${hasPreApprovedPaymentMethod ? 'the next' : 'every'} gift card purchase.`
          :
          `Only standard credit card payments will be accepted for gift card purchases.`
        }
      </p>
    </div >
  );
};

export default PreApprovedPaymentMethodCheckbox;