import { useState, useEffect } from "react";

import { 
  getImageInformation,
  generateCloudinaryMockupUrl, 
  generateLargeCloudinaryMockupUrl 
} from "@merchstores/shared/components/Cloudinary/LogoMockup";
import { ILogoMockupImageProps } from ".";

export const LogoMockupImage = (props: ILogoMockupImageProps) => {
  const { imgHeight, imgWidth, tags, logoUrl, imgUrl, cloudinarySource, classes } = props;

  const [mockupImageUrl, setMockupImageUrl] = useState("");

  const logoUrlWithFallback = String(logoUrl || '');

  useEffect(() => {
    if (!logoUrl) {
      return false;
    }

    getImageInformation(String(logoUrl))
      .catch( err => {
          console.error("getImageInformation", err);
          return { width: undefined, height: undefined }
      })
      .then( logoImg => {
        if (imgHeight && imgWidth) {
          setMockupImageUrl(
            generateCloudinaryMockupUrl(
              imgHeight, imgWidth, tags, logoUrlWithFallback, imgUrl, cloudinarySource,
              logoImg.width,
              logoImg.height
            )
          );
        } else {
          setMockupImageUrl(
            generateLargeCloudinaryMockupUrl(
              tags, logoUrlWithFallback, imgUrl, cloudinarySource,
              logoImg.width,
              logoImg.height
            )
          );
        }

      });

  }, []);

  if (!mockupImageUrl) {
    return null;
  }

  return (
    <img
      className={classes ? classes : ""}
      loading="lazy"
      src={mockupImageUrl}
    />
  );
};
