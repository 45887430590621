import axios from "axios";
import { IMerchStore } from "../MerchStore";
import {
  getNetlifySiteSettings,
  updateNetlifySiteSettings,
} from "../Netlify/NetlifySiteSettings";
import {
  encodeSnippet,
  updateNetlifySnippet,
  addNetlifySnippet,
} from "../Netlify/NetlifySnippets";

const isApexDomain = (domain: any) => {
  const count = [...domain].filter((char) => char === ".").length;
  if (count === 1) {
    return true;
  }
  return false;
};

const fetchNetlifySnippets = async (siteId: string) => {
  const snippetResp = await axios.post(
    "/.netlify/functions/getNetlifySnippets",
    {
      siteId: siteId,
    }
  );
  return snippetResp.data;
};

const processMerchStoreUpdate = async (
  customSubdomain: string,
  merchStore: IMerchStore
) => {
  const merchStoreData = merchStore;
  merchStoreData.customDomain = customSubdomain;
  const merchStoreResp = await axios.post(
    "/.netlify/functions/updateMerchStore",
    { 
      mutationType: 'updateMerchStore',
      merchStoreData: merchStoreData 
    }
  );
  return merchStoreResp;
};

export enum SubdomainReturnMessage {
  success = "Domain verified successfully",
  invalidEntry = "Invalid input",
  error = "We were unable to verify your custom domain",
}

export const setNetlifySubdomain = async (
  merchStore: IMerchStore,
  userInput: string
) => {
  const siteId = merchStore.netlifySiteId;
  let netlifySnippetUpdate = null;
  let domainAliasesUpdate: any = null;
  let primaryDomainUpdate: any = null;
  const validDomain =
    /^(?!:\/\/)([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\.[a-zA-Z]{2,11}?$/;

  if (validDomain.test(userInput) === true) {
    const snippets = await fetchNetlifySnippets(siteId);
    const settingsResp = await getNetlifySiteSettings(siteId);
    const siteSettings = settingsResp.data;
    const originalDomain = siteSettings.custom_domain;
    const originalSubdomain = siteSettings.custom_domain.split(".")[0];
    const subdomainSnippet = snippets.find(
      (snippet: any) => snippet.title === "Subdomain"
    );
    const snippetId = subdomainSnippet ? subdomainSnippet.id : null;
    const customSubdomainSnippet = snippets.find(
      (snippet: any) => snippet.title === "CustomSubdomain"
    );
    const customSubdomainSnippetId = customSubdomainSnippet
      ? customSubdomainSnippet.id
      : null;

    if (snippetId !== null) {
      netlifySnippetUpdate = await updateNetlifySnippet(
        siteId,
        "Subdomain",
        encodeSnippet(originalSubdomain, "subdomain")
      );
    } else {
      netlifySnippetUpdate = await addNetlifySnippet(
        siteId,
        "Subdomain",
        encodeSnippet(originalSubdomain, "subdomain")
      );
    }

    if (
      netlifySnippetUpdate.status === 200 ||
      netlifySnippetUpdate.status === 201
    ) {
      domainAliasesUpdate = await updateNetlifySiteSettings(siteId, [
        {
          title: "domain_aliases",
          value: [userInput, originalDomain],
        },
      ]);

      const domainAliasesJSON = JSON.parse(
        domainAliasesUpdate.request.response
      );

      if (
        domainAliasesJSON &&
        domainAliasesJSON.domain_aliases[0] === userInput
      ) {
        let customDomain;
        if (isApexDomain(userInput)) {
          customDomain = {
            title: "custom_domain",
            value: "www." + userInput,
          };
        } else {
          customDomain = {
            title: "custom_domain",
            value: userInput,
          };
        }

        primaryDomainUpdate = await updateNetlifySiteSettings(siteId, [
          customDomain,
        ]);
        const primaryDomainJSON = JSON.parse(
          primaryDomainUpdate.request.response
        );
        if (primaryDomainJSON.custom_domain === customDomain.value) {
          if (customSubdomainSnippetId !== null) {
            netlifySnippetUpdate = await updateNetlifySnippet(
              siteId,
              "CustomSubdomain",
              encodeSnippet(customDomain.value, "customSubdomain")
            );
          } else {
            netlifySnippetUpdate = await addNetlifySnippet(
              siteId,
              "CustomSubdomain",
              encodeSnippet(customDomain.value, "customSubdomain")
            );
          }
          if (
            netlifySnippetUpdate.status === 200 ||
            netlifySnippetUpdate.status === 201
          ) {
            const merchStoreUpdateResp = await processMerchStoreUpdate(
              customDomain.value,
              merchStore
            );
            if (merchStoreUpdateResp.data) {
              return { message: SubdomainReturnMessage.success };
            } else {
              return { message: SubdomainReturnMessage.error };
            }
          } else {
            return { message: SubdomainReturnMessage.error };
          }
        }
      }
    } else {
      return { message: SubdomainReturnMessage.error };
    }
  } else {
    return { message: SubdomainReturnMessage.invalidEntry };
  }
};
