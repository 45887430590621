import { useEffect } from "react";
import { useState } from "react";
import { IDropdownPros } from ".";
import "./styles.scss";
import { MdArrowDropDown, MdModeEdit } from 'react-icons/md';
import { generateReactKey } from "@merchstores/shared/react/ReactKey";
import { useRef } from "react";

export const ActionDropdown = (props: IDropdownPros) => {

    const { title: headerTitle, list, className } = props;
    const [listOpen, setListOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const wrapperRef = useRef(null);


    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousemove', handleClickOutside);
        }
    });

    const toggleList = () => {
        setListOpen(!listOpen);
    }

    const closeList = () => {
        setListOpen(false);
    }

    const selectItem = (item: any) => {
        if (item) {
            setListOpen(false);
            setSelectedItem(item);
            if (item.action) {
                item.action();
            }
        }

    }

    const handleClickOutside = (event: any) => {
        if (wrapperRef && wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            closeList();
        }
    }


    return (
        <div className={`dd-container ${listOpen ? "open" : ""} `} id={""} ref={wrapperRef}>
            <button
                type="button"
                className={`dd-header rounded-md flex justify-center items-center text-merch-dark-gray font-bold ${className || ''} `}
                onClick={toggleList}
            >
                <div className="dd-header-title">{selectedItem?.title || headerTitle}</div>
                {!listOpen && <div className="desktop"><MdArrowDropDown /></div>}
                <div className="mobile"><MdModeEdit /></div>
            </button>
            {listOpen && (
                <div
                    role="list"
                    className={`dd-list ${props.listClassName ? props.listClassName : ''}`}
                >
                    <span className="dd-list-title pt-2 pb-1 pr-2.5" onClick={closeList}>{headerTitle}</span>
                    {list.map((item, index) => (
                        item && !item?.hide ?
                        <div key={index} className={`py-1 pl-6 pr-2.5 ${item?.disabled ? '' : 'hover:bg-merch-dark-gray hover:bg-opacity-5'}`}>
                            <button
                                type="button"
                                className={
                                    `dd-list-item 
                                    font-bold 
                                    text-13 
                                    action-tooltip 
                                    ${item?.disabled ? 'text-merch-text-disabled cursor-default' : 'text-merch-dark-gray'}`
                                }
                                key={generateReactKey(item?.id)}
                                onClick={() => item?.disabled ? null : selectItem(item)}
                            >
                                {item?.disabledTip && item?.disabled ? 
                                    <span className="action-tooltiptext">{item?.disabledTip}</span> :
                                    null
                                }
                                {item?.title}
                            </button>
                        </div>
                        : null
                    ))}
                </div>
            )}
        </div>
    )

}