import { ICustomAttribute } from "@merchstores/admin/types/shopifyCheckout";

const extractAttribute = (list: ICustomAttribute[], key: string): string => {
  const foundItem = list?.find((item) => {
    return item.key == key;
  });
  return foundItem?.value as string;
};

export { extractAttribute };
