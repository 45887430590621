import { IFormOptionProps } from "@merchstores/shared/elements/FormSelect";
import { IShippingPackageProps } from "./MemberSubmission";

export function makePackageStyleOptions(
  selectedPackage: IShippingPackageProps
): IFormOptionProps[] {
  const styleString = String(selectedPackage.styles || '').trim();

  if (!styleString) {
    return [];
  }

  const sizeSplit = styleString.split(/\s*,\s*/g).filter(Boolean);

  return sizeSplit.map((packageStyle: string) => {
    return {
      value: packageStyle,
      displayText: packageStyle
    }
  })
}
