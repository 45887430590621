import "./styles.scss";
import { useState, useEffect } from "react";
import { CTA } from "@merchstores/shared/elements/Cta";
import { Table } from "@merchstores/shared/elements/Table";
import { MdDelete, MdModeEdit } from "react-icons/md";
import { PackageListProps } from ".";
import { Logo } from "@merchstores/shared/elements/Logo";
import { cloudinaryApplyWidthHeight } from "@merchstores/shared/components/Cloudinary";
import { CreateEditPackage } from "../../CreateEditPackage";
import axios from "axios";
import { useContext } from "react";
import { Context } from "../../../context";

export const PackageList = (props: PackageListProps) => {
  const [packagesData, setPackagesData] = useState(undefined);
  const [editPackageModalOpen, setEditPackageModalOpen] = useState(false);
  const [packageDetails, setPackageDetails] = useState(undefined);
  const [orderDetails, setOrderDetails] = useState(undefined);
  const { userRole } = useContext(Context);

  const onOpenEditPackageModal = () => setEditPackageModalOpen(true);
  const onCloseEditPackageModal = () => setEditPackageModalOpen(false);

  const onEditClick = (packageItem: any) => {
    setPackageDetails(packageItem);
    onOpenEditPackageModal();
  }

  const onDeleteClick = (groupOrderId: string, packageId: string) => {
    axios.post('/.netlify/functions/deletePackage', {
      groupOrderId,
      packageId,
    }).then(() => {
      alert(`Package deleted successfully.`);
      orderDetails.packages = orderDetails.packages.filter((p: any) => p.id !== packageId);
      loadPackagesData(orderDetails);
    }).catch((err: unknown) => {
      window.alert('An error occured deleting the Package, please try again.');
      console.error(err);
    })
  }

  const onPackageCreatedOrEdited = () => {
    if (props.onPackageCreatedOrEdited) {
      props.onPackageCreatedOrEdited();
    }
  }

  const loadPackagesData = (orderDetails: any) => {
    const packagesData = orderDetails.packages.map((packageItem: any) => {

      const logoUrl = packageItem.images.length > 0 ? cloudinaryApplyWidthHeight(packageItem.images[0].url, 36, 36) : '';
      switch (userRole) {
        case "ADMIN":
          return {
            tr: {
              class: "",
              data: {
                packageLogo: {
                  desktopOnly: false,
                  value: <div className="relative h-45">
                    <div className="absolute logoShadow top z-20"><Logo size="small" imgUrl={logoUrl} /></div>
                    {packageItem.images[1] ? <div className="logoShadow first absolute bg-white z-10"></div> : ''}
                    {packageItem.images[2] ? <div className="logoShadow second absolute bg-white z-0"></div> : ''}
                  </div>
                },
                packageTitle: {
                  desktopOnly: false,
                  value: <div className="font-bold text-merch-dark-gray text-left ml-10">{packageItem.title}</div>
                },
                actions: {
                  desktopOnly: true,
                  value: <CTA size="standard" type="secondary" onClick={() => { onEditClick(packageItem); }}>{userRole !== "ADMIN" ? "View/Edit" : "View"}</CTA>
                },
                actionsMobile: {
                  mobileOnly: true,
                  value: <CTA size="small" type="secondary" icon={<MdModeEdit />} onClick={() => { onEditClick(packageItem); }} />
                }
              }
            }
          }
        default:
          return {
            tr: {
              class: "",
              data: {
                packageLogo: {
                  desktopOnly: false,
                  value: <div className="relative h-45">
                    <div className="absolute logoShadow top z-20"><Logo size="small" imgUrl={logoUrl} /></div>
                    {packageItem.images[1] ? <div className="logoShadow first absolute bg-white z-10"></div> : ''}
                    {packageItem.images[2] ? <div className="logoShadow second absolute bg-white z-0"></div> : ''}
                  </div>
                },
                packageTitle: {
                  desktopOnly: false,
                  value: <div className="font-bold text-merch-dark-gray text-left ml-10">{packageItem.title}</div>
                },
                actions: {
                  desktopOnly: true,
                  value: <CTA size="standard" type="secondary" onClick={() => { onEditClick(packageItem); }}>{userRole !== "ADMIN" ? "View/Edit" : "View"}</CTA>
                },
                actionsMobile: {
                  mobileOnly: true,
                  value: <CTA size="small" type="secondary" icon={<MdModeEdit />} onClick={() => { onEditClick(packageItem); }} />
                },
                actionDelete: {
                  desktopOnly: false,
                  value: <CTA 
                    classes="border-none shadow-none"  
                    size="small" 
                    type="secondary" 
                    icon={<MdDelete />} 
                    onClick={() => { onDeleteClick(orderDetails.groupOrderId, packageItem.id); }}
                  />
                }
              }
            }
          }

      }
      
    });
    setPackagesData(packagesData);
  }

  useEffect(() => {
    if (orderDetails) {
      loadPackagesData(orderDetails);
    }
  }, [orderDetails])

  useEffect(() => {
    if (props.orderDetails) {
      setOrderDetails(props.orderDetails);
    }
  }, [props])

  return (
    <div className='package-list-container'>
      <CreateEditPackage 
        groupOrderId={orderDetails ? orderDetails.groupOrderId : ''} 
        packageFormData={packageDetails} 
        modalOpened={editPackageModalOpen} 
        onOpenModal={onOpenEditPackageModal} 
        onCloseModal={onCloseEditPackageModal} 
        onPackageCreatedOrEdited={onPackageCreatedOrEdited} 
      />
      {packagesData ?
        <Table name="package-list" filter={true} data={packagesData} itemsName="packages" />
        : ''
      }
    </div>
  )
}