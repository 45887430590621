
import { sendEmail } from "@merchstores/shared/components/Mailer";
import axios from "axios";
import { extractArtworkFileName } from "./Artwork";

export interface GroupOrder {
  groupOrderId: string;
  name: string;
  storeUrl: string;
  storeLogo: string;
}

export interface Submission {
  memberEmail: string,
  address: SubmissionAddress,
  selectedStyle: string;
  selectedArtwork: string,
  selectedPackage: string,
  newsletterSubscription: boolean;
  size: string,
  shippingGroupOrderAddressId?: string | null;
}

export interface GroupOrderMember {
  memberId: string;
}

export interface SubmissionAddress {
  groupOrderAddressId?: string | null;
  company: string;
  firstName: string;
  lastName: string;
  address1: string,
  address2: string,
  country: string;
  city: string,
  state: string,
  zip: string
  phone: string;
}


interface AddSubmissionParameters {
  groupOrderId: string;
  submission: Submission;
}

interface SendSubmissionConfirmationParameters {
  groupOrderId: string;
  groupOrderName: string;
  groupOrderStoreLogo: string;
  groupOrderStoreUrl: string;
  email: string;
  submission: Submission;
  memberId: string;
}


export interface SubmitSubmissionParameters {
  groupOrder: GroupOrder;
  submission: Submission;
}


export async function submitSubmission(parameters: SubmitSubmissionParameters) {
  const { groupOrder, submission } = parameters;

  const normalizedMemberEmail = (String(submission.memberEmail || '')).trim().toLocaleLowerCase();

  const registerSuccess = await registerSubmission({
    groupOrderId: groupOrder.groupOrderId,
    submission: {
      ...submission,
      memberEmail: normalizedMemberEmail
    }
  })

  const groupOrderMember = await fetchMemberByEmail(
    groupOrder.groupOrderId, normalizedMemberEmail
  );

  if (registerSuccess && groupOrderMember && groupOrderMember.memberId) {
    await sendSubmissionConfirmation({
      groupOrderId: groupOrder.groupOrderId,
      groupOrderName: groupOrder.name,
      groupOrderStoreLogo: groupOrder.storeLogo,
      groupOrderStoreUrl: groupOrder.storeUrl,
      email: submission.memberEmail,
      submission: submission,
      memberId: groupOrderMember.memberId
    });
  }

}

export async function fetchMemberByEmail(groupOrderId: string, memberEmail: string): Promise<GroupOrderMember | null> {
  // Fetch memberId from recently created/updated member
  const response = await axios.post('/.netlify/functions/fetchMemberByEmail', {
    email: memberEmail,
    groupOrderId: groupOrderId
  });

  if (response && response.data) {
    return {
      memberId: response.data.memberId || ""
    }
  }

  return null;
}


export async function registerSubmission(parameters: AddSubmissionParameters): Promise<boolean> {
  const { groupOrderId, submission } = parameters;

  const memberEmail = submission.memberEmail;

  if (!memberEmail) {
    throw new Error(
      `registerSubmission: submission.memberEmail is required`
    );
  }

  const addResponse = await axios.post('/.netlify/functions/addSubmission', {
    email: memberEmail,
    submission,
    groupOrderId: groupOrderId
  });
  if (addResponse && addResponse.data && addResponse.data.newMember) {
    const submissionId = addResponse.data.submissionId;

    await axios.post('/.netlify/functions/uploadGroupMember', {
      params: {
        emailArray: [memberEmail],
        groupOrderId: groupOrderId,
        //verificationCode: order ? order.verificationCode === true : false,
        submissionId: submissionId
      },
    });

    return true;
  }

  return false;
}

export async function sendSubmissionConfirmation(parameters: SendSubmissionConfirmationParameters): Promise<boolean> {
  const {
    groupOrderId,
    groupOrderName,
    groupOrderStoreLogo,
    groupOrderStoreUrl,
    email,
    submission,
    memberId
  } = parameters;

  const artworkItemFileName = extractArtworkFileName({ logo: submission.selectedArtwork });

  await sendSubmissionConfirmationEmail(
    groupOrderId,
    [email],
    groupOrderName,
    groupOrderStoreLogo,
    groupOrderStoreUrl,
    submission.selectedPackage,
    submission.address.firstName,
    submission.address.lastName,
    submission.address.company,
    submission.address.address1,
    submission.address.city,
    submission.address.state,
    submission.address.country,
    submission.address.zip,
    submission.address.phone,
    submission.selectedStyle,
    submission.size,
    submission.selectedArtwork,
    artworkItemFileName,
    submission.address.address2,
    memberId
  );

  return true;
}


const sendSubmissionConfirmationEmail = (
  groupOrderId: string,
  recipient: string[],
  storeName: string,
  storeLogo: string,
  storeUrl: string,
  selectedPackage: string,
  firstName: string,
  lastName: string,
  company: string,
  streetAddress: string,
  city: string,
  state: string,
  country: string,
  postalCode: string,
  phone: string,
  selectedStyle?: string,
  selectedSize?: string,
  artwork?: string,
  artworkFileName?: string,
  apartmentNumber?: string,
  memberId?: string,
) => {
  const storeSite = process.env.REACT_APP_ENV === "production" ?
    "https://merchologysolutions.com" :
    "https://staging.merchologysolutions.com";

  const email = {
    templateId: "got-submission-confirmation",
    subject: `You’re In. Your ${storeName} Merchology Order Details Inside!`,
    text: 'Stay tuned for shipping details.',
    templateContent: [
      {
        name: 'groupOrderId',
        content: groupOrderId
      },
      {
        name: 'storeLogo',
        content: storeLogo
      },
      {
        name: 'storeUrl',
        content: `${storeSite}/${storeUrl}`
      },
      {
        name: 'memberEmail',
        content: recipient[0]
      },
      {
        name: 'selectedPackage',
        content: selectedPackage
      },
      {
        name: 'style',
        content: selectedStyle
      },
      {
        name: 'size',
        content: selectedSize
      },
      {
        name: 'artwork',
        content: artwork
      },
      {
        name: 'firstName',
        content: firstName
      },
      {
        name: 'lastName',
        content: lastName
      },
      {
        name: 'company',
        content: company
      },
      {
        name: 'streetAddress',
        content: streetAddress
      },
      {
        name: 'apartmentNumber',
        content: apartmentNumber
      },
      {
        name: 'city',
        content: city
      },
      {
        name: 'state',
        content: state
      },
      {
        name: 'country',
        content: country
      },
      {
        name: 'postalCode',
        content: postalCode
      },
      {
        name: 'phone',
        content: phone
      },
      {
        name: 'artworkName',
        content: artworkFileName
      },
      {
        name: 'groupOrderId',
        content: groupOrderId
      },
      {
        name: 'memberId',
        content: memberId
      },
    ]
  }
  sendEmail(email, recipient);
}