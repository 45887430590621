import { OrderType } from "@merchstores/shared/components/MerchStore";
import { fetchShopifyOrders } from "./ShopifyOrders";
import {
  IShopifyOrdersData,
  IShopifyOrder,
} from "@merchstores/admin/types/shopifyOrder";

export async function fetchPendingIndividualOrders(
  storeCode: string
): Promise<Array<IShopifyOrder>> {
  const pendingIndividualOrdersData = await fetchShopifyOrders({
    storeCode: storeCode,
    orderType: OrderType.INDIVIDUAL,
    closeDate: "pending",
    sorting: "dateDesc",
  });

  const pendingIndividualOrders = pendingIndividualOrdersData.orders;

  return pendingIndividualOrders;
}

export async function fetchIndividualOrders(
  storeCode: string,
  combinedOrderId: string
): Promise<IShopifyOrdersData> {
  if (combinedOrderId === "merchstore-pending") {
    return fetchShopifyOrders({
      storeCode: storeCode,
      closeDate: "pending",
      orderType: OrderType.INDIVIDUAL,
      sorting: "dateDesc",
    });
  }

  const ordersResponse = await fetchShopifyOrders({
    storeCode: storeCode,
    combinedOrderId: combinedOrderId,
    orderType: OrderType.INDIVIDUAL,
    sorting: "dateDesc",
  });

  const snapshotsByOrderId = new Map<string, IShopifyOrder>();

  const snapshots = ordersResponse.snapshots ?? [];

  snapshots.forEach((order) => {
    snapshotsByOrderId.set(order.id, order);
  });

  let orders = ordersResponse.orders ?? [];

  orders = orders.map((order) => {
    const orderSnapshot = snapshotsByOrderId.get(order.id);

    if (orderSnapshot) {
      return orderSnapshot;
    }

    return order;
  });

  ordersResponse.orders = orders;

  return ordersResponse;
}
