import { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { MdAdd } from "react-icons/md";
import { IMerchStore } from '@merchstores/admin/types/merchstore';
import { CTA } from "@merchstores/shared/elements/Cta";
import { GiftCardModal } from "../MerchStoreGiftCards";

interface Props {
    order: IMerchStore;
    missingGiftCards: number;
    onChange: () => void;
}

const GiftCardsStatusArea = (props: Props): JSX.Element => {
    const { order, missingGiftCards, onChange } = props;
    const [isBuyGiftCardModalOpen, setIsBuyGiftCardModalOpen] = useState(false);

    const toggleBuyGiftCardsModal = () => {
        setIsBuyGiftCardModalOpen(!isBuyGiftCardModalOpen);
    }

    const handleCloseBuyGiftCardsModal = () => {
        onChange();
        setIsBuyGiftCardModalOpen(!isBuyGiftCardModalOpen);
    }

    return (
        <>
            <div className="flex items-center h-40 px-3 mb-3 -mt-4 bg-merch-background-orange">
                <p className="font-bold text-13 w-full text-merch-orange">
                    You need to add {missingGiftCards} more gift cards.
                </p>
                <div className="w-full flex justify-end">
                    <CTA
                        type={'flat'}
                        size={'standard'}
                        onClick={toggleBuyGiftCardsModal}
                    >
                        <div className="flex">
                            <MdAdd size="20px" />
                            <p>Add Gift Cards</p>
                        </div>
                    </CTA>
                </div>
            </div>
            <GiftCardModal
                isMobile={isMobile}
                merchStore={order}
                open={isBuyGiftCardModalOpen}
                storeCode={order.storeCode}
                storeSubdomain={order.subdomain}
                onClose={handleCloseBuyGiftCardsModal}
            />
        </>
    )
}

export default GiftCardsStatusArea;