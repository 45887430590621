import { IMerchStoreProps } from ".";
import { IMerchStoreActions } from "./MerchStoreActions";

export const buildDropdowns = (
  role: string,
  merchstore: IMerchStoreProps,
  actions: IMerchStoreActions
) => {
  const dropdowns = {
    viewEdit: {
      title: "View Store",
      id: `${merchstore.storeCode}-view`,
      action: () => {
        actions.viewEditStore && actions.viewEditStore();
      },
    },
    viewPendingOrder: {
      title: "View Pending Order",
      id: `${merchstore.storeCode}-view`,
      action: () => {
        actions.viewPendingOrder && actions.viewPendingOrder();
      },
    },
    close: {
      title: "Close",
      id: `${merchstore.storeCode}-close`,
      action: () => {
        actions.closeStore && actions.closeStore();
      },
    },
    reassign: {
      title: "Reassign",
      id: `${merchstore.storeCode}-reassign`,
      action: () => {
        actions.reassignMerchologist && actions.reassignMerchologist();
      },
    },
    reOpen: {
      title: "Re-Open",
      id: `${merchstore.storeCode}-re-open`,
      action: () => {
        actions.reOpenStore && actions.reOpenStore();
      },
    },
    rebuild: {
      title: "Rebuild",
      id: `${merchstore.storeCode}-rebuild`,
      action: () => {
        actions.rebuildMerchstore && actions.rebuildMerchstore();
      },
    },
    delete: {
      title: "Delete",
      id: `deleted-${merchstore.storeCode}`,
      action: () => {
        actions.deleteMerchstore && actions.deleteMerchstore();
      },
    },
  };

  const hasPendingOrder = merchstore.hasPendingOrder;

  let merchologistDropdowns: any[];
  let adminDropdowns: any[];
  let superuserDropdowns: any[];

  if (merchstore.status !== "closed") {
    merchologistDropdowns = [
      hasPendingOrder ? dropdowns.viewPendingOrder : null,
      dropdowns.viewEdit,
      dropdowns.close,
    ];

    adminDropdowns = [
      hasPendingOrder ? dropdowns.viewPendingOrder : null,
      dropdowns.viewEdit,
      dropdowns.close,
    ];

    superuserDropdowns = [
      hasPendingOrder ? dropdowns.viewPendingOrder : null,
      dropdowns.viewEdit,
      dropdowns.reassign,
      dropdowns.rebuild,
      dropdowns.close,
    ];
  } else {
    merchologistDropdowns = [
      hasPendingOrder ? dropdowns.viewPendingOrder : null,
      dropdowns.viewEdit,
      dropdowns.reOpen,
    ];

    adminDropdowns = [
      hasPendingOrder ? dropdowns.viewPendingOrder : null,
      dropdowns.viewEdit,
    ];

    superuserDropdowns = [
      hasPendingOrder ? dropdowns.viewPendingOrder : null,
      dropdowns.viewEdit,
      dropdowns.reOpen,
    ];
  }

  if (
    ["draft", "closed"].includes(merchstore.status) &&
    (!merchstore.individualOrdersPendingCount ||
      merchstore.individualOrdersPendingCount <= 0)
  ) {
    merchologistDropdowns.push(dropdowns.delete);
    superuserDropdowns.push(dropdowns.delete);
  }

  const dropdownsByRole = new Map(
    Object.entries({
      SUPERUSER: superuserDropdowns,
      MERCHOLOGIST: merchologistDropdowns,
      ADMIN: adminDropdowns,
      DEFAULT: adminDropdowns,
    })
  );

  const optionsForRole =
    dropdownsByRole.get(role) || dropdownsByRole.get("DEFAULT") || [];

  const availableOptions = optionsForRole.filter(Boolean);

  return availableOptions;
};
