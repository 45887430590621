import { getMerchStoreGiftCardsStatus } from "@merchstores/admin/api/merchstores/giftCards";
import { StoreContext } from "@merchstores/admin/context/Store";
import { IMerchStoreGiftCardsStatus } from "@merchstores/admin/types/merchstore";
import { useContext } from "react";
import { useState } from "react";

interface UseGiftCardsStatusAsyncResult {
  isLoading: boolean;
  getGiftCardsStatusAsync: (
    storeCode: string
  ) => Promise<IMerchStoreGiftCardsStatus>;
}

const useGiftCardsStatusAsync = (): UseGiftCardsStatusAsyncResult => {
  const [isLoading, setIsLoading] = useState(false);
  const { setGiftCardsStatus } = useContext(StoreContext);

  const getGiftCardsStatusAsync = async (
    storeCode: string
  ): Promise<IMerchStoreGiftCardsStatus> => {
    setIsLoading(true);
    const result = await getMerchStoreGiftCardsStatus(storeCode);
    setGiftCardsStatus(result);
    setIsLoading(false);
    return result;
  };

  return { isLoading, getGiftCardsStatusAsync };
};

export default useGiftCardsStatusAsync;
