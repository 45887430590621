import Collapsible from "react-collapsible";

import { MdAdd, MdRemove } from "react-icons/md";
import { useState } from "react";

import { ADDRESS_FIELDS } from "@merchstores/admin/constants/shippingAddress";
import { GroupOrderAddress } from "@merchstores/admin/types/address";
import { CTA } from "@merchstores/shared/elements/Cta";

import useFetchShippingAddresses from "@merchstores/admin/hooks/address/useFetchShippingAddresses";
import { StoreContext } from "@merchstores/admin/context/Store";
import { useContext } from "react";

type Props = {
    groupOrderId: string;
    onEdit: (id: GroupOrderAddress) => void;
}

type AddressBlockProps = {
    address: GroupOrderAddress;
    onEdit: () => void;
}

type AddressBlockItemProps = {
    label: string;
    isLastItem: boolean;
    value: string | boolean;
}

function AddressBlockItem(props: AddressBlockItemProps) {
    const { label, value, isLastItem } = props;

    return (
        <div
            className={`
                flex
                flex-row
                items-center
                justify-between
                h-40
                ${!isLastItem && 'border-b'}
            `}
        >
            <p className="text-merch-text-disabled text-14">
                {label}
            </p>
            <p className="text-merch-dark-gray text-14">
                {value}
            </p>
        </div>
    )
}

function AddressBlock(props: AddressBlockProps) {
    const { address, onEdit } = props;
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className={`member-list-container mb-4 p-3 ${isOpen && 'pb-0'}`}>
            <div className="flex flex-row items-center justify-between h-27">
                <div className="flex flex-row items-center">
                    <p className="text-merch-dark-gray text-14 font-bold">
                        {address.locationDisplayName}
                    </p>
                    {address.isDefault && (
                        <p className="text-merch-text-disabled italic text-12 ml-3">
                            Default Address
                        </p>
                    )}
                </div>
                <div className="flex flex-row">
                    <CTA
                        type='flat'
                        size='small'
                        onClick={onEdit}
                    >
                        Edit
                    </CTA>
                    <CTA
                        type='clean'
                        size='small'
                        classes="pr-0"
                        icon={isOpen ? <MdRemove size="20px" /> : <MdAdd size="20px" />}
                        onClick={() => setIsOpen(!isOpen)}
                    />
                </div>
            </div>
            {/* eslint-disable */}
            {/* @ts-ignore */}
            <Collapsible open={isOpen} transitionTime={1}>
                {ADDRESS_FIELDS.map((field, index) =>
                    <AddressBlockItem
                        key={index}
                        label={field.label}
                        isLastItem={index === ADDRESS_FIELDS.length - 1}
                        value={address[field.id as keyof GroupOrderAddress]}
                    />
                )}
            </Collapsible>

        </div>
    )
}

export default function ShippingAddressTabContent(props: Props): JSX.Element {
    const { groupOrderId, onEdit } = props;
    const { state: { shippingAddresses, isShippingAddressesLoading } } = useContext(StoreContext);

    useFetchShippingAddresses(groupOrderId);

    return (
        <div className="flex flex-col items-center justify-center">
            {isShippingAddressesLoading && (
                <div className="flex items-center justify-center text-sm text-merch-text-disabled mb-3">
                    Loading...
                </div>
            )}
            {shippingAddresses?.map((address: GroupOrderAddress, index: number) => (
                <AddressBlock key={index} address={address} onEdit={() => onEdit(address)} />
            ))}
        </div>
    );
}