import { IMerchStore } from "../MerchStore";

export interface IScheduleOption
{
  displayText: string;
  value: string;
}

export const WEEKLY = {
  value: "Weekly", 
  displayText: "Weekly",
  days: 7
}

export const EVERY_TWO_WEEK = {
  value: "Every-two-weeks", 
  displayText: "Every 2 weeks",
  days: 14
}

export const MONTHLY = {
  value: "Monthly", 
  displayText: "Monthly",
  days: 30
}

export function defaultScheduleOption()
{
  return EVERY_TWO_WEEK;
}

export function generateMerchStoreScheduleOptions(merchStore: IMerchStore): IScheduleOption[]
{
  const defaultOptions = [
      WEEKLY,
      EVERY_TWO_WEEK
  ];

  const formerOptions = defaultOptions.concat([
      MONTHLY
  ])

  if (merchStore.status === "closed")
  {
      return formerOptions;
  }

  return defaultOptions;
}