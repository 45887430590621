import { Card } from "@merchstores/shared/elements/Card";
import './styles.scss';
import { useForm, useWatch } from "react-hook-form";
import { FormInput } from "@merchstores/shared/elements/FormInput";
import { CTA } from "@merchstores/shared/elements/Cta";
import { useContext } from "react";
import { Context } from "../../../context";
import { IGroupMemberLoginProps } from ".";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";

export const GroupMemberLogin = (props: IGroupMemberLoginProps) => {

    const { register, handleSubmit, formState: { errors }, setError, control } = useForm();
    const { loginGroupMember } = useContext(Context);
    const [passwordType, setPasswordType] = useState("none");
    const [groupOrder, setGroupOrder] = useState(null);
    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(true);

    const password = useWatch({
        control,
        name: "password",
        defaultValue: ""
    });

    useEffect(() => {
        if (props.requiresPassword) {
            setPasswordType(props.requiresPassword);
        }

        if (props.groupOrder) {
            setGroupOrder(props.groupOrder);
        }
    }, [props.requiresPassword, props.groupOrder]);

    useEffect(() => {
        if (password.length > 0) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    }, [password]);

    const onSubmit = (vals: any) => {
        setLoading(true);
        if (vals.password) {
            switch (passwordType) {
                case "storefront":
                    // Checks if the GO's pw matches what the user entered.
                    if (vals.password === groupOrder?.password) {
                        loginGroupMember(groupOrder.groupOrderId);

                    } else {
                        setLoading(false);
                        setError("password", { type: "incorrect", message: "This password is incorrect." });
                    }
                    break;
                case "member":
                    // Fetch the member from that group order which has the entered password assigned.
                    axios.post('/.netlify/functions/fetchOrderMembersAuth', { orderId: groupOrder.groupOrderId, uniquePassword: vals.password })
                        .then((res: any) => {
                            const data = res.data;
                            if (data && data.length > 0) {
                                if (data.length === 1) {
                                    loginGroupMember(groupOrder.groupOrderId, data[0].email);
                                } else {
                                    setLoading(false);
                                    setError(
                                        "password", 
                                        { 
                                            type: "incorrect", 
                                            message: "There are multiple members with that password. Please contact the administrator."
                                        }
                                    );
                                }
                            } else {
                                setLoading(false);
                                setError("password", { type: "incorrect", message: "This password is incorrect." });
                            }
                        });
                    break;
            }
        }
    }

    return <div className="flex justify-center items-center relative">
        <div className="group-member-page-login-container flex justify-center items-center">
            <Card>
                <div className="flex flex-col justify-between items-center h-full pt-40 pb-30 pr-30 pl-30 ">
                    <img alt="Store Logo" src={groupOrder?.storeLogo} />
                    <h1 className="text-center text-lg mt-20 mb-8px font-bold">Welcome to the {groupOrder?.name} group order!</h1>
                    <p className="text-center text-base mb-15">Please enter the password you received in your email.</p>
                    <form onSubmit={handleSubmit(onSubmit)} className="form w-full">
                        <FormInput errors={errors} label="Password" name="password" type="password" register={register} required={true} />
                        <CTA 
                            type="primary" 
                            formSubmit={true} 
                            size="flex" 
                            classes={`w-full mt-5 `} 
                            disabled={loading || disabled ? true : false}
                        >
                            {loading ? "Submitting..." : "Submit"}
                        </CTA>
                    </form>
                </div>
            </Card>
        </div>
    </div>
}