import { useState } from "react";
import { useContext } from "react";
import { Route } from "react-router-dom";
import { SidebarMenu } from "../components/Common/SidebarMenu";
import { Login } from "../components/Login";
import { Context } from "./index";
import "./styles.scss";

interface IAuthProps {
  path: any,
  component: any
}

const paths = [
	{
		path: "/admin/group-orders",
		permissions: [
			"SUPERUSER",
			"MERCHOLOGIST",
			"ADMIN"	
			]
	},
	{
		path: "/admin/group-orders/:id",
		permissions: [
			"SUPERUSER",
			"MERCHOLOGIST",
			"ADMIN"
			]
	},
	{
		path: "/admin/group-orders/new",
		permissions: [
			"SUPERUSER",
			"MERCHOLOGIST"	
			]
	},
	{
		path: "/admin/merchstores/new",
		permissions: [
			"SUPERUSER",
			"MERCHOLOGIST"	
			]
	},
  {
    path: "/admin/merchstores",
    permissions: [
      "SUPERUSER",
			"MERCHOLOGIST",
			"ADMIN"	
    ]
  },
  {
	path: "/admin/merchstores/browse-search-testing",
	permissions: [
		"SUPERUSER",
		"MERCHOLOGIST",
		"ADMIN"
		]
  },
  {
	path: "/admin/merchstores/:id",
	permissions: [
		"SUPERUSER",
		"MERCHOLOGIST",
		"ADMIN"
		]
  },
  {
	path: "/admin/merchstores/:id/orders/active",
	permissions: [
		"SUPERUSER",
		"MERCHOLOGIST",
		"ADMIN"
		]
	},
	{
	path: "/admin/merchstores/:id/orders/:orderId",
	permissions: [
		"SUPERUSER",
		"MERCHOLOGIST",
		"ADMIN"
		]
	},
]

export const AuthenticatedRoute = (props: IAuthProps) => {
  const { path, component: Component } = props;
  const { loggedIn, userRole } = useContext(Context);
  const [sidebarActive, setSidebarActive] = useState(false);

  const userHasPermission = () => {
		if (path && loggedIn) {
			const pathPermissions = paths.find(p => p.path === path);
			if (pathPermissions) {
				if (pathPermissions.permissions.findIndex(p => p === userRole) > -1) {
					return true;
				}
				return false;
			}
			return false;
		}
		return false;
	}

    const onSidebarToggle = (value: boolean) => {
        setSidebarActive(value);
    }

  return (
    <Context.Consumer>
      {() => (
        userHasPermission() ?  
        <Route
          path={path}
          render={(props: any) =>
              <div className="relative flex" style={{overflow: "overlay"}}>
                <SidebarMenu onSidebarToggle={onSidebarToggle}></SidebarMenu>
                <div className={`relative w-full justify-center main-container ${sidebarActive ? "ml-60" : ""}`}>
                  <Component {...props} />
                </div>
              </div>
            }
        />
        : 
        <Route
          path='/'
          component={Login}
        />
        
      )}
    </Context.Consumer>
  )
}