import GraphQLClient from "graphql-js-client";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ScrollContext } from "react-router-scroll-4";
import { ToastContainer } from "react-toastify";
import { SubmissionThankYouPage } from "./components/GroupMemberLayout/SubmissionThankYouPage";
import { CreateEditGroupOrder } from "./components/CreateEditGroupOrder";
import { CreateEditMerchStore } from "./components/CreateEditMerchStore";
import { RecoverPassword } from "./components/Login/RecoverPassword";
import { GroupMemberLayout } from "./components/GroupMemberLayout";
import { ContextProvider, AuthenticatedRoute } from "./context";
import { MerchStoreList } from "./components/MerchStoreList";
import { ReactQueryDevtools } from "react-query/devtools";
import { StoreContextProvider } from "./context/Store";
import { GroupOrders } from "./components/GroupOrders";
import { Login } from "./components/Login";
import { Auth } from "./components/Auth";
import { HexagonTiles } from "./shared/elements/HexagonTiles";
import typeBundle from "./types/types";

import "react-toastify/dist/ReactToastify.css";

require("dotenv").config();

const SHOPIFY_GRAPHQL_STOREFRONT_API_URL =
  process.env.REACT_APP_SHOPIFY_GRAPHQL_STOREFRONT_API_URL;
const SHOPIFY_STOREFRONT_ACCESS_TOKEN =
  process.env.REACT_APP_SHOPIFY_STOREFRONT_ACCESS_TOKEN;
const MERCHSTORES_SHOPIFY_STOREFRONT_URL =
  process.env.REACT_APP_MERCHSTORES_SHOPIFY_STOREFRONT_URL;
const MERCHSTORES_SHOPIFY_STOREFRONT_ACCESS_TOKEN =
  process.env.REACT_APP_MERCHSTORES_SHOPIFY_STOREFRONT_ACCESS_TOKEN;

export const storefrontClient = new GraphQLClient(typeBundle, {
  url: SHOPIFY_GRAPHQL_STOREFRONT_API_URL,
  fetcherOptions: {
    headers: {
      "X-Shopify-Storefront-Access-Token": SHOPIFY_STOREFRONT_ACCESS_TOKEN,
    },
  },
});

export const merchstoreStorefrontClient = new GraphQLClient(typeBundle, {
  url: MERCHSTORES_SHOPIFY_STOREFRONT_URL,
  fetcherOptions: {
    headers: {
      "X-Shopify-Storefront-Access-Token":
        MERCHSTORES_SHOPIFY_STOREFRONT_ACCESS_TOKEN,
    },
  },
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 30,
    },
  },
});

export const App = () => {
  return (
    <>
      <BrowserRouter basename={"/"}>
        <ContextProvider>
          <StoreContextProvider>
            <ScrollContext>
              <QueryClientProvider client={queryClient}>
                <Switch>
                  <Route exact path={"/login"} component={Login} />
                  <Route exact path={"/"} component={Login} />
                  <Route
                    exact
                    path={"/forgot-password"}
                    component={RecoverPassword}
                  />
                  <Route
                    exact
                    path={"/auth/:token/:redirect?"}
                    component={Auth}
                  />
                  <Route 
                    exact 
                    path={"/admin"} 
                    component={Login} 
                  />
                  <AuthenticatedRoute
                    exact
                    path={"/admin/group-orders"}
                    component={GroupOrders}
                  />
                  <AuthenticatedRoute
                    exact
                    path={"/admin/group-orders/new"}
                    component={CreateEditGroupOrder}
                  />
                  <AuthenticatedRoute
                    exact
                    path={"/admin/group-orders/:id"}
                    component={CreateEditGroupOrder}
                  />
                  <AuthenticatedRoute
                    exact
                    path={"/admin/merchstores"}
                    component={MerchStoreList}
                  />
                  <AuthenticatedRoute
                    exact
                    path={"/admin/merchstores/:id"}
                    component={CreateEditMerchStore}
                  />
                  <AuthenticatedRoute
                    exact
                    path={"/admin/merchstores/:id/orders/active"}
                    component={CreateEditMerchStore}
                  />
                  <AuthenticatedRoute
                    exact
                    path={"/admin/merchstores/:id/orders/:orderId"}
                    component={CreateEditMerchStore}
                  />
                  <Route
                    exact
                    path={"/thank-you-page"}
                    component={SubmissionThankYouPage}
                  />
                  <Route
                    path={"/:storeUrl/:storeUrl2"}
                    component={GroupMemberLayout}
                  />
                  <Route path={"/:storeUrl"} component={GroupMemberLayout} />
                </Switch>
                <ReactQueryDevtools initialIsOpen={false} />
              </QueryClientProvider>
            </ScrollContext>
          </StoreContextProvider>
        </ContextProvider>
      </BrowserRouter>
      <ToastContainer position="bottom-right" />
      <HexagonTiles />
    </>
  );
};
