import axios from "axios";
import { IMerchStoreProps } from ".";

const CACHE_EXPIRE_SECONDS = 60;

const RequestQueryCache = new Map();

setTimeout(() => {
  RequestQueryCache.clear();
}, 1000 * CACHE_EXPIRE_SECONDS);

function cacheKey(queryParameters: IMerchStoresQueryParameters): string {
  return JSON.stringify(queryParameters);
}

export interface IMerchStoresQueryParameters {
  email?: string;
  role?: string;
  status?: string | null;
  search?: string | null;
  sorting?: string | null;
  limit?: number;
  customerContact?: string | null;
  merchologist?: string | null;
  payerType?: string | null;
  offset?: number;
  isDeleted?: boolean;
}

export interface IMerchStoresQueryResult {
  hasNextPage: boolean;
  totalCount: number;
  merchStores: IMerchStoreProps[];
  merchologists: string[];
  customerContacts: string[];
}

export async function clearQueryCache() {
  RequestQueryCache.clear();
  return true;
}

export async function queryMerchstores(
  queryParameters: IMerchStoresQueryParameters
): Promise<IMerchStoresQueryResult | null> {
  const cachedResult = RequestQueryCache.get(cacheKey(queryParameters));

  if (cachedResult) {
    return cachedResult;
  }

  const fetchResult = await axios
    .post("/.netlify/functions/fetchMerchstores", queryParameters)
    .catch((err) => {
      console.error(err);
      return null;
    });

  if (!fetchResult) {
    return null;
  }

  const queryResults = fetchResult.data as IMerchStoresQueryResult;
  RequestQueryCache.set(cacheKey(queryParameters), queryResults);

  return queryResults;
}
