import { ITableDisplayColumns } from './MerchStoresTable';

interface IColumn {
  text: string;
  class?: string;
  display?: boolean;
}

interface ITableColumns {
  merchstore: IColumn;
  customerContact: IColumn;
  merchologyContact: IColumn;
  payments: IColumn;
  storeId: IColumn;
  orderCount: IColumn;
  pendingOrdersCount: IColumn;
  revenue: IColumn;
  pendingRevenue: IColumn;
  close: IColumn;
  status: IColumn;
  pendingOrdersIndicator: IColumn;
  dropdown: IColumn;
}

function allColumns(): ITableColumns {
  return {
    merchstore: {
      text: 'MerchStore',
      class: 'text-left',
    },
    customerContact: {
      text: 'Customer Contact',
      class: 'text-left pl-10',
    },
    merchologyContact: {
      text: 'Merchology Contact',
      class: 'text-left',
    },
    payments: {
      text: 'Payments',
      class: 'text-left',
    },
    storeId: {
      text: 'Store ID',
      class: 'text-left',
    },
    orderCount: {
      text: 'Orders',
      class: 'text-center',
    },
    pendingOrdersCount: {
      text: 'Pending Orders',
      class: 'text-center',
    },
    revenue: {
      text: 'Revenue',
    },
    pendingRevenue: {
      text: 'Pending Revenue',
    },
    close: {
      text: 'Close',
    },
    status: {
      text: 'Status',
    },
    pendingOrdersIndicator: {
      text: '',
    },
    dropdown: {
      text: '',
    },
  };
}

export function buildColumns(
  role: string,
  displayColumns?: ITableDisplayColumns
) {
  displayColumns = displayColumns || {};

  const availableColumns: ITableColumns = allColumns();

  for (const [columnName, displayColumn] of Object.entries(displayColumns)) {
    if (availableColumns[columnName as keyof ITableColumns]) {
      availableColumns[columnName as keyof ITableColumns].display =
        displayColumn;
    }
  }

  let exposedColumns = [];

  switch (role) {
    case 'SUPERUSER':
      exposedColumns = pickSuperUserColumns(availableColumns);
      break;
    case 'MERCHOLOGIST':
      exposedColumns = pickMerchologistColumns(availableColumns);
      break;
    case 'ADMIN':
      exposedColumns = pickAdminColumns(availableColumns);
      break;
    default:
      exposedColumns = pickAdminColumns(availableColumns);
  }

  return exposedColumns.filter((column) => column.display !== false);
}

function pickMerchologistColumns(columns: ITableColumns): IColumn[] {
  return [
    columns.merchstore,
    columns.customerContact,
    columns.merchologyContact,
    columns.storeId,
    columns.payments,
    columns.orderCount,
    columns.pendingOrdersCount,
    columns.revenue,
    columns.pendingRevenue,
    columns.close,
    columns.dropdown,
  ];
}

function pickSuperUserColumns(columns: ITableColumns): IColumn[] {
  return [
    columns.merchstore,
    columns.customerContact,
    columns.merchologyContact,
    columns.storeId,
    columns.payments,
    columns.orderCount,
    columns.pendingOrdersCount,
    columns.revenue,
    columns.pendingRevenue,
    columns.close,
    columns.dropdown,
  ];
}

function pickAdminColumns(columns: ITableColumns): IColumn[] {
  return [
    columns.merchstore,
    columns.customerContact,
    columns.storeId,
    columns.payments,
    columns.orderCount,
    columns.close,
    columns.dropdown,
  ];
}
