import { useEffect } from "react";
import { useState } from "react";
import { useForm, useWatch } from "react-hook-form";

import { CTA } from "@merchstores/shared/elements/Cta";
import { FormInput } from "@merchstores/shared/elements/FormInput";

import countriesInformation from "i18n-iso-countries/langs/en.json";

import { FormSelect } from "@merchstores/shared/elements/FormSelect";
import _ from "lodash";
import { testAddress } from "../../MerchStoreOrderCheckout";
import { IMerchStoreContactInfo } from "@merchstores/admin/components/MerchStore";

import "./ContactInfoForm.scss";

export interface IContactInfoFormProps {
  contactInfo: IMerchStoreContactInfo;
  onSubmitAction?: (data: IMerchStoreContactInfo) => void;
  onCancelAction?: () => void;
}

interface IValidateAddressResult {
  success: boolean;
  errors: string[];
}

const validateAddress = async (data: IMerchStoreContactInfo): Promise<IValidateAddressResult> => {
  const checkoutResponse = await testAddress(data as IMerchStoreContactInfo);

  const validationResult: IValidateAddressResult = {
    success: false,
    errors: []
  }

  if (checkoutResponse.errors && checkoutResponse.errors.length) {
    const errors = checkoutResponse.errors.map(err => String(err.message || err));
    validationResult.errors = errors;
  } else {
    validationResult.success = true;
  }

  return validationResult;
}

export const ContactInfoForm = (props: IContactInfoFormProps): JSX.Element => {
  const [contactInfo] = useState(props.contactInfo);

  const { register, handleSubmit, setValue, formState: { errors }, control, setError, clearErrors, reset } = useForm();

  const [countries, setCountries] = useState([]);
  const [defaultCountry, setDefaultCountry] = useState("");
  const [addressError, setAddressError] = useState(null);

  const onCancelAction = () => {
    reset();

    if (props.onCancelAction) {
      props.onCancelAction();
    }
  }

  const onSubmitAction = async (data: IMerchStoreContactInfo) => {
    const validateResult = await validateAddress(data).catch(err => {
      setAddressError(`
        The contact address doesn't have a valid format. 
        Please update it before saving your Merchstore.
      `);
      throw err;
    });

    if (validateResult.errors.length) {
      setAddressError(_.join(validateResult.errors, "\n"))
      return false;
    }

    setAddressError(null);

    if (props.onSubmitAction) {
      props.onSubmitAction(data);
    }
  }

  const phoneNumber = useWatch({
    control,
    name: "phone",
    defaultValue: ""
  });

  useEffect(() => {
    if (countriesInformation && countriesInformation.countries) {
      const countriesNames = Object.values(countriesInformation.countries);
      const countries = countriesNames.map(c => {
        let countryName = "";
        if (Array.isArray(c)) {
          countryName = c[0];
        } else {
          countryName = c;
        }
        if (countryName.toLowerCase() === "united states of america") {
          countryName = "United States";
        }
        return { displayText: countryName, value: countryName };
      })
      setCountries(countries);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countriesInformation])


  useEffect(() => {
    if (countries && contactInfo) {
      if (contactInfo) {
        setDefaultCountry(contactInfo.country);
        setValue("phone", contactInfo.phone);
      } else {
        setDefaultCountry("United States");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countries, contactInfo]);

  useEffect(() => {
    const regex = /(\+*[0-9]{1,3})*\s*[(]{0,1}[0-9]{1,4}[)]{0,1}\s*[-\s./0-9]{0,20}$/g;
    if (phoneNumber && !phoneNumber.match(regex)) {
      setError("phone", {
        type: "invalid",
        message: "Please input a valid phone number."
      });
    } else {
      clearErrors("phone");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phoneNumber])

  return (
    <form onSubmit={handleSubmit(onSubmitAction)} className="w-full" id="create-edit-contact-form">
      <FormInput
        name="email"
        type="email"
        register={register}
        errors={errors}
        label="Email"
        required={true}
        defaultValue={contactInfo ? contactInfo.email : ""} />

      <FormInput
        label="Company"
        name="company"
        errors={errors}
        register={register}
        required={true}
        type="text"
        defaultValue={contactInfo ? contactInfo.company : ""} />
      <div className="flex two-inputs-50-50">
        <FormInput
          label="First name"
          name="firstName"
          errors={errors}
          register={register}
          required={false}
          type="text"
          defaultValue={contactInfo ? contactInfo.firstName : ""} />
        <FormInput
          label="Last name"
          name="lastName"
          errors={errors}
          register={register}
          required={true}
          type="text"
          defaultValue={contactInfo ? contactInfo.lastName : ""} />
      </div>

      <div className="flex two-inputs-60-40">
        <FormInput
          label="Street Address"
          name="address1"
          errors={errors}
          register={register}
          required={true}
          type="text"
          defaultValue={contactInfo ? contactInfo.address1 : ""} />
        <FormInput
          label="Apt, Suite, etc."
          name="address2"
          errors={errors}
          register={register}
          required={false}
          type="text"
          defaultValue={contactInfo ? contactInfo.address2 : ""} />
      </div>

      <div className="flex two-inputs-50-50">
        <FormInput
          label="City"
          name="city"
          errors={errors}
          register={register}
          required={true}
          type="text"
          defaultValue={contactInfo ? contactInfo.city : ""} />
        <FormInput
          label="State"
          name="province"
          errors={errors}
          register={register}
          required={true}
          type="text"
          defaultValue={contactInfo ? contactInfo.province : ""} />
      </div>

      <div className="flex two-inputs-60-40">
        <FormSelect
          label="Country"
          name="country"
          errors={errors}
          register={register}
          setValue={setValue}
          options={countries}
          default={defaultCountry}
          selectStyle="two" />
        <FormInput
          label="Postal code"
          name="zip"
          errors={errors}
          register={register}
          required={true}
          type="text"
          defaultValue={contactInfo ? contactInfo.zip : ""} />
      </div>
      <FormInput
        label="Phone"
        name="phone"
        errors={errors}
        register={register}
        required={true}
        type="text"
        defaultValue={contactInfo ? contactInfo.phone : ""} />
      {addressError && <p className="error pl-2 pb-2">{addressError}</p>}

      <div className="flex justify-between">
        <CTA
          size="large"
          type="secondary"
          onClick={onCancelAction}
          classes="mr-16">
          Cancel
        </CTA>
        <CTA
          size="large"
          type="primary"
          formSubmit={true}
          disabled={!_.isEmpty(errors)}>
          Save
        </CTA>
      </div>
    </form>
  )
}