import { GroupOrderAddress, GroupOrderAddressInput } from "../types/address";

const fetchShippingAddresses = async (groupOrderId: string): Promise<Array<GroupOrderAddress>> => {
  const response = await fetch(
    `/.netlify/functions/hubspotGroupOrderToolShippingAddress?groupOrderId=${groupOrderId}`,
    {
      method: "GET",
    }
  );
  return await response.json();
};

const fetchShippingAddressForm = async (): Promise<any> => {
  const response = await fetch(
    `/.netlify/functions/hubspotGroupOrderToolShippingAddressForm`,
    {
      method: "GET",
    }
  );
  return await response.json();
};

const addShippingAddress = async (input: GroupOrderAddressInput): Promise<string> => {
  const response = await fetch(
    `/.netlify/functions/hubspotGroupOrderToolShippingAddressForm`,
    {
      method: "POST",
      body: JSON.stringify(input),
    }
  );
  return await response.json();
};

const updateShippingAddress = async (id: string, input: GroupOrderAddressInput): Promise<string> => {
  const response = await fetch(
    `/.netlify/functions/hubspotGroupOrderToolShippingAddressForm?id=${id}`,
    {
      method: "PATCH",
      body: JSON.stringify(input),
    }
  );
  return await response.text();
};

export {
  addShippingAddress,
  updateShippingAddress,
  fetchShippingAddresses,
  fetchShippingAddressForm,
};
