import BigNumber from "bignumber.js";

interface IOrderWithSummary
{
  totalPrice: string;
  totalTax: string;
  totalShippingPrice: string;
}

export function calculateSummaryTotal(order: IOrderWithSummary) : BigNumber
{
  const orderTotalPrice = new BigNumber(order.totalPrice);

  // totalPrice includes shipping and tax
  //const orderTotalShipping = new BigNumber(order.totalShippingPrice);
  //const orderTotalTax = new BigNumber(order.totalTax);

  return orderTotalPrice;
}

export default {
  calculateSummaryTotal
}