import { IOrderAddress } from "@merchstores/admin/types/merchstoreOrder";

interface Props {
  billingAddress?: IOrderAddress;
}

const BillingAddress = (props: Props): JSX.Element => {
  const { billingAddress } = props;

  return (
    <div className='md:flex-grow'>
      <div className='section-title mb-10 font-bold text-xs'>
        Billing Address
      </div>
      <div className='section-info'>
        {billingAddress && <>
          <div className="text-sm">{billingAddress.address1} {billingAddress.address2}</div>
          <div className="text-sm">{billingAddress.city} {billingAddress.province} {billingAddress.zip}</div>
          <div className="text-sm">{billingAddress.country}</div>
        </>
        }
      </div>
    </div>
  );
};

export default BillingAddress;