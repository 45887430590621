import axios from "axios";
import { useState, useEffect } from "react";
import { ICreateEditPackageProps, IPackageFormData } from "./index";
import { Modal } from "react-responsive-modal";
import { useForm } from "react-hook-form";
import { FormInput } from "@merchstores/shared/elements/FormInput";
import { Checkbox } from "@merchstores/shared/elements/Checkbox";
import { CTA } from "@merchstores/shared/elements/Cta";
import { CloudinaryFileInput } from "@merchstores/shared/elements/CloudinaryFileInput";
import { MdClear } from "react-icons/md";
import { toast } from 'react-toastify';
import "react-responsive-modal/styles.css";
import './styles.scss';
import { useContext } from "react";
import { Context } from "../../context";
import { userHasRole, Role, ROLE_SUPERUSER, ROLE_MERCHOLOGIST } from "@merchstores/admin/context/Roles";

export const CreateEditPackage = (props: ICreateEditPackageProps) => {
  const { register, handleSubmit, formState: { errors }, reset, setValue } = useForm();
  const [groupOrderId, setGroupOrderId] = useState(null);
  const [packageFormData, setPackageFormData] = useState({});
  const [packageError, setPackageError] = useState(false);

  const [artworkOptions, setArtworkOptions] = useState(null);
  const [packageImages, setPackageImages] = useState(null);
  const [requireSizeSelection, setRequireSizeSelection] = useState(false);
  const [requireStyleSelection, setRequireStyleSelection] = useState(false);
  const [artworkOptionsError] = useState(false);
  const [packageImagesError] = useState(false);
  const { userRole } = useContext(Context);

  useEffect(() => {
    if (props.groupOrderId) {
      setGroupOrderId(props.groupOrderId);
    }
  }, [props.groupOrderId])

  useEffect(() => {
    reset();
    setPackageFormData(props.packageFormData);
    setRequireSizeSelection(props.packageFormData ? props.packageFormData.sizeRequired : false);
    setRequireStyleSelection(props.packageFormData ? props.packageFormData.styleRequired : false);
    setArtworkOptions(
      props.packageFormData && props.packageFormData.artwork ? 
      props.packageFormData.artwork.map((artwork: any) => { return artwork.logo; }) : undefined
    );
    setPackageImages(
      props.packageFormData && props.packageFormData.images ? 
      props.packageFormData.images.map((image: any) => { return image.url; }) : undefined
    );
  }, [props.packageFormData])

  const onSubmit = (formData: IPackageFormData) => {
    const artwork = artworkOptions && artworkOptions.length > 0 ? artworkOptions.map((art: string) => { return { logo: art } }) : [];
    const images = packageImages && packageImages.length > 0 ? packageImages.map((art: string) => { return { url: art } }) : [];
    axios.post('/.netlify/functions/addPackage', {
      groupOrderId,
      packageData: JSON.stringify({
        id: packageFormData ? packageFormData.id : undefined,
        title: formData.title,
        description: formData.description,
        internalItemDescription: (formData.internalItemDescription || '').trim(),
        sizes: formData.sizes,
        sizeRequired: requireSizeSelection,
        styleRequired: requireStyleSelection,
        artwork,
        images,
        styles: formData.styles
      })
    }).then(() => {
      if (props.onCloseModal) {
        clearForm();
        if (props.onPackageCreatedOrEdited) {
          props.onPackageCreatedOrEdited();
          toast.success("Package added successfully")
        }
        props.onCloseModal();
      }
    }).catch((err: unknown) => {
     toast.error('An error occured saving the Package, please try again.');
      
      console.error(err);
    })
  }

  const clearForm = () => {
    setPackageError(false);
    reset();
    setRequireSizeSelection(false);
    setRequireStyleSelection(false);
    setArtworkOptions(null);
    setPackageImages(null);
  }

  const onStandardSizingClick = () => {
    const sizesInput = document.getElementById('sizesInput') as HTMLInputElement;
    if (sizesInput != null) {
      sizesInput.focus();
      sizesInput.value = 'X-Small, Small, Medium, Large, X-Large';
      setValue("sizes", 'X-Small, Small, Medium, Large, X-Large');
    }
  }

  const requireSizeSelectionChange = (newValue: boolean) => {
    setRequireSizeSelection(newValue);
  }

  const requireStyleSelectionChange = (newValue: boolean) => {
    setRequireStyleSelection(newValue);
  }

  const handleArtworkChange = (artworkData: any) => {
    if (artworkData.length) {
      setArtworkOptions(artworkData);
    } else {
      setArtworkOptions(null);
    }
  }

  const handlePackageImagesChange = (packageImageData: any) => {
    if (packageImageData.length > 0) {
      setPackageImages(packageImageData);
    } else {
      setPackageImages(null);
    }
  }

  const exposeInternalItemDescription = userHasRole(new Role(userRole), [ ROLE_SUPERUSER, ROLE_MERCHOLOGIST ]);

  const modalCloseIcon = (
    <div className="rounded-full bg-gray-200 text-12 absolute top-2 right-2 w-30 h-30 flex items-center justify-center"><MdClear /></div>
  );

  return (
    <div className="flex flex-col">
      <Modal open={props.modalOpened} onClose={props.onCloseModal} center classNames={{ modal: 'add-package-modal' }} closeIcon={modalCloseIcon}>
        <div className="text-xl font-bold">{packageFormData ? userRole !== "ADMIN" ? 'Edit' : "View" : 'Add New'} Package</div>
        <form onSubmit={handleSubmit(data => onSubmit(data as IPackageFormData) )}>
          <div className="mt-35 font-bold text-sm">Package Details</div>
          <div className="my-20">
            <FormInput 
              id="titleInput" 
              name="title" 
              type="text" 
              register={register} 
              errors={errors} 
              required={true} 
              defaultValue={packageFormData ? packageFormData.title : ''} 
              placeholder="Title" 
            />
          </div>
          <div className="my-20">
            <FormInput 
              id="descriptionInput" 
              name="description" 
              textArea={true} 
              type="textarea" 
              register={register} 
              errors={errors} 
              required={false} 
              defaultValue={packageFormData ? packageFormData.description : ''} 
              placeholder="Description" 
              inputStyle="two" 
            />
          </div>

          { exposeInternalItemDescription ?
          <div className="my-20">
            <FormInput 
              id="internalItemDescriptionInput" 
              name="internalItemDescription" 
              label="Internal Item Description"
              placeholder="Internal Item Description" 
              textArea={true} 
              type="textarea" 
              register={register} 
              errors={errors} 
              required={false} 
              defaultValue={packageFormData ? packageFormData.internalItemDescription : ''} 
              inputStyle="two" />
          </div> 
          : '' }

          <Checkbox label={"Require size selection"} onChange={requireSizeSelectionChange} className="my-20" isSelected={requireSizeSelection} />
          <div className={requireSizeSelection ? 'block' : 'hidden'}>
            <FormInput 
              id="sizesInput" 
              name="sizes" 
              type="text" 
              register={register} 
              errors={errors} 
              required={false} 
              defaultValue={packageFormData ? packageFormData.sizes : ''} 
              placeholder="Add sizes separated by commas" 
            />
            <CTA size={'small'} type={'secondary'} onClick={onStandardSizingClick} classes="mb-20 mt-10">Standard Sizing</CTA>
          </div>
          <Checkbox label={"Require style selection"} onChange={requireStyleSelectionChange} className="my-20" isSelected={requireStyleSelection} />
          <div className={`${requireStyleSelection ? 'block' : 'hidden'} mb-10`}>
            <FormInput 
              id="styles" 
              name="styles" 
              type="text" 
              register={register} 
              errors={errors} 
              required={false} 
              defaultValue={packageFormData ? packageFormData.styles : ''} 
              placeholder="Add styles separated by commas" 
            />
          </div>
          <CloudinaryFileInput 
            error={artworkOptionsError} 
            name="artwork-options" type="one" 
            updateContent={handleArtworkChange} 
            content={artworkOptions ? artworkOptions : []} 
            maxFiles={3} 
            label="Artwork Options" 
            placeholder="JPEGs, PNGs or SVGs" 
            classes="py-10"
            helpText="Maximum 3 artwork files"
          />
          <CloudinaryFileInput 
            error={packageImagesError} 
            name="package-images" 
            type="two" 
            updateContent={handlePackageImagesChange} 
            content={packageImages ? packageImages : []} 
            maxFiles={3} 
            label="Package Images" 
            placeholder="" 
            classes="py-10" 
            helpText="Recommended size 1252 x 1252px" 
            helpText2="Limit 3 images"
          />
          {userRole !== "ADMIN" && <div className="flex justify-center mt-5 mb-2">
            <CTA formSubmit={true} size={'standard'} type={'primary'} classes="w-full h-45">Save Package</CTA>
          </div>
          }
          <div className="flex justify-center">
            <CTA 
              size={'standard'} 
              type={'secondary'} 
              classes="w-full h-45 text-14 text-opacity-50 border-none shadow-none" 
              onClick={props.onCloseModal}
            >
              Cancel
            </CTA>
          </div>
        </form>
        {packageError ? (<div>Error loading package information. Please try again.</div>) : ''}
      </Modal>
    </div>
  )
}