import axios from 'axios';

const getNetlifySnippets = (siteId: string) => {
    return axios.post("/.netlify/functions/getNetlifySnippets", {
        siteId
    }).then(response => {
        return response;
    });
}

const getNetlifySnippetByTitle = (siteId: string, snippetTitle: string) => {
    return getNetlifySnippets(siteId).then(response => {
        const snippet = response.data.find((s: any) => s.title.toLowerCase() === snippetTitle.toLowerCase());
        return snippet;
    })
}

export const addNetlifySnippet = (siteId: string, snippetTitle: string, snippetContent: string) => {
    const body = {
        siteId,
        snippetTitle,
        snippetContent
    };

    return axios.post('/.netlify/functions/createNetlifySnippet', body)
        .then((response) => {
            return response;
        })
        .catch(err => err);
}

export const updateNetlifySnippet = (siteId: string, snippetTitle: string, snippetContent: string) => {
    const body = {
        siteId,
        snippetTitle,
        snippetContent
    };

    return axios.post('/.netlify/functions/updateNetlifySnippet', body)
        .then((response) => {
            return response;
        })
        .catch(err => err);
}

export async function findAndUpdateNetlifySnippet(siteId: string, snippetTitle: string, snippetContent: string) {
    const snippet = await getNetlifySnippetByTitle(siteId, snippetTitle);
    if (snippet) {
        // Update snippet
        updateNetlifySnippet(siteId, snippetTitle, snippetContent);

    } else {
        // Create snippet
        addNetlifySnippet(siteId, snippetTitle, snippetContent);
    }
}

export const encodeSnippet = (content: string, name: string) => {
  const snippet = `\u003Cscript\u003Ewindow.${name}=${JSON.stringify(content)}\u003C/script\u003E`
  return snippet
}