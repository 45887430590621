
import { ILineItem, ILineItemFulfillment } from "@merchstores/admin/components/MerchStoreOrders";


export function findLineItemFulfillment(
  lineItem: ILineItem,
  fulfillments: Array<ILineItemFulfillment>
): ILineItemFulfillment | null {

  const fulfillmentFound = fulfillments.find(fulfilledItem => {

    return String(fulfilledItem.variantId).replace('gid://shopify/ProductVariant/', '') 
      === String(lineItem.variant.id).replace('gid://shopify/ProductVariant/', '');
  })

  if (fulfillmentFound) {
    return fulfillmentFound;
  }

  return null;
}

export function isOutOfStock(lineItemFulfillment: ILineItemFulfillment): boolean {
  if (!lineItemFulfillment.outOfStockQuantity) {
    return false;
  }

  return lineItemFulfillment.outOfStockQuantity > 0;
}

export function generateOutOfStockMessage(
  lineItem: ILineItem,
  lineItemFulfillment: ILineItemFulfillment
): string {
  return `
      This quantity (${lineItem.quantity}) of ${lineItem.title} - ${lineItem.variantTitle} - 
      exceeds available inventory (${lineItemFulfillment.fulfilledQuantity}).
    `
}