
const DisplayableError = {
  "name": "DisplayableError",
  "kind": "INTERFACE",
  "fieldBaseTypes": {
    "field": "String",
    "message": "String"
  },
  "possibleTypes": ["CheckoutUserError", "CustomerUserError", "UserError"]
};
export default DisplayableError;