import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

export const SHOPIFY_MERCH_SERVICES_STOREFRONT_URL = process.env.REACT_APP_SHOPIFY_MERCH_SERVICES_STOREFRONT_URL;
export const SHOPIFY_MERCH_SERVICES_STOREFRONT_TOKEN = process.env.REACT_APP_SHOPIFY_MERCH_SERVICES_STOREFRONT_TOKEN;
export const SHOPIFY_STOREFRONT_PROXY = process.env.REACT_APP_SHOPIFY_STOREFRONT_PROXY;

export const DEFAULT_API_VERSION = '2022-10';

export function resolveApiUrl(version?: string): string
{
  const apiVersion = version || DEFAULT_API_VERSION;

  const apiUrl = (SHOPIFY_MERCH_SERVICES_STOREFRONT_URL || '')
    .replace(/\/api\/\d{4}-\d{2}/g, `/api/${apiVersion}`);

  return apiUrl;
}

interface ICreateStorefrontApiClientOptions {
  endpointUrl?: string,
  apiKey?: string,
  useProxy?: boolean
}

export function createStorefrontApiClient(
  options?: ICreateStorefrontApiClientOptions
): AxiosInstance
{
  options = options || {};

  let endpointUrl = options.endpointUrl || resolveApiUrl();
  const apiKey = options.apiKey || SHOPIFY_MERCH_SERVICES_STOREFRONT_TOKEN;
  const useProxy = options.useProxy;


  if (useProxy || SHOPIFY_STOREFRONT_PROXY)
  {
    endpointUrl = '/.netlify/functions/shopifyStorefront';
  }

  const apiClient = axios.create({
    baseURL: endpointUrl
  });
  
  apiClient.defaults.headers.common['Content-Type'] = 'application/json';
  apiClient.defaults.headers.common['Accept'] = 'application/json';
  apiClient.defaults.headers.common['X-Shopify-Storefront-Access-Token'] = apiKey;

  apiClient.interceptors.response.use(
    // On Success
    function (response) {
      if (response && response.data && response.data.errors)
      {
        const shopifyError = new StorefrontApiError(
          'Storefront API Error: ' + JSON.stringify(response.data.errors),
          {
            errors: response.data.errors,
            request: response.config
          }
        );
        throw shopifyError;
      }

      response.data = response.data.data;

      return response;
    }, 
    // On Error
    function (err) {
      return Promise.reject(err);
    }
  );

  return apiClient;
}


export type IStorefrontErrorList = Array<{message: string}>;
export interface IStorefrontGraphQLQuery { query: string, variables?: unknown }

export interface IErrorInfo {
  errors: IStorefrontErrorList
  query: IStorefrontGraphQLQuery,
  request: AxiosRequestConfig
}

export class StorefrontApiError extends Error
{
  protected request;
  protected errors;

  constructor(
    msg: string, 
    data: { request: AxiosRequestConfig, errors: IStorefrontErrorList}
  ) 
  {
    super(msg);
    data = data || {}
    this.request = data.request;
    this.errors = data.errors;
  }

  getErrors(): IStorefrontErrorList
  {
    return this.errors;
  }

  getRequest(): AxiosRequestConfig
  {
    return this.request;
  }

  getQuery(): IStorefrontGraphQLQuery
  {
    return JSON.parse(this.request.data);
  }
  
  getInfo(): IErrorInfo
  {
    return {
      errors: this.getErrors(),
      query: this.getQuery(),
      request: this.getRequest()
    }
  }
}