import { IOrderDetailsView } from "@merchstores/admin/types/merchstoreOrder";
import { ACTIVE_ORDER_ID } from "@merchstores/admin/components/MerchStoreOrders";
import { IMerchStore } from "@merchstores/admin/components/MerchStore";
import { IShopifyOrder } from "@merchstores/admin/types/shopifyOrder";
import { Loading } from "@merchstores/shared/components/Loading";
import useOrder from "@merchstores/admin/hooks/orders/useOrder";
import CustomerContact from "./CustomerContact";
import BillingAddress from "./BillingAddress";
import PaymentDetails from "./PaymentDetails";
import OrderSummary from "./OrderSummary";
import BackButton from "./BackButton";

interface Props {
  order: IShopifyOrder;
  merchStore: IMerchStore,
  setSelectedOrderDetails(orderDetailsView: IOrderDetailsView): void;
}

const GiftCardOrderDetail = (props: Props): JSX.Element => {
  const { merchStore, order, setSelectedOrderDetails } = props;
  const { isLoading, data } = useOrder({
    storeCode: merchStore.storeCode,
    orderId: order.id,
  });

  const paymentDetail = {
    itemsQuantity: order.itemsCount || 0,
    subtotalAmount: (Number(order.totalPrice) - Number(order.totalTax)).toString(),
    shippingDescription: "Free",
    shippingAmount: "0",
    taxesAmount: order.totalTax,
    totalAmount: order.totalPrice
  }

  if (isLoading) {
    return (
      <div className="flex justify-center p-30">
        <Loading isLoading={true} />
      </div>
    );
    
  }

  return (
    <div className="w-full flex border-2 justify-between h-full">
      <div className="p-30 w-full">
        <div className="flex items-center mb-8">
          <BackButton
            storeCode={merchStore.storeCode}
            setSelectedOrderDetails={setSelectedOrderDetails}
          />
          <div className="ml-6 flex font-bold text-xl">{order.name}</div>
        </div>
        {(order.id !== ACTIVE_ORDER_ID) && (
          <div className='flex grid-cols-2 mb-8'>
            <CustomerContact
              name={order.customer?.displayName}
              email={order.customer?.email}
            />
            <BillingAddress billingAddress={order.billingAddress} />
          </div>
        )}
        {data && <OrderSummary order={data?.order} />}
      </div>
      <PaymentDetails summary={paymentDetail} />
    </div>
  );
};

export default GiftCardOrderDetail;