


export interface IPriceFilter {
  low: number|string,
  high: number|string
}

export const defaultPriceFilter: IPriceFilter = {
  low: 0,
  high: 5000
}

interface IMakeGetProductsByStoreOptions {
  productFilter?: string;
  brandFilter?: string;
  priceFilter?: IPriceFilter;
  sortChosen?: string;
  cursor?: string | null;
}


export function makeGetProductsByStore(
  storeCode: string, 
  options?: IMakeGetProductsByStoreOptions
): { query: string}
{
    options = options || {};

    const defaultOptions = {
      productFilter: 'All',
      brandFilter: 'All',
      priceFilter: defaultPriceFilter,
      sortChosen: '',
      cursor: null
    }

    const brandFilter = options.brandFilter || defaultOptions.brandFilter;
    const productFilter = options.productFilter || defaultOptions.productFilter;
    const priceFilter = options.priceFilter || defaultOptions.priceFilter;
    const sortChosen = options.sortChosen || defaultOptions.sortChosen;

    let reverseStatus = false;

    let productQueryParams = '';

    productQueryParams += `
      AND variants.price:>=${priceFilter.low} 
      AND variants.price:<=${priceFilter.high}
    `;

    if (productFilter && productFilter !== 'All') {
      productQueryParams += ` AND product_type:${options.productFilter}`
    }
    if (brandFilter && brandFilter !== 'All') {
      productQueryParams += ` AND vendor:${options.brandFilter}`
    }

    if (["sort_name_z-a", "sort_price_high"].includes(sortChosen)) {
        reverseStatus = true
    }

    const productsArgs = {
        first: 150,
        query: productQueryParams,
        sortKey: makeSortKey(options.sortChosen),
        reverse: reverseStatus,
        after: options.cursor ? options.cursor : ''
    };

    const query = `
    {
      products(
        first: ${productsArgs.first}
        ${productsArgs.after ? `after:"${productsArgs.after}"` : ''}
        query: "
              tag:merchstore_code=${storeCode} 
          AND NOT tag:merchstore_deletion_code=${storeCode} 
          ${productsArgs.query}"
        sortKey: ${productsArgs.sortKey || "UPDATED_AT"}
        reverse: ${productsArgs.reverse || "false"}
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        edges {
          cursor
          node {
            title
            id
            handle
            descriptionHtml
            tags
            productType
            vendor
            images(first: 10) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              edges {
                cursor
                node {
                  src: url(
                    transform: {
                      maxWidth: 1024,
                      maxHeight: 1024,
                      preferredContentType: JPG
                    }
                  )
                  id
                  altText
                }
              }
            }
            variants(first: 250) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              edges {
                cursor
                node {
                  id
                  title
                  availableForSale
                  quantityAvailable
                  sku
                  price {
                    amount
                    currencyCode
                  }
                  compareAtPrice {
                    amount
                    currencyCode
                  }
                  selectedOptions {
                    name
                    value
                  }
                }
              }
            }
          }
        }
      }
  }`;

  return {
    query: query
  };
}


function makeSortKey(sortOption?: string): string {
  const sortDefault = "UPDATED_AT";

  if (!sortOption)
  {
    return sortDefault;
  }
  
  const sortKeys = new Map(Object.entries({
      "sort_default": "UPDATED_AT",
      "sort_name_a-z": "TITLE",
      "sort_name_z-a": "TITLE",
      "sort_newest": "CREATED_AT",
      "sort_price_low": "PRICE",
      "sort_price_high": "PRICE",
  }));

  return sortKeys.get(sortOption) || sortDefault;
}


export function makeGetProductVariantById(
  variantId: string
): { query: string, variables: { id: string } } 
{
  const query = `
    query getProductVariantFromNode($id: ID!) {
      node(id: $id) {
        id
        ... on ProductVariant {
          id
          title
        }
      }
    }
  `

  return {
    query: query,
    variables: {
      id: variantId
    }
  };
}