import { useEffect } from "react";
import "./styles.scss";

export interface IHexagonTilesProps {
  primaryColor?: string
}

export const HexagonTiles: React.FC<IHexagonTilesProps> = (props: IHexagonTilesProps) => {
  useEffect(() => {
    screensize();
  });

  function randcolor() {
    const colors = ['#021d49', '#00babc', '#ff671d', '#84dade', '#4a4f54', '#d0d3d4'];
    return colors[Math.floor(Math.random() * colors.length)];
  }

  function draw(numHexes:number, numRand:number, tilt:number) {
    const hexesRoot = document.querySelector<HTMLElement>('.hexes');

    if (hexesRoot) {
      hexesRoot.style.setProperty('--hex-shape-outside', `repeating-linear-gradient(     
        #0000 0 calc(var(--hex-f) - 3px),      
        #000  0 var(--hex-f))`);

      hexesRoot.style.setProperty('--hex-margin-bottom', `calc(var(--hex-m) - var(--hex-s)*0.2885)`);
    }

    const container = document.querySelector('.hex-container');

    // remove old hexagons
    const hexagons = document.querySelectorAll('div.hex');
    hexagons.forEach(hexagon => hexagon.remove());

    // add new hexagons
    for (let i = 1; i < numHexes; i++) {
      const div = document.createElement('div');
      div.classList.add('hex');
      div.style.background = randcolor();
      container?.appendChild(div);
    }

    // make some hexes transparent
    const rand = [];
    for (let i = 0; i <= numRand; i++) {
      rand[i] = Math.floor(Math.random() * numHexes + 1 );
      const divs = document.querySelectorAll<HTMLElement>('div.hex:nth-of-type(' + rand[i] + ')');
      divs.forEach(div => div.style.background = 'transparent');
    }

    // rotate
    const hexes = document.querySelector('.hexes') as HTMLElement;
    if (!hexes) return;
    hexes.style.transform = `rotate(${tilt}deg)`;


  }

  function screensize() {
    const screenWidth = window.innerWidth;
    if (screenWidth > 2001) {
      draw(30, 10, 20);
      return;
    }
    if (screenWidth < 2000 && screenWidth > 951 ) {
      draw(20, 6, 15);
      return;
    }
    if (screenWidth < 950) {
      draw(16, 5, 10);
      return;
    } 
  }

  return (
    <div className="hexwrap">
      <div className="hexes">
        <div className="hex-container">
        </div>
      </div>
    </div>
  );
}