
export enum TrackingNotification {
  NOTIFY_DISABLED = "notify_disabled",
  NOTIFY_GROUP_ADMIN = "notify_group_admin",
  NOTIFY_INDIVIDUALS= "notify_individuals"
}

export const OptionNotifyDisabled = {
  value: TrackingNotification.NOTIFY_DISABLED,
  displayText: "Don't send notifications"
}

export const OptionNotifyStoreAdmin = {
  value: TrackingNotification.NOTIFY_GROUP_ADMIN,
  displayText: "Send to group admin only"
}

export const OptionNotifyIndividuals = {
  value: TrackingNotification.NOTIFY_INDIVIDUALS,
  displayText: "Send to individuals"
}

export function buildTrackingNotificationOptions(): { value: string, displayText: string }[] {
  return [
    OptionNotifyStoreAdmin,
    OptionNotifyIndividuals,
    OptionNotifyDisabled
  ];
}

export function resolveTrackingNotification(trackingValue?: string|null): TrackingNotification
{
  if (trackingValue && (Object.values(TrackingNotification) as string[]).includes(trackingValue))
  {
    return trackingValue as TrackingNotification;
  }
  
  return TrackingNotification.NOTIFY_GROUP_ADMIN;
}