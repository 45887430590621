import { storefrontClient, merchstoreStorefrontClient } from '../App';

export const getCustomerAccessTokenMutation = (email, password) => {
    const credentials = {
        "email": email,
        "password": password
    };
    const input = storefrontClient.variable(credentials, 'CustomerAccessTokenCreateInput!');
    const mutation = storefrontClient.mutation('customerAccessTokenCreate', input, (root) => {
        root.add('customerAccessTokenCreate', { args: { input: credentials } }, (customerAccessTokenCreate) => {
            customerAccessTokenCreate.add('customerAccessToken', (customerAccessToken) => {
                customerAccessToken.add('accessToken');
                customerAccessToken.add('expiresAt');
            });
        });
    });
    return mutation;
}

export const getCustomerQuery = (token) => {
    const query = storefrontClient.query((root) => {
        root.add('customer', { args: { customerAccessToken: token } }, (customer) => {
            customer.add('id');
            customer.add('email');
            customer.add('defaultAddress', (defaultAddress) => {
                defaultAddress.add('address1');
                defaultAddress.add('address2');
                defaultAddress.add('city');
                defaultAddress.add('company');
                defaultAddress.add('country');
                defaultAddress.add('firstName');
                defaultAddress.add('lastName');
                defaultAddress.add('phone');
                defaultAddress.add('province');
                defaultAddress.add('zip');
            });
            customer.add('tags');
        });
    });
    return query;
}

export const getMerchstoreProductsByTag = (productTag, sortKey = "sort_name_a-z") => {
    const query = merchstoreStorefrontClient.query((root) => {
        root.addConnection('products', { 
            args: { 
                first: 250, 
                query: `tag:${productTag}`, 
                sortKey: makeSortKey(sortKey) 
            }
        }, (product) => {
            product.add('title');
            product.add('id');
            product.add('description');
            product.add('descriptionHtml');
            product.add('productType');
            product.add('vendor');
            product.add('createdAt');
            product.add('handle');
            product.addConnection('metafields', { args: { first: 100 } }, (metafield) => {
                metafield.add('key');
                metafield.add('value');
            });
            product.add('tags');
            product.addConnection('images', { args: { first: 10 } }, (images) => {
                images.add('src');
                images.add('id');
                images.add('altText');
            });
            product.addConnection('variants', { args: { first: 250 } }, (variants) => {
                variants.add('id');
                variants.add('product');
                variants.add('title');
                variants.add('price');
                variants.add('available');
                variants.add('sku');
                variants.add('availableForSale');
                variants.add('quantityAvailable');
                variants.add('image', (image) => {
                    image.add('src');
                    image.add('id');
                    image.add('altText');
                });
                variants.add('compareAtPriceV2', (price) => {
                    price.add('amount')
                    price.add('currencyCode')
                });
                variants.add('selectedOptions', (selectedOptions) => {
                    selectedOptions.add('name');
                    selectedOptions.add('value');
                });

            });
        });
    });
    return query;
};

export const createCustomerMutation = (email, password, firstName = '', lastName = '') => {
    const credentials = {
        "email": email,
        "password": password,
        "firstName": firstName,
        "lastName": lastName
    };
    const input = storefrontClient.variable(credentials, 'CustomerCreateInput!');
    const mutation = storefrontClient.mutation('customerCreate', input, (root) => {
        root.add('customerCreate', { args: { input: credentials } }, (customerCreate) => {
            customerCreate.add('customer', (customer) => {
                customer.add('id');
            });
            customerCreate.add('customerUserErrors', (customerUserErrors) => {
                customerUserErrors.add('code');
                customerUserErrors.add('field');
                customerUserErrors.add('message');
            });
        });
    });
    return mutation;
}

export const recoverPasswordMutation = (email) => {
    const input = storefrontClient.variable(email, 'String!');
    const mutation = storefrontClient.mutation('customerRecover', input, (root) => {
        root.add('customerRecover', { args: { email: email } }, (customerRecover) => {
            customerRecover.add('customerUserErrors', (customerUserErrors) => {
                customerUserErrors.add('code');
                customerUserErrors.add('field');
                customerUserErrors.add('message');
            });
        });
    });
    return mutation;
}

export const getCustomerAccessTokenMultipassMutation = (multipassToken) => {
    const params = {
        "multipassToken": multipassToken
    };
    const input = storefrontClient.variable('multipassToken', 'String!');
    const mutation = storefrontClient.mutation('customerAccessTokenCreateWithMultipass', input, (root) => {
        root.add(
            'customerAccessTokenCreateWithMultipass', { args: params }, (customerAccessTokenCreateWithMultipass) => {
            customerAccessTokenCreateWithMultipass.add('customerAccessToken', (customerAccessToken) => {
                customerAccessToken.add('accessToken');
                customerAccessToken.add('expiresAt');
            });
            customerAccessTokenCreateWithMultipass.add('customerUserErrors', (customerUserErrors) => {
                customerUserErrors.add('code');
                customerUserErrors.add('field');
                customerUserErrors.add('message');
            });
        });
    });
    return mutation;
}


export const getProductVariantByIdQuery = (id) => {
    const query = storefrontClient.query((root) => {
        root.add('node', { args: { id } }, (node) => {
            node.addInlineFragmentOn('ProductVariant', (productVariant) => {
                productVariant.add('id');
                productVariant.add('title');
            });
        });
    });

    return query;
}


export const getCheckoutCreateMutation = (checkoutFields) => {
    checkoutFields.presentmentCurrencyCode = storefrontClient.enum(process.env.REACT_APP_CURRENCY || "USD");
    const mutation = storefrontClient.mutation((root) => {
        root.add('checkoutCreate', { args: { "input": checkoutFields } }, (checkoutCreate) => {
            checkoutCreate.add('checkout', (checkout) => {
                checkout.add('id');
                checkout.add('webUrl');
            });
            checkoutCreate.add('checkoutUserErrors', (checkoutUserErrors) => {
                checkoutUserErrors.add('code');
                checkoutUserErrors.add('field');
                checkoutUserErrors.add('message');
            });
        });
    });
    return mutation;
};

export const customerAddressCreateMutation = (customerAccessToken, address) => {
    const mutation = storefrontClient.mutation((root) => {
        root.add('customerAddressCreate', { args: { customerAccessToken, address } }, (customerAddressCreate) => {
            customerAddressCreate.add('customerAddress', (customerAddress) => {
                customerAddress.add("id");
            });
            customerAddressCreate.add('customerUserErrors', (customerUserErrors) => {
                customerUserErrors.add('code');
                customerUserErrors.add('field');
                customerUserErrors.add('message');
            });
        });        
    });

    return mutation;
}

export const customerAddressUpdateMutation = (customerAccessToken, id, address) => {
    const mutation = storefrontClient.mutation((root) => {
        root.add('customerAddressUpdate', { args: { customerAccessToken, id, address } }, (customerAddressUpdate) => {
            customerAddressUpdate.add('customerAddress', (customerAddress) => {
                customerAddress.add("id");
            });
            customerAddressUpdate.add('customerUserErrors', (customerUserErrors) => {
                customerUserErrors.add('code');
                customerUserErrors.add('field');
                customerUserErrors.add('message');
            });
        });        
    });

    return mutation;
}

// Used in getMerchstoreProductsByTag
function makeSortKey(sortOption) {
    const sortKeys = new Map(Object.entries({
        "sort_default": storefrontClient.enum("UPDATED_AT"),
        "sort_name_a-z": storefrontClient.enum("TITLE"),
        "sort_name_z-a": storefrontClient.enum("TITLE"),
        "sort_newest": storefrontClient.enum("CREATED_AT"),
        "sort_price_low": storefrontClient.enum("PRICE"),
        "sort_price_high": storefrontClient.enum("PRICE"),
    }));

    let sortKey = sortKeys.has(sortOption) ? sortKeys.get(sortOption) : sortKeys.get("default");

    return sortKey
}