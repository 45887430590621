export class Role
{
    roleCode: string;

    constructor(roleCode: string) {
        this.roleCode = roleCode;
    }

    toString() : string {
        return this.roleCode;
    }
}

export const ROLE_MERCHOLOGIST = new Role("MERCHOLOGIST");

export const ROLE_SUPERUSER = new Role("SUPERUSER");

export const ROLE_GROUPADMIN = new Role("ADMIN");


export function userHasRole(userRole: Role, roles: Role[]): boolean
{
    return roles.map(String).includes(String(userRole));
}