import { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import {
  MdArrowDropDown,
  MdArrowDropUp,
  MdDelete,
  MdEdit,
  MdHelpOutline,
} from 'react-icons/md';
import { Table } from '@merchstores/shared/elements/Table';
import { CTA } from '@merchstores/shared/elements/Cta';
import { Tooltip } from '@merchstores/shared/elements/Tooltip';
import { IMerchStoreProduct, IMerchStoreProductListProps } from '.';
import { cloudinaryApplyWidthHeight } from '@merchstores/shared/components/Cloudinary';
import { Loading } from '@merchstores/shared/components/Loading';
import { MerchStoreArtworkOptions } from '../MerchStoreArtworkOptions';
import { Context } from '@merchstores/admin/context';
import { userHasRole, ROLE_GROUPADMIN } from '@merchstores/admin/context/Roles';

import './styles.scss';

const SortInput: React.FC<any> = (props: any) => {
  const { value, onSubmit } = props;
  const [text, setText] = useState(value);
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit(text);
      }}
      className="flex items-center justify-center text-center"
    >
      <input
        type="text"
        value={text}
        onChange={(text) => setText(text.target.value)}
        onBlur={() => onSubmit(text)}
        className="w-50 h-10 font-bold text-base text-center outline-none mb-3"
        maxLength={2}
      />
    </form>
  );
};

export const MerchStoreProductList: React.FC<IMerchStoreProductListProps> = (
  props: IMerchStoreProductListProps
) => {
  const { products, setSelectedProduct, isMobile, deleteProduct, order } =
    props;
  const [collectionData, setCollectionData] = useState([]);
  const [productsOrder, setProductsOrder] = useState(order.productsOrder || []);
  const [highlight, setHighlight] = useState([]);
  const [productsAdding, setProductsAdding] = useState(false);

  const { userRole } = useContext(Context);

  const isStoreAdmin = userHasRole(userRole, [ROLE_GROUPADMIN]);

  useEffect(() => {
    const newProducts = getSelectedProducts();
    if (newProducts && products) {
      const productsMissing = newProducts.filter(
        (product: IMerchStoreProduct) =>
          !products.some((p) => p.title === product.title)
      );
      if (productsMissing.length) {
        setProductsAdding(true);
      } else {
        setProductsAdding(false);
      }
    }
  }, [products]);

  useEffect(() => {
    if (products?.length !== productsOrder?.length && products?.length) {
      const productIds = products.map(
        (product: IMerchStoreProduct) => product.id
      );
      const sorted = productIds.filter((product) =>
        productsOrder.includes(product)
      );
      const unsorted = productIds.filter(
        (product) => !productsOrder.includes(product)
      );
      const newSort = [...sorted, ...unsorted];
      updateProductSort(newSort);
    }
  }, [props]);

  useEffect(() => {
    if (!products) return;

    let sortedProducts = products;
    if (productsOrder?.length) {
      const desiredOrder = productsOrder;
      const productsToSort = products.filter((product: IMerchStoreProduct) =>
        desiredOrder.includes(product.id)
      );
      const productsNoSort = products.filter(
        (product: IMerchStoreProduct) => !desiredOrder.includes(product.id)
      );
      productsToSort.sort(
        (a: any, b: any) =>
          desiredOrder.indexOf(a.id) - desiredOrder.indexOf(b.id)
      );
      sortedProducts = [...productsToSort, ...productsNoSort];
    }

    const collectionProductData = sortedProducts.map(
      (product: IMerchStoreProduct) => {
        const data: any = {};
        const productImage = product.images[0];
        const imgSrc = productImage ? productImage.src : '';
        const formattedImage =
          imgSrc.indexOf('shopify') > -1
            ? imgSrc /*shopifyImageResize(imgSrc, 'thumb')*/
            : imgSrc.indexOf('cloudinary') > -1
            ? cloudinaryApplyWidthHeight(imgSrc, 36, 36)
            : imgSrc;
        data.image = {
          desktopOnly: false,
          value: (
            <div className="flex items-center ml-4">
              {product.images.length ? (
                <div className="h-36 w36 bg-white border border-gray-300 rounded-md overflow-hidden">
                  <img
                    src={formattedImage}
                    alt={productImage ? productImage.altText : ''}
                  />
                </div>
              ) : (
                <></>
              )}
              <div className="text-left pl-3 font-bold text-merch-dark-gray w-200 md:w-auto">
                {product.title}
              </div>
            </div>
          ),
        };

        data.resort = {
          desktopOnly: false,
          text: '',
          value: (
            <div
              className={`
              flex 
              items-center 
              rounded-md 
              overflow-hidden 
              bg-white
              border 
              border-gray-300
              mr-2 
              ml-2 
              ${highlight.includes(product.id) ? 'border-merch-orange' : ''}
            `}
            >
              <div className="flex flex-col">
                <div
                  className="
                    flex 
                    items-center 
                    cursor-pointer 
                    hover:bg-gray-300
                    rounded-md
                  "
                  onClick={() => incrementProductSort(product.id)}
                >
                  <MdArrowDropUp />
                </div>
                <div
                  className="
                    flex 
                    items-center 
                    cursor-pointer 
                    hover:bg-gray-300
                    rounded-md
                  "
                  onClick={() => decrementProductSort(product.id)}
                >
                  <MdArrowDropDown />
                </div>
              </div>
              <div className="flex items-center text-left font-bold text-merch-dark-gray">
                <SortInput
                  value={productsOrder.indexOf(product.id) + 1 || '-'}
                  onSubmit={(newIndex: any) =>
                    setNewPosition(newIndex, product.id)
                  }
                />
              </div>
            </div>
          ),
        };

        data.editAction = {
          desktopOnly: false,
          value: product.productType ? (
            <div className="flex w-full justify-end">
              <CTA
                size="small"
                type="mobile-icon"
                onClick={() => {
                  setSelectedProduct(product);
                }}
              >
                {isMobile ? <MdEdit size="15px" /> : 'View/Edit'}
              </CTA>
            </div>
          ) : (
            ''
          ),
        };

        data.deleteAction = {
          desktopOnly: false,
          value: product.productType ? (
            <CTA
              size="small"
              type="clear-background"
              onClick={() => {
                deleteProduct(product.id);
              }}
            >
              <MdDelete />
            </CTA>
          ) : (
            ''
          ),
        };
        return {
          tr: {
            class: '',
            data,
          },
        };
      }
    );
    setCollectionData(collectionProductData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props, productsOrder, highlight]);

  const incrementProductSort = async (id: string) => {
    const arr = [...productsOrder];
    const currentIndex = arr.indexOf(id);
    if (currentIndex === 0) return;
    if (currentIndex !== -1) {
      const item = arr.splice(currentIndex, 1)[0];
      arr.splice(currentIndex - 1, 0, item);
    } else {
      arr.push(id);
    }
    await updateProductSort(arr)
      .then(() => {
        setHighlight([...highlight, id]);
        setTimeout(() => {
          setHighlight([]);
        }, 1000);
      })
      .catch((err) => {
        throw err;
      });
  };

  const decrementProductSort = async (id: string) => {
    const arr = [...productsOrder];
    const currentIndex = arr.indexOf(id);
    if (currentIndex !== -1) {
      const item = arr.splice(currentIndex, 1)[0];
      arr.splice(currentIndex + 1, 0, item);
    } else {
      arr.push(id);
    }
    await updateProductSort(arr)
      .then(() => {
        setHighlight([...highlight, id]);
        setTimeout(() => {
          setHighlight([]);
        }, 1000);
      })
      .catch((err) => {
        throw err;
      });
  };

  const setNewPosition = async (newIndex: any, id: string) => {
    newIndex = parseInt(newIndex);
    if (typeof newIndex !== 'number') return;
    const arr = [...productsOrder];
    const currentIndex = arr.indexOf(id);
    if (currentIndex !== -1) {
      const item = arr.splice(currentIndex, 1)[0];
      arr.splice(newIndex - 1, 0, item);
    } else {
      arr.push(id);
    }
    await updateProductSort(arr)
      .then(() => {
        setHighlight([...highlight, id]);
        setTimeout(() => {
          setHighlight([]);
        }, 1000);
      })
      .catch((err) => {
        throw err;
      });
  };

  const updateProductSort = (newProductsOrder: Array<string>) => {
    return axios
      .post('/.netlify/functions/updateMerchStore', {
        mutationType: 'updateMerchStoreProductsOrder',
        newProductsOrder: newProductsOrder,
        storeCode: order.storeCode,
      })
      .then((merchStoreResp) => {
        setProductsOrder(newProductsOrder);
        return merchStoreResp;
      })
      .catch((err) => {
        throw err;
      });
  };

  const sectionHeader = (
    text: string,
    tooltip?: string | HTMLElement | JSX.Element
  ) => {
    return (
      <div className="flex items-center mb-2.5 pl-6 md:pl-0">
        <div className="text-sm text-merch-dark-gray font-bold">{text}</div>
        {tooltip && (
          <Tooltip content={tooltip}>
            <div className="opacity-50 ml-2">
              <MdHelpOutline />
            </div>
          </Tooltip>
        )}
      </div>
    );
  };
  if (props.isLoading) return <Loading isLoading={true} />;

  function getSelectedProducts() {
    const currentSelectionString = localStorage.getItem(
      `productsAdded${order.storeCode}`
    );
    if (currentSelectionString) {
      return JSON.parse(currentSelectionString);
    } else {
      return [];
    }
  }

  return (
    <>
      {isStoreAdmin
        ? sectionHeader(
            'Artwork Options',
            'Contact a Merchologist for additional artwork options'
          )
        : sectionHeader('Artwork Options')}
      <MerchStoreArtworkOptions
        merchStore={props.order}
        setMerchStoreDetails={props.setOrderDetails}
      />
      {productsAdding && (
        <div className="flex flex-row w-full bg-yellow-200 text-yellow-700 rounded-md items-center justify-center p-2 my-2">
          Products are being added to your store. Some products may take a few
          minutes to appear.
        </div>
      )}
      <div className="flex flex-row w-4/5 justify-between">
        {sectionHeader('Products')}
        {sectionHeader('Sort')}
      </div>
      <div className="product-list-container">
        {collectionData.length ? (
          <Table
            itemsName="products"
            name="products"
            data={collectionData}
            usePagination={false}
          />
        ) : (
          <div className="bg-merch-background-gray px-3 py-6 lg:p-6 rounded-md flex justify-between items-center">
            <div className="text-13 italic text-merch-dark-gray opacity-50 font-medium">
              Showing 0 of 0 products
            </div>
          </div>
        )}
      </div>
    </>
  );
};
