
const HasMetafields = {
  "name": "HasMetafields",
  "kind": "INTERFACE",
  "fieldBaseTypes": {
    "metafield": "Metafield",
    "metafields": "MetafieldConnection"
  },
  "possibleTypes": ["Product", "ProductVariant"]
};
export default HasMetafields;