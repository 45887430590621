import { resolveShopifySubdomain } from "@merchstores/admin/lib/MerchServicesShopify/ShopifyDomainUrl";

export const SHOPIFY_SUBDOMAIN = resolveShopifySubdomain();

const MERCH_SERVICES_SHOPIFY_SUBDOMAIN_STAGING = "merch-services-staging.myshopify.com";
const MERCH_SERVICES_SHOPIFY_SUBDOMAIN_PRODUCTION = "merch-services.myshopify.com";

let shopifyMemberSubmissionVariantGid = "";

if (SHOPIFY_SUBDOMAIN === MERCH_SERVICES_SHOPIFY_SUBDOMAIN_STAGING) {
    shopifyMemberSubmissionVariantGid = "gid://shopify/ProductVariant/44598857105658";
}

if (SHOPIFY_SUBDOMAIN === MERCH_SERVICES_SHOPIFY_SUBDOMAIN_PRODUCTION) {
    shopifyMemberSubmissionVariantGid = "gid://shopify/ProductVariant/42382887616709";
}


export const SHOPIFY_MEMBER_SUBMISSION_VARIANT_GID = shopifyMemberSubmissionVariantGid;

