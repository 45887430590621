import Bluebird from "bluebird";
import axios, {AxiosResponse} from "axios";
import { History } from "history";
import { toast } from "react-toastify";
import { IOrderDetailsView } from "@merchstores/admin/types/merchstoreOrder";
import { ACTIVE_ORDER_ID } from "@merchstores/admin/components/MerchStoreOrders";
import { ICombinedOrder } from "@merchstores/admin/types/shopifyCombinedOrder";

const POLLING_TIMEOUT = 5000; // 5 seconds
const MAX_ATTEMPTS = 40; // The retry count is to prevent infinite polling

export function resolveDropdownOptions(
  storeCode: string,
  order: ICombinedOrder,
  setSelectedOrderDetails: (orderDetailsView: IOrderDetailsView) => void,
  history: History
): {
  title: string;
  id: string;
  action: () => void;
}[] {
  const resolvedDropdownOptions = [
    {
      title: "View/Edit",
      id: `${order.id}-view`,
      action: () => {
        setSelectedOrderDetails({
          viewType: "combined_order",
          combinedOrder: order,
          individualOrder: null,
        });
        if (order.id === ACTIVE_ORDER_ID) {
          history.push(`/admin/merchstores/${storeCode}/orders/active`);
        } else {
          history.push(
            `/admin/merchstores/${storeCode}/orders/${order.name.replace(
              "#",
              ""
            )}`
          );
        }
      },
    },
  ];

  resolvedDropdownOptions.push({
    title: "Download CSV",
    id: `${order.id}-download-csv`,
    action: () => {
      console.log(
        `Waiting for ${
          (POLLING_TIMEOUT * MAX_ATTEMPTS) / 1000
        } seconds before timeout`
      );
      toast.success(`Downloading order history CSV...`, {
        autoClose: POLLING_TIMEOUT * MAX_ATTEMPTS,
      });
      const timestamp = Date.now();

      // Generate the CSV file on background
      axios
        .get(
          // eslint-disable-next-line max-len
          `/.netlify/functions/generateOrderHistoryCsv-background?storeCode=${storeCode}&combinedOrderId=${order.id}&timestamp=${timestamp}`
        )
        .catch((error) => {
          console.error("generateOrderHistoryCsv-background error:", error);
        });

      // Start polling for the CSV file
      const exportId = `${storeCode}-${timestamp}`;
      const orderNumber = order.name.replace("#", "");
      startPollingForCsvDownload(exportId, orderNumber).then(
        () => {
          toast.dismiss();
          // The file is ready to download, redirect to the download link
          window.open(
            `/.netlify/functions/downloadOrderHistoryCsv?exportId=${exportId}&orderNumber=${orderNumber}`
          );
        },
        (error) => {
          toast.dismiss();
          toast.error(
            "There was an error downloading the Order history CSV. Please try again."
          );
          console.error(error);
        }
      );
    },
  });

  return resolvedDropdownOptions;
}

const startPollingForCsvDownload = async (
  exportId: string,
  orderNumber: string
) => {
  console.log("Start polling for CSV download...");
  let attempts = 0;

  const executePoll = async (): Promise<AxiosResponse> => {
    const result = await axios.get(
      `/.netlify/functions/downloadOrderHistoryCsv?exportId=${exportId}&orderNumber=${orderNumber}`
    );
    attempts++;

    console.log(`Polling... ${attempts}. Status: ${result.status}`);
    if (result.status == 200) {
      console.log("Export is ready.")
      return result;
    } else if (result.status == 202) {
      // File is not ready to download yet, continue polling
      if (attempts === MAX_ATTEMPTS) {
        throw new Error("Exceeded max attempts");
      }
      
    } else if (result.status >= 400 && result.status < 599) {
      throw new Error(
        `Error checking CSV exportId '${exportId}' status: ${result.status} data: ${result.data}`
      );
    }

    return Bluebird.delay(POLLING_TIMEOUT).then(executePoll);
  };

  return executePoll();
};

export default {
  resolveDropdownOptions,
};
