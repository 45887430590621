
const MoneyV2Edge = {
  "name": "MoneyV2Edge",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "cursor": "String",
    "node": "MoneyV2"
  },
  "implementsNode": false
};
export default MoneyV2Edge;