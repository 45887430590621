/* eslint-disable max-len */
import { useState } from "react";
import { useEffect } from "react";
import { useForm, useWatch } from "react-hook-form";
import Modal from "react-responsive-modal";
import { ICreateMerchstoreProps, INewMerchstoreProps } from ".";
import { Card } from "@merchstores/shared/elements/Card/component";
import { CTA } from "@merchstores/shared/elements/Cta";
import { FormInput } from "@merchstores/shared/elements/FormInput";
import { FormSelect } from "@merchstores/shared/elements/FormSelect";
import "./styles.scss";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { toast } from 'react-toastify';
import { useContext } from "react";
import { Context } from "../../context";
import { userHasRole, ROLE_GROUPADMIN, ROLE_MERCHOLOGIST, ROLE_SUPERUSER } from "@merchstores/admin/context/Roles";
import { newMerchStorePaymentOptions } from "../CreateEditMerchStore/MerchstorePaymentOptions";
import { newMerchStoreTypeOptions } from "../CreateEditMerchStore/MerchstoreStoreTypeOptions";

export const CreateMerchstore = (props: ICreateMerchstoreProps): JSX.Element => {
    const { register, handleSubmit, setValue, setError, formState: { errors }, control } = useForm();
    const history = useHistory();

    const [modalOpen, setModalOpen] = useState(props.modalOpen);
    const [merchStorePaymentOptions] = useState(newMerchStorePaymentOptions());
    const [merchStoreTypeOptions] = useState(newMerchStoreTypeOptions());

    const [submitDisabled, setSubmitDisabled] = useState(true);
    const { userEmail, userRole } = useContext(Context);

    const isStoreAdmin = userHasRole(userRole, [ROLE_GROUPADMIN]);
    const hasMerchologyPermissions = userHasRole(userRole, [ROLE_MERCHOLOGIST, ROLE_SUPERUSER])

    const subdomain = useWatch({
        control,
        name: "subdomain",
        defaultValue: ""
    });

    const payerType = useWatch({
        control,
        name: "payerType",
        defaultValue: null
    });

    const alwaysOpen = useWatch({
        control,
        name: "alwaysOpen",
        defaultValue: ""
    });

    useEffect(() => {
        if (subdomain && subdomain !== "" && payerType && alwaysOpen) {
            setSubmitDisabled(false);
        } else {
            setSubmitDisabled(true);
        }
    }, [subdomain, payerType, alwaysOpen]);

    useEffect(() => {
        if (props.modalOpen !== modalOpen) {
            setModalOpen(props.modalOpen);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.modalOpen]);

    const createMerchstore = async (form: INewMerchstoreProps) => {
        setSubmitDisabled(true);

        // Check Subdomain format
        const re = /[^a-zA-Z0-9-]/;
        if (re.test(subdomain)) {
            setError(
                "subdomain",
                { type: "invalid", message: "Valid subdomains can only contain letters, numbers, or the character -" }
            );
        } else {
            toast.info(`Checking availability of ${subdomain}.merchologysolutions.com...`);
            const subdomainCheck = await axios.post(
                '/.netlify/functions/fetchMerchstoreBySubdomain',
                { subdomain: form.subdomain }
            );
            if (!subdomainCheck.data._id) {
                toast.info(`Registering ${subdomain}.merchologysolutions.com, please wait...`);
                await axios.post('/.netlify/functions/createNetlifySubdomain', {
                    subdomain: form.subdomain
                })
                    .then(async (netlifyReponse: any) => {
                        const siteId = netlifyReponse.data.siteId || "";
                        toast.success(`${subdomain}.merchologysolutions.com registered correctly!`);
                        toast.info(`Setting up your store, please wait...`);
                        await axios.post('/.netlify/functions/createMerchstore', {
                            store: {
                                subdomain: form.subdomain,
                                payerType: form.payerType,
                                alwaysOpen: form.alwaysOpen,
                                merchologist: hasMerchologyPermissions ? userEmail : "",
                                netlifySiteId: siteId,
                                customerAdmins: isStoreAdmin ? [userEmail] : []
                            }
                        })
                            .then(res => {
                                toast.success(`Your store ${subdomain} was created correctly!`);
                                history.push(`/admin/merchstores/${res.data}`);

                            }).catch(async (err) => {
                                console.error(err);
                                await axios.post('/.netlify/functions/deleteNetlifySite', {
                                    siteId
                                })
                                    .then(() => {
                                        toast.error(`There was an error creating your store. Please try again.`);
                                        setSubmitDisabled(false);
                                    })
                                    .catch(err => {
                                        console.error(err);
                                        toast.error(
                                            `There was an error creating your store. Please contact your administrator.`
                                        );
                                    });
                            })
                    }).catch(err => {
                        if (err?.response?.data?.error) {
                            setError("subdomain", { type: "in-use", message: err.response.data.error });
                        }
                        setSubmitDisabled(false);
                    });
            } else {
                setError(
                    "subdomain",
                    { type: "in-use", message: "That domain has already been registered, please try another." }
                );
                setSubmitDisabled(false);
            }
        }
    }

    return <Modal open={modalOpen} classNames={{ root: "create-edit-merchstore-modal" }} center={true} onClose={() => {
        setModalOpen(false);
        props.onModalClose();
    }}>
        <Card>
            <div className="overflow-y-auto h-full">
                <div className="flex flex-col h-full justify-evenly">
                    <h2 className="font-bold sm:pb-15">Create New MerchStore</h2>
                    <p className="sm:pb-15">
                        These settings cannot be changed once you’ve created your store. If you have any further questions please contact
                        <a
                            className="link-2"
                            href="mailto:sales@merchology.com"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            customer support
                        </a>
                        for additional help.
                    </p>
                    <form onSubmit={handleSubmit(createMerchstore)} className="">
                        <div className="flex justify-between flex-col">
                            <div>
                                <h3 className="font-medium mb-2">What is the subdomain of your new MerchStore?</h3>
                                <FormInput
                                    errors={errors}
                                    name="subdomain"
                                    register={register}
                                    required={true}
                                    type="text"
                                    htmlSubText={<p className="pb-15"><b>{subdomain}</b>.merchologysolutions.com</p>} />
                            </div>
                            <div>
                                <FormSelect
                                    name="payerType"
                                    errors={errors}
                                    register={register}
                                    label="Who will be paying for MerchStore products?"
                                    classes="h3-label-type sm:mt-1"
                                    options={merchStorePaymentOptions}
                                    setValue={setValue}
                                    emptyText="Select one" />
                            </div>
                            <div>
                                <FormSelect
                                    label="Will this MerchStore be always open or only open for a limited time?"
                                    classes="h3-label-type sm:mt-1"
                                    name="alwaysOpen"
                                    errors={errors}
                                    register={register}
                                    options={merchStoreTypeOptions}
                                    setValue={setValue}
                                    emptyText="Select one"
                                    tooltip={<>
                                        <p>
                                            <b>Limited Time:</b>
                                            Store is only open for a limited time. The store admin will select the date that the store should close, and then the combined order will be submitted.
                                        </p>
                                        <p className="mt-3">
                                            <b>Always Open:</b> The store remains open (it does not have a close date) and orders that are submitted by members are processed on a timeline / schedule that the store admin selects.
                                        </p>
                                    </>}
                                    tooltipPosition="left"
                                />

                            </div>
                        </div>
                        <CTA
                            type="primary"
                            size="large"
                            formSubmit={true}
                            classes="w-full"
                            disabled={submitDisabled}
                        >
                            Create Store
                        </CTA>
                        <CTA type="secondary-gray" size="large" classes="w-full mt-3" onClick={() => {
                            setModalOpen(false);
                            props.onModalClose();
                        }} >Cancel</CTA>
                        <div className="flex justify-center pt-24">
                            <a
                                className="link-3"
                                href="https://www.merchology.com/pages/merchstore"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Frequently Asked Questions
                            </a>
                        </div>
                    </form>
                </div>
            </div>
        </Card>
    </Modal>
}