import { Card } from "@merchstores/shared/elements/Card";
import { CTA } from "@merchstores/shared/elements/Cta";
import "./styles.scss";
import { MdThumbUp } from "react-icons/md";
import { Header } from "../../Header";
import { Footer } from "../../Footer";
import { useState } from "react";
import { useEffect } from "react";
import { ISubmissionThankYouProps } from ".";
import { useLocation } from "react-router-dom";

export const SubmissionThankYouPage = (props: ISubmissionThankYouProps) => {

    const [groupOrder, setGroupOrder] = useState(null);

    const location = useLocation();

    useEffect(() => {
        if (location.state) {
            if (location.state.groupOrder) {
                setGroupOrder(location.state.groupOrder);
            }
        }
    }, [location]);

    useEffect(() => {
        if (props.groupOrder) {
            setGroupOrder(props.groupOrder);
        }
    }, [props.groupOrder]);

    const goToMerchology = () => {
        window.location.assign("https://www.merchology.com/");
    }

    return <div className="flex h-full justify-between items-center flex-col group-member-page-container">
        <Header storeLogo={groupOrder?.storeLogo ? groupOrder.storeLogo : ""} />
        <div className="relative w-full h-full flex items-center justify-center h-full">
            <div className=" submission-thank-you-container">
                <Card>
                    <div className="flex flex-col items-center h-full w-full p-30">
                        <div className="thumb-container flex justify-center items-center mb-30"><MdThumbUp size="33px" /></div>
                        <h3 className="text-center font-bold mb-8px">Thank you, your group order details have been received!</h3>
                        <p className="text-center mb-24" >A confirmation email with additional details has been sent.</p>
                        <CTA size="large" type="primary" classes="w-full" onClick={goToMerchology}>Visit Merchology</CTA>
                    </div>
                </Card>
                <p 
                    className="bottom-note text-center mt-15 pl-30 pr-30"
                >
                    Note: If you’ve already submitted your details, the most recent submission will be used to complete your order.
                </p>
            </div>
        </div>
        <Footer />
    </div>
}