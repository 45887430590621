
const MediaConnection = {
  "name": "MediaConnection",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "edges": "MediaEdge",
    "pageInfo": "PageInfo"
  },
  "implementsNode": false
};
export default MediaConnection;