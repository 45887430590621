import { Modal } from "react-responsive-modal";

import { CTA } from "../Cta";

import { IConfirmationModalProps } from ".";
import "./styles.scss";
import { MdClear } from "react-icons/md";

export const ConfirmationModal = (props: IConfirmationModalProps) => {
  const { open, onClose, image, headlineText, bodyText, onConfirmation, onCancellation, closeIcon } = props;
  return (
    <Modal 
      open={open} 
      onClose={onClose} 
      center 
      classNames={{ modal: "confirmation-modal shadow-confirmation-modal rounded-3xl" }} 
      closeIcon={closeIcon ? closeIcon : <MdClear />}
    >
      <div className="rounded-3xl flex flex-col p-30">
        <div className="flex justify-center mb-30">
          <img className="max-h-16 w-auto" src={image}></img>
        </div>
        <p className="text-center text-lg font-bold mb-2">{headlineText}</p>
        <p className="text-center text-sm mb-6">{bodyText}</p>
        <CTA
          size="standard"
          type="primary"
          onClick={onConfirmation}
        >
          Confirm
        </CTA>
        <CTA
          size="standard"
          type="clear-background"
          onClick={onCancellation}
        >
          Cancel
        </CTA>
      </div>
    </Modal>
  )
}