

import { useEffect } from 'react';
import { useState } from 'react';
import { IHeaderProps } from './index';
import "./styles.scss";

export const Header = (props: IHeaderProps) => { // Define the actual props needed in the index file

    const [loading, setLoading] = useState(true);
    const [storeLogo, setStoreLogo] = useState(null);

    useEffect(() => {
        if (props.storeLogo) {
            setStoreLogo(props.storeLogo);
            setLoading(false);
        }
    }, [props.storeLogo]);

    return (
        <header className="storefront flex items-center justify-center">
            {loading ? <></> : <img src={storeLogo} alt="Store logo" />}
        </header>
    )
}