import axios from "axios";
import { useEffect, useState } from "react";
import { MdArrowBack } from "react-icons/md";
import { toast } from "react-toastify";
import { fetchMerchStoreOrder } from "@merchstores/admin/components/MerchStoreOrders";
import { OrderStatus } from "@merchstores/admin/types/merchstoreOrder";
import { IndividualOrderDetailsList } from "./OrderIndividualDetailsList"
import { OrderDetailsSummary } from "./OrderDetailsSummary";
import { CTA } from "@merchstores/shared/elements/Cta";
import {
  IOrderIndividualDetailsPageProps,
  IVariantQuantityDataEntry
} from "@merchstores/admin/types/shopifyIndividualOrder";
import "./styles.scss";

import { Loading } from "@merchstores/shared/components/Loading";


export const OrderIndividualDetailsPage: React.FC<IOrderIndividualDetailsPageProps> = (
  props: IOrderIndividualDetailsPageProps
) => {
  const [loading, setLoading] = useState(true);

  const combinedOrder = props.combinedOrder;
  const merchStore = props.merchStore;
  const order = props.order;
  const setSelectedOrderDetails = props.setSelectedOrderDetails;
  const userRole = props.userRole;
  const shipToOffice = merchStore.shipToOffice;
  const officeAddress = merchStore.officeAddress;

  const pendingProcessingTag = order.tags.find(tag => tag === "close_date=pending");
  const closeDateTag = order.tags.find(tag => /close_date=\d{4}-\d{2}-\d{2}/i.test(tag));
  
  let closeDate = closeDateTag ? closeDateTag.replace('close_date=', '') : '';
  
  if (!closeDate && pendingProcessingTag && merchStore.nextProcessingDate)
  {
    closeDate = merchStore.nextProcessingDate;
  }

  const [orderItems, setOrderItems] = useState([]);
  const [orderItemsFulfillment, setOrderItemsFulfillment] = useState([]);
  
  const [variantQuantityData, setVariantQuantityData] = useState([]);
  const [variantQuantityEditActive, setVariantQuantityEditActive] = useState(false);

  const [canEditOrder, setCanEditOrder] = useState(false)
  const [individualOrder, setIndividualOrder] = useState(undefined)
  const [orderTrackingCode, setOrderTrackingCode] = useState('');

  useEffect(() => {
    fetchMerchStoreOrder({ storeCode: merchStore.storeCode, orderId: order.id }).then(data => {
      setIndividualOrder(data.shopifyOrder);
      setOrderTrackingCode(data.tracking?.trackingCode ?? '');
      const orderItemData = data.shopifyOrder?.lineItems ?? [];

      setOrderItems(orderItemData);
      setOrderItemsFulfillment(data.fulfillment);

      const orderVariantData = orderItemData?.map((item) => {
        return { 
          id: item.variant.id, 
          quantity: item.currentQuantity, 
          price: item.variant.price, 
          title: item.title 
        }
      })
      setVariantQuantityData(orderVariantData);
      setLoading(false);
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    let payerTypeAdmin = false;
    let closeDatePending = false;

    const orderTags = order.tags || [];

    for (const tag of orderTags) {
      if (tag == "payer_type=admin_payer") {
        payerTypeAdmin = true
      }
      if (tag == "close_date=pending") {
        closeDatePending = true;
      }
    }
    if (payerTypeAdmin && closeDatePending) {
      setCanEditOrder(true)
    }
  }, [order])


  const updateOrderDetails = () => {
    const originalVariantData = orderItems.map((item: any) => {
      return { id: item.variant.id, quantity: item.currentQuantity, price: item.variant.price }
    })
    const variantUpdateData = variantQuantityData.filter((entry: IVariantQuantityDataEntry) => {
      const originalToCompare = originalVariantData.find((originalEntry: IVariantQuantityDataEntry) =>
        originalEntry.id === entry.id
      )
      if (originalToCompare && (originalToCompare.quantity !== entry.quantity)) {
        return entry;
      }
    })
    axios.post("/.netlify/functions/updateOrderLineItemQuantities", {
      orderData: order,
      variantUpdateData: variantUpdateData
    })
      .then(resp => {
        handleVariantQuantityEdit(false);
        toast.success("Order updated")
      })
      .catch(err => {
        console.log(err);
        toast.error("Unable to update order. Please try again or contact support.")
      })
  }

  const handleVariantQuantityEdit = (editActive: boolean) => {
    setVariantQuantityEditActive(editActive);
  }

  if (loading) {
    return (
      <div className="order-details w-full h-96 md:p-30">
        <Loading isLoading={loading as boolean} />
      </div>
    );
  }

  return (
    <div className="md:flex">
      <div className="order-details w-full md:p-30">
        <div className="p-20 md:p-0">
          <div className="w-full flex mb-3 lg:mb-3">
            <div className="flex">
              <CTA
                size="small"
                type="mobile-icon"
                onClick={() => {
                  setSelectedOrderDetails({
                    viewType: 'combined_order',
                    combinedOrder: combinedOrder,
                    individualOrder: null
                  })
                }}
              >
                <MdArrowBack />
              </CTA>
              <div className="ml-6 flex font-bold text-xl">{order.name}</div>
            </div>
          </div>

          <div className='md:flex w-full md:ml-60'>
            <div className='md:flex-grow mb-15'>
              <div className='section-title mb-10'>
                {order.customer ? order.customer.displayName : ''}
              </div>
              <div className='section-info'>
                {order.customer ? order.customer.email : ''}
              </div>
            </div>
            <div className='md:flex-grow mb-15'>
              <div className='section-title mb-10'>
                Shipping Address
              </div>
              <div className='section-info'>
                <div className='section-subtitle'>{shipToOffice ? 'Ship to Office' : ''}</div>
                {shipToOffice && officeAddress ? <>
                  <div>{officeAddress.address1} {officeAddress.address2}</div>
                  <div>{officeAddress.city} {officeAddress.province} {officeAddress.zip}</div>
                  <div>{officeAddress.country}</div>
                </>
                  :
                  order.shippingAddress && <>
                    <div>{order.shippingAddress.address1} {order.shippingAddress.address2}</div>
                    <div>{order.shippingAddress.city} {order.shippingAddress.province} {order.shippingAddress.zip}</div>
                    <div>{order.shippingAddress.country}</div>
                  </>
                }
              </div>
            </div>
            {order.status === OrderStatus.closed &&
              <div className='md:flex-grow'>
                <div className='section-title mb-10'>
                  Billing Address
                </div>
                <div className='section-info'>
                  <div className='section-subtitle'>{'Group Payment'}</div>
                  {order.billingAddress && <>
                    <div>{order.billingAddress.address1} {order.billingAddress.address2}</div>
                    <div>{order.billingAddress.city} {order.billingAddress.province} {order.billingAddress.zip}</div>
                    <div>{order.billingAddress.country}</div>
                  </>
                  }
                </div>
              </div>
            }
          </div>
        </div>
        <div className="w-full mt-10">
          <IndividualOrderDetailsList
            order={order}
            orderItems={orderItems}
            orderItemsFulfillment={orderItemsFulfillment}
            display={true}
            variantQuantityData={variantQuantityData}
            setVariantQuantityData={setVariantQuantityData}
            handleVariantQuantityEdit={handleVariantQuantityEdit}
            canEditOrder={canEditOrder}
          />
        </div>
      </div>
      <OrderDetailsSummary
        order={order}
        individualOrder={individualOrder}
        userRole={userRole}
        closeDate={closeDate}
        updateOrderDetails={updateOrderDetails}
        variantQuantityData={variantQuantityData}
        variantQuantityEditActive={variantQuantityEditActive}
        canEditOrder={canEditOrder}
        trackingCode={orderTrackingCode}
      />
    </div>
  )
}
