import axios, { AxiosResponse } from "axios";
import {
  IShopifyOrder,
} from "@merchstores/admin/types/shopifyOrder";

interface IFetchMerchStoreOrderParams {
  storeCode: string;
  orderId: string;
  snapshot?: string;
}

export interface ILineItem {
  title: string,
  variantTitle: string,
  quantity: number,
  currentQuantity: number,
  variant: {
    id: string
    price: string
  }
}


export interface ILineItemFulfillment {
  variantId: string;
  fulfilledQuantity: number;
  outOfStockQuantity: number;
}

interface IMerchStoreFetchOrderData {
  shopifyOrder: IShopifyOrder | null;
  tracking: {
    trackingStatus: string;
    trackingCode: string;
  }
  recipient: {
    recipientId: string
  }
  fulfillment: Array<ILineItemFulfillment>
}

export async function fetchMerchStoreOrder(
  params: IFetchMerchStoreOrderParams
): Promise<IMerchStoreFetchOrderData> {
  if (!params.storeCode) {
    throw new Error("storeCode parameter is required");
  }

  const response: AxiosResponse = await axios
    .post("/.netlify/functions/fetchMerchStoreOrderById", params)
    .catch((err) => {
      if (err.response && err.response.data) {
        console.error(err.response.data);
      }

      throw err;
    });

  const data = response.data ?? {};

  return {
    shopifyOrder: data.order || data.shopifyOrder || null,
    tracking: data.tracking,
    recipient: data.recipient,
    fulfillment: data.fulfillment || []
  };
}
