import { useState, useEffect } from "react";
import { ISubmissionProps } from "../ShippingForm";
import { IShippingPackageProps, ShippingAddress } from "./MemberSubmission";
import { Loading } from "@merchstores/shared/components/Loading";
import { Card } from "@merchstores/shared/elements/Card";
import { CTA } from "@merchstores/shared/elements/Cta";

import { FormikInput } from "@merchstores/shared/elements/Formik/FormikInput";
import { Formik, FormikHelpers, FormikProps } from 'formik';
import { FormSelect, IFormOptionProps } from "@merchstores/shared/elements/FormSelect";
import { fetchShippingAddresses } from "@merchstores/admin/api/hubspot";
import { GroupOrderAddress } from "@merchstores/admin/types/address";
import { deleteSubmission } from "./MemberSubmissionDelete";
import { GroupOrder, submitSubmission } from "./MemberSubmissionSubmit";
import { makePackageSizeOptions } from "./SizeOptions";
import { makePackageStyleOptions } from "./StyleOptions";
import { makeArtworkOptions } from "./ArtworkOptions";
import { isValidEmail } from "./EmailValidation";


export interface IMemberSubmissionAddressFormProps {
  selectedPackage?: IShippingPackageProps,
  title?: string,
  onCloseModal?: () => unknown,
  order?: {
    groupOrderId: string
  },
  selectedSubmission?: ISubmissionProps,
  submissionUpdate?: (arg0: string, arg1: ISubmissionProps, arg2: boolean) => void,
  onFormSubmitted?: () => void,
  readOnly: boolean,
}

const GROUP_ORDER_ADDRESS_LIST = new Map<string, GroupOrderAddress>();

interface MemberSubmissionFormData {
  email: string;
  package: string;
  style: string;
  size: string;
  artwork: string;
  firstName: string;
  lastName: string;
  shippingGroupOrderAddressId: string;
}


function validate(values: MemberSubmissionFormData, selectedPackage?: IShippingPackageProps) {
  //console.log(values) 
  
  const errors: Record<string, string> = {};
  
  if (values.email && !isValidEmail(String(values.email || ''))) {
    errors.email = `${values.email} is not a valid email address`;
  }

  const requiredFieldMap = new Map<string, string>();
  requiredFieldMap.set("email", "Email");
  requiredFieldMap.set("package", "Package");
  requiredFieldMap.set("artwork", "Artwork");
  requiredFieldMap.set("firstName", "First Name");
  requiredFieldMap.set("lastName", "Last Name");
  requiredFieldMap.set("shippingGroupOrderAddressId", "Shipping Address");

  if (selectedPackage && selectedPackage.styleRequired) {
    requiredFieldMap.set("style", "Style");
  } 

  if (selectedPackage && selectedPackage.sizeRequired) {
    requiredFieldMap.set("size", "Size");
  } 

  requiredFieldMap.forEach((value, key) => {
    if (!values[key as keyof MemberSubmissionFormData]) {
      errors[key as keyof MemberSubmissionFormData] = `${value} is required`;
    }
  });

  return errors;
}

async function handleFormSubmit( parameters: {
  groupOrder: GroupOrder,
  formValues: MemberSubmissionFormData,
  formikHelpers: FormikHelpers<MemberSubmissionFormData>,
  onFormSubmitted?: () => void
}) {
  const {
    groupOrder, formValues, formikHelpers, onFormSubmitted
  } = parameters;

  formikHelpers.setSubmitting(true);

  //console.log(formValues);
  /*
  if (currentAddress) {
    updateShippingAddress({ ...currentAddress, ...values });
  } else {
    addShippingAddress({ ...values, groupOrderId, isDeleted: false });
  }
  */

  const groupOrderAddress = GROUP_ORDER_ADDRESS_LIST.get(formValues.shippingGroupOrderAddressId) || null;

  if (!groupOrderAddress) {
    throw new Error(
      `handleFormSubmit: unable to find shipping address with id ${formValues.shippingGroupOrderAddressId}`
    )
  }

  const shippingAddress = {
    ...groupOrderAddress,
    firstName: formValues.firstName,
    lastName: formValues.lastName,
    groupOrderAddressId: formValues.shippingGroupOrderAddressId
  }

  await submitSubmission({
    groupOrder: groupOrder,
    submission: {
      memberEmail: formValues.email,
      address: shippingAddress,
      selectedStyle: formValues.style,
      selectedArtwork: formValues.artwork,
      selectedPackage: formValues.package,
      newsletterSubscription: false,
      size: formValues.size,
      shippingGroupOrderAddressId: formValues.shippingGroupOrderAddressId
      //timestamp: string,
      //_id: string
    }
  });


  formikHelpers.setSubmitting(false);

  if (onFormSubmitted)
  {
    formikHelpers.resetForm();
    onFormSubmitted();
  }
}

function makeShippingAddressOptions(
  shippingAddresses: GroupOrderAddress[]
): IFormOptionProps[] {

  return shippingAddresses.map(address => {
    return {
      value: String(address._id),
      displayText: address.locationDisplayName
    }
  })
}


export const MemberSubmissionManagedAddressForm: React.FC<IMemberSubmissionAddressFormProps> = (props: IMemberSubmissionAddressFormProps) => {
  const [selectedPackage, setSelectedPackage] = useState(props.selectedPackage);
  const [order, setOrder] = useState(props.order);
  const [selectedSubmission, setSelectedSubmission] = useState(props.selectedSubmission);
  const [loading, setLoading] = useState(true);
  const [shippingAddresses, setShippingAddresses] = useState([]);

  useEffect(() => {

    fetchShippingAddresses(order.groupOrderId).then(shippingAddresses => {
      setShippingAddresses(shippingAddresses);

      for (const groupOrderAddress of shippingAddresses) {
        GROUP_ORDER_ADDRESS_LIST.set(groupOrderAddress._id, groupOrderAddress);
      }

      setOrder(props.order);
      setSelectedPackage(props.selectedPackage);

      // if the submission is prefilled, set the state
      if (props.selectedSubmission && props.selectedSubmission.address) {
        setSelectedSubmission(props.selectedSubmission);
      }

      setLoading(false);
    });

  }, [props.selectedSubmission, props.order, props.selectedPackage]);


  const handleSubmissionDeletion = () => {

    deleteSubmission(selectedSubmission.id).catch((deleteError: unknown) => {
      console.log("deleteSubmission", deleteError);
    });

    if (props.onCloseModal) {
      props.onCloseModal();
    }
    if (props.submissionUpdate) {
      props.submissionUpdate(selectedSubmission.email, {} as ISubmissionProps, true);
    }
  }

  const defaultShippingAddress = (shippingAddresses || []).find( 
    (address: GroupOrderAddress) => address.isDefault
  )

  const currentSubmission = {
    email: '',
    package: selectedPackage.title,
    style: selectedPackage.selectedStyle,
    size: selectedPackage.selectedSize,
    artwork: selectedPackage.selectedArtwork,
    firstName: '',
    lastName: '',
    shippingGroupOrderAddressId: defaultShippingAddress ? defaultShippingAddress._id : '',
    newsletterSubscription: false
  }

  if (selectedSubmission && selectedSubmission.submission) {
    //console.log("selectedSubmission", selectedSubmission)
    currentSubmission.shippingGroupOrderAddressId = selectedSubmission.submission.shippingGroupOrderAddressId;
    currentSubmission.email = selectedSubmission.email || selectedSubmission.submission.memberEmail;
    currentSubmission.size = selectedSubmission.submission.size;
    currentSubmission.style = selectedSubmission.submission.selectedStyle;
    currentSubmission.artwork = selectedSubmission.submission.selectedArtwork;
    currentSubmission.package = selectedSubmission.submission.selectedPackage;
    currentSubmission.newsletterSubscription = selectedSubmission.submission.newsletterSubscription || false;
    //currentSubmission.timestamp = selectedSubmission.submission.timestamp;
    currentSubmission.firstName = selectedSubmission.submission.address ? selectedSubmission.submission.address.firstName : "";
    currentSubmission.lastName = selectedSubmission.submission.address ? selectedSubmission.submission.address.lastName  : "";
  }

  if (loading) {
    return (<Loading isLoading={true} />);
  }

  return <>
    <Card style={{ minHeight: "420px", minWidth: "420px", maxWidth: "420px" }}>
      { /* HERE:SOL-158 */}
      <div className={`shipping-form-container ${loading ? "loading" : ""}`}>
        <h2>Your Group Order Details</h2>

        <Formik
          validate={ (values: MemberSubmissionFormData) => validate(values, selectedPackage) }
          onSubmit={ (values, formikHelpers) => handleFormSubmit({
            groupOrder: order, 
            formValues: values, 
            formikHelpers: formikHelpers, 
            onFormSubmitted: props.onFormSubmitted
          }) }
          initialValues={currentSubmission || {}}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleSubmit,
            handleChange,
            isSubmitting,
            setFieldValue,
          }: FormikProps<MemberSubmissionFormData>) => (
            <form onSubmit={handleSubmit} className="px-30">

              <p className="mb-2 font-semibold">
                Order Details:
              </p>
              <FormikInput
                type='text'
                disabled={ Boolean(selectedSubmission) }
                id='email'
                name='email'
                label='Email'
                value={values?.email}
                error={touched?.email ? errors?.email : null}
                onChange={handleChange}
                onBlur={handleBlur}
                defaultValue={values?.email}
              />

              <FormSelect
                label='Package'
                name='package'
                disabled={true}
                errors={ touched?.package && errors?.package ? { package: { message: errors?.package} } : {} }
                register={handleChange}
                setValue={(field: string, value: string) => {
                  setFieldValue(field, value);
                }}
                options={[ { value: selectedPackage.title, displayText: selectedPackage.title } ]}
                selectStyle='two'
                classes='mb-5'
                default={values?.package}
              />

              { selectedPackage.sizeRequired &&
              <FormSelect
                label='Size'
                name='size'
                errors={ touched?.size && errors?.size ? { size: { message: errors?.size} } : {} }
                register={handleChange}
                setValue={(field: string, value: string) => {
                  setFieldValue(field, value);
                }}
                options={makePackageSizeOptions(selectedPackage) || []}
                selectStyle='two'
                classes='mb-5'
                default={values?.size}
              />
              }

              { selectedPackage.styleRequired &&
              <FormSelect
                label='Style'
                name='style'
                errors={ touched?.style && errors?.style ? { style: { message: errors?.style} } : {} }
                register={handleChange}
                setValue={(field: string, value: string) => {
                  setFieldValue(field, value);
                }}
                options={makePackageStyleOptions(selectedPackage) || []}
                selectStyle='two'
                classes='mb-5'
                default={values?.style}
              />
              }
              
              <p className="mb-2 mt-6 font-semibold">
                Artwork:
              </p>

              <FormSelect
                label=''
                name='artwork'
                artwork={ Boolean(currentSubmission.artwork) }
                errors={ touched?.artwork && errors?.artwork ? { artwork: { message: errors?.artwork} } : {} }
                register={handleChange}
                setValue={(field: string, value: string) => {
                  setFieldValue(field, value);
                  /*
                  if (value !== ENABLE_STATE_COUNTRY) {
                      setFieldValue('state', null);
                  }
                  */
                }}
                options={makeArtworkOptions(selectedPackage) || []}
                selectStyle='two'
                classes='mb-5'
                default={values?.artwork}
              />

              <p className="mb-2 mt-6 font-semibold">
                Shipping Address:
              </p>

              <div className="md:grid md:grid-flow-col md:grid-cols-2 md:gap-x-4">
                <div className="">
                  <FormikInput
                    type='text'
                    id='firstName'
                    name='firstName'
                    label='First Name'
                    value={values?.firstName}
                    error={touched?.firstName ? errors?.firstName : null}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    defaultValue={values?.firstName}
                  />
                </div>

                <div className="">
                  <FormikInput
                    type='text'
                    id='lastName'
                    name='lastName'
                    label='Last Name'
                    value={values?.lastName}
                    error={touched?.lastName ? errors?.lastName : null}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    defaultValue={values?.lastName}
                  />
                </div>
              </div>

              <FormSelect
                label='Shipping Address'
                name='shippingGroupOrderAddressId'
                errors={ 
                  touched?.shippingGroupOrderAddressId && errors?.shippingGroupOrderAddressId ? 
                  { shippingGroupOrderAddressId: { message: errors?.shippingGroupOrderAddressId} } : 
                  {} 
                }
                register={handleChange}
                setValue={(field: string, value: string) => {
                  setFieldValue(field, value);
                }}
                options={makeShippingAddressOptions(shippingAddresses) || []}
                selectStyle='two'
                classes='mb-5'
                default={ values?.shippingGroupOrderAddressId }
              />

              <div className='flex mt-6'>
                <CTA
                  type='primary'
                  size='standard'
                  formSubmit={true}
                  classes='flex-1 text-base pt-3 pb-3'
                  disabled={isSubmitting/* || Object.keys(errors).length > 0 */}
                >
                  {isSubmitting ? "Submitting..." : "Submit Your Order"}
                </CTA>
              </div>
            </form>
          )}
        </Formik>

        {!props.readOnly && order && selectedSubmission && selectedSubmission.id && (
          <div className="px-30 -mt-2.5 mb-5">
            <CTA
              size="standard"
              type="secondary"
              onClick={() => { handleSubmissionDeletion() }}
              stretch={true}
            >
              Delete Submission
            </CTA>
          </div>
        )}
      </div>
    </Card>
  </>
}
