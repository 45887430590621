import SummaryCard from "./SummaryCard";
import { moneyFormat } from "@merchstores/shared/components/Money";


interface GiftCardInfo {
  cardValue: number | string;
  cardCount: number;
}

interface AvailableGiftCardsSummaryProps {
  giftCards: [];
}

const AvailableGiftCardsSummary = (props: AvailableGiftCardsSummaryProps): JSX.Element | null => {
  const giftCards: GiftCardInfo[] = Array.isArray(props.giftCards) ? props.giftCards : [];

  if (!giftCards.length) {
    return null;
  }

  return (
    <div className="grid px-6 w-full items-center mb-30 gap-5 md:grid-cols-4 sm:px-0 sm:flex-row sm:grid-cols-2">
      {
      giftCards.map( (giftCard: GiftCardInfo) => {
        return <SummaryCard
          key={giftCard.cardValue}
          heading={ moneyFormat(String(giftCard.cardValue), 0) }
          description={`${giftCard.cardCount} remaining`} />
      })
      }
    </div>
  );
};

export default AvailableGiftCardsSummary;