
const MetafieldConnection = {
  "name": "MetafieldConnection",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "edges": "MetafieldEdge",
    "pageInfo": "PageInfo"
  },
  "implementsNode": false
};
export default MetafieldConnection;