import { useState } from "react";
import { useEffect } from "react";
import { isEmpty, isEqual } from "lodash";
import axios from "axios";
import { IShippingFormProps, ISubmissionProps } from ".";
import { Card } from "@merchstores/shared/elements/Card";
import { HubspotForm } from "@merchstores/shared/elements/HubspotForm";
import { sendEmail } from "@merchstores/shared/components/Mailer";
import { CTA } from "@merchstores/shared/elements/Cta";
import { Loading } from "@merchstores/shared/components/Loading";
import { SUBMISSION_CONFIRMATION } from "@merchstores/admin/mailing/templates";
import { validateGOTShippingAddress } from './validators';
import "./styles.scss";

export const ShippingForm: React.FC<IShippingFormProps> = (props: IShippingFormProps) => {

  const [formId] = useState(process.env.REACT_APP_SHIPPING_HUBSPOT_FORM_ID);
  const [portalId] = useState(process.env.REACT_APP_SHIPPING_HUBSPOT_PORTAL_ID);
  const [selectedPackage, setSelectedPackage] = useState(props.selectedPackage);
  const [order, setOrder] = useState(props.order);
  const [selectedSubmission, setSelectedSubmission] = useState(props.selectedSubmission);
  const [loading, setLoading] = useState(true);
  let submitting = false;

  const UNITED_STATES_COUNTRY = 'United States';
  const SHIPPING_STATE_FIELD_KEY = 'group_order_tool_shipping_state';
  const SHIPPING_COUNTRY_FIELD_KEY = 'group_order_tool_shipping_country';

  document.querySelector(`[id*='${SHIPPING_STATE_FIELD_KEY}-${formId}_1']`);

  useEffect(() => {
    // if the submission is prefilled, set the state
    if (props.selectedSubmission) {
      setSelectedPackage(props.selectedPackage);
      setOrder(props.order);
      setSelectedSubmission(props.selectedSubmission);
    } else {
      setOrder(props.order);
      setSelectedPackage(props.selectedPackage);
    }
  }, [props.selectedSubmission, props.order, props.selectedPackage])

  const insertAfter = (referenceNode: any, newNode: any) => {
    referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
  }

  const addEventListener = (el: any, eventName: any, handler: any) => {
    if (el.addEventListener) {
      el.addEventListener(eventName, handler);
    } else {
      el.attachEvent('on' + eventName, function () {
        handler.call(el);
      });
    }
  }

  const addEventListeners = (selector: any, type: any, handler: any) => {
    const elements = document.querySelectorAll(selector);
    for (let i = 0; i < elements.length; i++) {
      addEventListener(elements[i], type, handler);
    }
  }

  const updateSelect = (key: string, value: string) => {
    const element = document.getElementById(`${key}-${formId}_1`) as HTMLSelectElement;
    const selectedCountry = document.getElementById(`${SHIPPING_COUNTRY_FIELD_KEY}-${formId}_1`) as HTMLSelectElement;
    element.value = value;

    if (
      isEqual(key, SHIPPING_STATE_FIELD_KEY) &&
      isEmpty(value) &&
      !isEqual(selectedCountry.value, UNITED_STATES_COUNTRY)
    ) {
      // eslint-disable-next-line max-len
      const defaultStateOption = document.querySelector(`[data-reactid*="${SHIPPING_STATE_FIELD_KEY}.0.0"]`) as HTMLSelectElement;
      defaultStateOption.innerHTML = '  ---  ';
    }

    element.dispatchEvent(new Event('focus', { bubbles: true }));
    element.dispatchEvent(new Event('change', { bubbles: true }));
  }

  const disableInput = (key: string) => {
    const element = document.getElementsByName(key)[0] as HTMLInputElement;
    element.disabled = true;
    element.style.opacity = ".5";
  }
  const disableSelect = (key: string) => {
    const element = document.getElementById(`${key}-${formId}_1`) as HTMLSelectElement;
    element.disabled = true;
    element.style.opacity = ".5";
  }

  const validateShippingForm = async () => {
    const errorMessage = document.getElementById("error-message") as HTMLElement;
    const _selectedPackage = document.querySelector(".hs_selected_package select") as HTMLSelectElement;
    if (_selectedPackage && (!_selectedPackage.value || _selectedPackage.value === "default")) {
      if (errorMessage) {
        errorMessage.innerHTML = 'Please select a package.';
      } else {
        alert('Please select a package.');
      }
      return false;
    }

    const kvpairs = [];
    const form = document.getElementsByClassName("hs-form")[0] as HTMLFormElement;
    const isFormValid = form.checkValidity();


    if (!isFormValid) {
      if (errorMessage) {
        form.reportValidity();
        errorMessage.innerHTML = `
            We could not verify this address. Please double check your 
            inputs or reach out to your group administrator for support.`;
      } else {
        alert('We could not verify this address.');
      }
      return false;
    }

    for (let i = 0; i < form.elements.length; i++) {
      const e = form.elements[i] as HTMLFormElement;
      kvpairs.push(e.name + "=" + e.value);
    }

    let firstName = '';
    let lastName = '';
    let email = '';
    let streetAddressLine1 = '';
    let streetAddressLine2 = '';
    let city = "";
    let state = "";
    let zip = "";
    let country = "";
    let phone = "";

    for (const v of kvpairs) {
      const key = v.split("=")[0];
      const value = v.split("=")[1];

      switch (key) {
        case "email":
          email = value;
          break;
        case "firstname":
          firstName = value;
          break;
        case "lastname":
          lastName = value;
          break;
        case "address":
          streetAddressLine1 = value;
          break;
        case "apt__suite__etc":
          streetAddressLine2 = value;
          break;
        case SHIPPING_STATE_FIELD_KEY:
          state = value;
          break;
        case "city":
          city = value;
          break;
        case SHIPPING_COUNTRY_FIELD_KEY:
          country = value;
          break;
        case "zip":
          zip = value;
          break;
        case "phone":
          phone = value;
          break;
        default:
          break;
      }
    }

    if (phone.length < 7 || phone.length > 20) {
      errorMessage.innerHTML = 'Please enter a valid phone.';
      return false;
    }

    try {
      const { errors } = await validateGOTShippingAddress({
        email,
        firstName,
        lastName,
        address1: streetAddressLine1 + ' ' + streetAddressLine2,
        city,
        state,
        country,
        zip,
        phone
      });

      if (!isEmpty(errors)) {
        const errorMessages = errors.map(err => err.message + '.<br/>');
        errorMessage.innerHTML = errorMessages.join('');
        return false;
      }

      //clear errors here
      return true;
    }
    catch (err) {
      return false;
    }
  }
  const handleValidateGOTShippingForm = async () => validateShippingForm();

  const onFormReady = () => {
    addEventListeners("input", "focus", function (e: any) {
      const grandParent = e.target.parentElement.parentElement;
      grandParent.classList.add("focus");
    });

    addEventListeners("input", "focusout", function (e: any) {
      if (e.target.value === "") {
        const grandParent = e.target.parentElement.parentElement;
        grandParent.classList.remove("focus");
      }
    });

    addEventListeners('[name="address"]', "keypress", function (e: any) {
      // disable form submission when pressing return or enter on the autocomplete field
      if (e.which === 13) e.preventDefault();
    });

    addEventListeners(`#item_submissions_artwork-${formId}_1`, "change", function (e: any) {
      const selectedArtwork = e.target.value;
      const artworkSelect = document.getElementById(`item_submissions_artwork-${formId}_1`) as HTMLSelectElement;
      const artworkPreview = Array.from(document.getElementsByClassName("artwork-preview"));
      artworkPreview.forEach((ap: any) => {
        ap.setAttribute("src", selectedArtwork);
      });
      artworkSelect.value = selectedArtwork;
    });

    // Disable State select if the Country is not "United States"
    addEventListeners(`#${SHIPPING_COUNTRY_FIELD_KEY}-${formId}_1`, "change", function (e: any) {
      const selectedCountry = e.target.value;
      const stateSelect = document.getElementById(`${SHIPPING_STATE_FIELD_KEY}-${formId}_1`) as HTMLSelectElement;
      if (selectedCountry !== UNITED_STATES_COUNTRY) {
        updateSelect(SHIPPING_STATE_FIELD_KEY, '');
        stateSelect.disabled = true;
      }
      else {
        // eslint-disable-next-line max-len
        const defaultStateOption = document.querySelector(`[data-reactid*="${SHIPPING_STATE_FIELD_KEY}.0.0"]`) as HTMLSelectElement || {};
        defaultStateOption.innerHTML = 'Please Select';
        stateSelect.disabled = false;
      }
    });

    styleFrom();
    fillFormData();
    setLoading(false);
  }

  const styleFrom = () => {
    // Get elements
    const form = document.getElementsByClassName("hs-form")[0];
    const artworkDiv = document.getElementsByClassName("hs_item_submissions_artwork")[0];
    const itemSubmissionsDiv = document.getElementsByClassName("hs_item_submissions")[0];
    const selectedPackageDiv = document.getElementsByClassName("hs_selected_package")[0];

    // Add "Member" label before email
    const memberLabel = document.createElement("label");
    const memberContainerDiv = document.createElement("div");
    memberLabel.innerHTML = "Member";
    const emailDiv = document.getElementsByClassName("hs_email")[0];
    memberContainerDiv.appendChild(memberLabel);
    memberContainerDiv.appendChild(emailDiv);
    form.prepend(memberContainerDiv);

    //Disable Selected Package input (read-only)
    const userSelectedPackageInput = document.createElement("input");
    if (selectedPackage) {
      (userSelectedPackageInput).setAttribute("value", selectedPackage.title);
    }
    (userSelectedPackageInput).dispatchEvent(new Event('input', { bubbles: true }));
    if (!selectedSubmission) {
      userSelectedPackageInput.setAttribute("disabled", "true");
    }
    userSelectedPackageInput.classList.add("selected-package-input");
    const selectedPackageInput = document.getElementsByName(`selected_package`)[0] as HTMLInputElement;

    const selectedPackageContainer = document.getElementsByClassName("hs_selected_package")[0] as HTMLInputElement;
    selectedPackageContainer.style.display = "";
    insertAfter(selectedPackageInput, userSelectedPackageInput);
    const itemSubmissionsSelect = document.getElementById(`item_submissions-${formId}_1`) as HTMLSelectElement;
    const itemStyleSelect = document.getElementById(`styles-${formId}_1`) as HTMLSelectElement;
    const artworkSelect = document.getElementById(`item_submissions_artwork-${formId}_1`) as HTMLSelectElement;
    if (selectedSubmission) {
      // if this is an existing submission, replace the submission input with a select
      const primarySubmissionLabel = document.getElementById(`label-selected_package-${formId}_1`);
      primarySubmissionLabel?.classList.add("primary-submission-dropdown-label");
      const itemPackageSelectLabel = document.createElement('label');
      itemPackageSelectLabel.innerHTML = "Package";
      itemPackageSelectLabel.setAttribute("id", "label-select-package-dropdown")
      const itemPackageSelect = document.createElement('select');
      itemPackageSelect.setAttribute("id", "user-package-select-dropdown");
      // if the admin is creating a submission on behalf of the user, create a default option 
      // and disable the submission fields until a package is selected
      if (!selectedSubmission.submission._id) {
        const defaultOption = document.createElement("option") as HTMLOptionElement;
        defaultOption.value = "default";
        defaultOption.innerHTML = "Please Select";
        itemPackageSelect.append(defaultOption);
        itemPackageSelect.value = "default";
        itemSubmissionsSelect.style.opacity = ".5";
        itemSubmissionsSelect.disabled = true;
        itemStyleSelect.style.opacity = ".5";
        itemStyleSelect.disabled = true;
        artworkSelect.style.opacity = ".5";
        artworkSelect.disabled = true;
      }
      order.packages.forEach((packageData: any) => {
        const newOption = document.createElement("option") as HTMLOptionElement;
        newOption.value = packageData.title;
        newOption.innerHTML = packageData.title;
        itemPackageSelect.append(newOption);
      });
      selectedPackageContainer.appendChild(itemPackageSelectLabel);
      selectedPackageContainer.appendChild(itemPackageSelect);
      userSelectedPackageInput.style.display = "none";
      // this function clears the options from a select element
      const clearSelect = (selectElement: HTMLSelectElement) => {
        const length = selectElement.options.length - 1;
        for (let i = length; i >= 0; i--) {
          selectElement.remove(i);
        }
      }
      // if the select changes, update the options in the other selects
      itemPackageSelect.addEventListener("change", (e: Event) => {
        const target = e.target as HTMLSelectElement;
        let packageToUse = null;
        for (const key in props.order.packages) {
          if (props.order.packages[key].title === target.value) {
            packageToUse = props.order.packages[key]
          }
        }
        const packageElement = document.getElementsByName("selected_package")[0] as HTMLInputElement;
        (packageElement).setAttribute("value", target.value);
        (packageElement).dispatchEvent(new Event('focus', { bubbles: true }));
        (packageElement).dispatchEvent(new Event('input', { bubbles: true }));
        itemSubmissionsSelect.style.opacity = "1";
        itemSubmissionsSelect.disabled = false;
        itemStyleSelect.style.opacity = "1";
        itemStyleSelect.disabled = false;
        artworkSelect.style.opacity = "1";
        artworkSelect.disabled = false;
        const sizesContainer = document.getElementsByClassName("hs_item_submissions")[0] as HTMLSelectElement;
        clearSelect(itemSubmissionsSelect);
        const sizeSplit = packageToUse.sizes.length ? packageToUse.sizes.split(",") : [];
        if (sizeSplit.length > 0) {
          sizesContainer.style.display = "block";
          sizeSplit.forEach((size: string) => {
            const sizeOption = document.createElement("option") as HTMLOptionElement;
            sizeOption.value = size.trim();
            sizeOption.innerHTML = size.trim();
            itemSubmissionsSelect.append(sizeOption);
          });
          if (sizeSplit.length === 1) {
            itemSubmissionsSelect.classList.add("one-option");
            itemSubmissionsSelect.addEventListener("mousedown", cancelDropDown, false);
          } else {
            itemSubmissionsSelect.classList.remove("one-option");
            itemSubmissionsSelect.removeEventListener("mousedown", cancelDropDown, false);
          }
        } else {
          sizesContainer.style.display = "none";
        }
        const stylesContainer = document.getElementsByClassName("hs_styles")[0] as HTMLSelectElement;
        clearSelect(itemStyleSelect);
        const styleSplit = packageToUse.styles.length ? packageToUse.styles.split(",") : [];
        if (styleSplit.length > 0) {
          stylesContainer.style.display = "block";
          styleSplit.forEach((style: string) => {
            const styleOption = document.createElement("option") as HTMLOptionElement;
            styleOption.value = style.trim();
            styleOption.innerHTML = style.trim();
            itemStyleSelect.append(styleOption);
          });
          if (styleSplit.length === 1) {
            itemStyleSelect.classList.add("one-option");
            itemStyleSelect.addEventListener("mousedown", cancelDropDown, false);
          } else {
            itemStyleSelect.classList.remove("one-option");
            itemStyleSelect.removeEventListener("mousedown", cancelDropDown, false);
          }
        } else {
          stylesContainer.style.display = "none";
        }
        clearSelect(artworkSelect);
        // eslint-disable-next-line max-len
        const artworkContainer = document.getElementsByClassName("hs_item_submissions_artwork")[0] as HTMLSelectElement;
        if (packageToUse.artwork.length) {
          artworkContainer.style.display = "block";
          packageToUse.artwork.forEach((a: any) => {
            const artOption = document.createElement("option") as HTMLOptionElement;
            artOption.value = a.logo;
            const fileName = a.logo.substr(a.logo.lastIndexOf('/')).replace("/", "");
            artOption.innerHTML = fileName;
            artworkSelect.append(artOption);
            const artworkPreview = Array.from(document.getElementsByClassName("artwork-preview"));
            artworkPreview.forEach((ap: any) => {
              ap.setAttribute("src", a.logo);
            });
          });

          if (packageToUse.artwork.length === 1) {
            artworkSelect.classList.add("one-option");
            artworkSelect.addEventListener("mousedown", cancelDropDown, false);
          } else {
            artworkSelect.classList.remove("one-option");
            artworkSelect.removeEventListener("mousedown", cancelDropDown, false);
          }
        }
        // remove the default option if it still exists
        if (!selectedSubmission.submission._id) {
          const packageSelect = document.getElementById("user-package-select-dropdown") as HTMLSelectElement;
          for (let i = 0; i < packageSelect.length; i++) {
            if (packageSelect.options[i].value === "default") {
              packageSelect.remove(i);
              return;
            }
          }
        }
      });
    }
    // Add "Item Submissions" label before options
    const itemSubmissionsLabel = document.createElement("label");
    const itemSubmissionsContainerDiv = document.createElement("div");
    itemSubmissionsLabel.innerHTML = "Item Submissions";
    itemSubmissionsContainerDiv.appendChild(itemSubmissionsLabel);
    itemSubmissionsContainerDiv.appendChild(itemSubmissionsDiv);
    insertAfter(selectedPackageDiv, itemSubmissionsContainerDiv);

    // Append artwork image element in "Artwork Selection"
    const artworkImg = document.createElement("img");
    artworkImg.classList.add("artwork-preview");
    if (artworkDiv) {
      artworkDiv.prepend(artworkImg);
    }

    // Add "Shipping Information" label before first name and last name input
    // Style first name and last name input side by side
    const namesDiv = document.createElement("div");
    const namesContainerDiv = document.createElement("div");
    namesDiv.classList.add("flex");
    namesDiv.classList.add("justify-between");
    namesDiv.classList.add("items-start");
    namesDiv.classList.add("names-container");
    const firstNameDiv = document.getElementsByClassName("hs_firstname")[0];
    firstNameDiv.classList.add("flex-1");
    const lastNameDiv = document.getElementsByClassName("hs_lastname")[0];
    lastNameDiv.classList.add("flex-1");
    namesDiv.appendChild(firstNameDiv);
    namesDiv.appendChild(lastNameDiv);
    const label = document.createElement("label");
    label.innerHTML = "Shipping Information";
    namesContainerDiv.appendChild(label);
    namesContainerDiv.appendChild(namesDiv);
    insertAfter(artworkDiv, namesContainerDiv);

    // Style state and city inputs side by side
    const stateCityDiv = document.createElement("div");
    stateCityDiv.classList.add("flex");
    stateCityDiv.classList.add("justify-between");
    stateCityDiv.classList.add("items-start");
    stateCityDiv.classList.add("state-city-container");
    const stateDiv = document.getElementsByClassName(`hs_${SHIPPING_STATE_FIELD_KEY}`)[0];
    const cityDiv2 = document.getElementsByClassName("hs_city")[0];
    stateDiv.classList.add("flex-1");
    cityDiv2.classList.add("flex-1");
    stateCityDiv.appendChild(stateDiv);
    stateCityDiv.appendChild(cityDiv2);
    const $addressLine2Container = document.getElementsByClassName("hs_apt__suite__etc")[0];
    insertAfter($addressLine2Container, stateCityDiv);

    // Style country and postal code input side by side
    const countryNewDiv = document.createElement("div");
    countryNewDiv.classList.add("flex");
    countryNewDiv.classList.add("justify-between");
    countryNewDiv.classList.add("items-start");
    countryNewDiv.classList.add("country-container");
    const countryDiv = document.getElementsByClassName(`hs_${SHIPPING_COUNTRY_FIELD_KEY}`)[0];
    countryDiv.classList.add("flex-2");
    const zipDiv = document.getElementsByClassName("hs_zip")[0];
    zipDiv.classList.add("flex-1");
    countryNewDiv.appendChild(countryDiv);
    countryNewDiv.appendChild(zipDiv);
    const stateCityContainerDiv = document.getElementsByClassName("state-city-container")[0];
    insertAfter(stateCityContainerDiv, countryNewDiv);

    const submitBtn = document.getElementsByClassName("hs-button")[0] as HTMLInputElement;
    submitBtn.classList.add("btn");
    submitBtn.classList.add("primary");

    const errorMessage = document.createElement("p");
    errorMessage.setAttribute("id", "error-message");
    errorMessage.classList.add("error-message");
    errorMessage.classList.add("mb-20");
    const phoneDiv = document.getElementsByClassName("hs_phone")[0];
    insertAfter(phoneDiv, errorMessage);
    document.getElementById(`selected_package-${formId}_1`)?.setAttribute("disabled", "true");
  }

  const fillFormData = () => {
    const packageTitle = selectedPackage.title;
    const sizes = selectedPackage.sizes ? selectedPackage.sizes.split(",") : [];
    const styles = selectedPackage.styles ? selectedPackage.styles.split(",") : [];
    const artwork = selectedPackage.artwork ? selectedPackage.artwork : [];

    const selectedPackageInput = document.getElementsByName(`selected_package`)[0] as HTMLInputElement;
    if (selectedPackageInput) {
      (selectedPackageInput).setAttribute("value", packageTitle);
      (selectedPackageInput).dispatchEvent(new Event('input', { bubbles: true }));
    }

    const itemSubmissionsSelect = document.getElementById(`item_submissions-${formId}_1`) as HTMLSelectElement;
    let length = itemSubmissionsSelect.options.length;

    for (let i = 0; i < length - 1; i++) {
      itemSubmissionsSelect.remove(i);
    }

    if (sizes.length > 0 || selectedSubmission) {
      sizes.forEach((size: string) => {
        const newOption = document.createElement("option") as HTMLOptionElement;
        newOption.value = size.trim();
        newOption.innerHTML = size.trim();
        itemSubmissionsSelect.append(newOption);
      });
      if (sizes.length === 1) {
        itemSubmissionsSelect.classList.add("one-option");
        itemSubmissionsSelect.addEventListener("mousedown", cancelDropDown, false);
      } else {
        itemSubmissionsSelect.classList.remove("one-option");
        itemSubmissionsSelect.removeEventListener("mousedown", cancelDropDown, false);
      }
    } else {
      const sizesContainer = document.getElementsByClassName("hs_item_submissions")[0] as HTMLSelectElement;
      sizesContainer.style.display = "none";
    }

    const itemStylesSelect = document.getElementById(`styles-${formId}_1`) as HTMLSelectElement;
    const stylesLength = itemStylesSelect.options.length;

    for (let i = 0; i < stylesLength - 1; i++) {
      itemStylesSelect.remove(i);
    }
    if (styles.length > 0 || selectedSubmission) {
      styles.forEach((style: string) => {
        const newOption = document.createElement("option") as HTMLOptionElement;
        newOption.value = style.trim();
        newOption.innerHTML = style.trim();
        itemStylesSelect.append(newOption);
      });
      if (styles.length === 1) {
        itemStylesSelect.classList.add("one-option");
        itemStylesSelect.addEventListener("mousedown", cancelDropDown, false);
      } else {
        itemStylesSelect.classList.remove("one-option");
        itemStylesSelect.removeEventListener("mousedown", cancelDropDown, false);
      }
    } else {
      const stylesContainer = document.getElementsByClassName("hs_styles")[0] as HTMLSelectElement;
      stylesContainer.style.display = "none";
    }

    const artworkSelect = document.getElementById(`item_submissions_artwork-${formId}_1`) as HTMLSelectElement;

    length = artworkSelect.options.length;

    for (let i = 0; i < length - 1; i++) {
      artworkSelect.remove(i);
    }
    if (artwork.length > 0 || selectedSubmission) {
      artwork.forEach((a: any) => {
        const newOption = document.createElement("option") as HTMLOptionElement;
        newOption.value = a.logo;
        const fileName = a.logo.substr(a.logo.lastIndexOf('/')).replace("/", "");
        newOption.innerHTML = fileName;
        artworkSelect.append(newOption);
      });
      if (artwork.length === 1) {
        artworkSelect.classList.add("one-option");
        artworkSelect.addEventListener("mousedown", cancelDropDown, false);
      } else {
        artworkSelect.classList.remove("one-option");
        artworkSelect.removeEventListener("mousedown", cancelDropDown, false);
      }
    } else {
      // eslint-disable-next-line max-len
      const artworkContainer = document.getElementsByClassName("hs_item_submissions_artwork")[0] as HTMLSelectElement;
      artworkContainer.style.display = "none";
    }

    if (selectedSubmission) {
      const updateCheckbox = (key: string, value: boolean) => {
        const element = document.getElementsByName(key)[0] as HTMLInputElement;
        if (element.checked && value === false) {
          element.click();
        }
      }

      const updateInput = (key: string, value: string) => {
        const element = document.getElementsByName(key)[0] as HTMLInputElement;
        (element).setAttribute("value", value);
        (element).dispatchEvent(new Event('focus', { bubbles: true }));
        (element).dispatchEvent(new Event('input', { bubbles: true }));
      }
      const updateSelect = (key: string, value: string) => {
        const element = document.getElementById(`${key}-${formId}_1`) as HTMLSelectElement;
        element.value = value;
        (element).dispatchEvent(new Event('focus', { bubbles: true }));
        (element).dispatchEvent(new Event('change', { bubbles: true }));
      }
      // if an admin is creating a submission for a user, the only field that should prefill is the email
      updateInput("email", selectedSubmission.email);
      // set email to read-only for previous submissions
      const emailInput = document.getElementsByName("email")[0] as HTMLInputElement;
      emailInput.style.display = "none";
      const dummyInputContainer = document.createElement("div");
      dummyInputContainer.classList.add("input");
      const dummyInput = document.createElement("input");
      dummyInput.value = selectedSubmission.email;
      dummyInput.disabled = true;
      dummyInput.style.color = "rgba(75, 75, 85, 0.5)";
      const emailContainer = document.getElementsByClassName("hs_email")[0];
      dummyInputContainer.appendChild(dummyInput)
      emailContainer.appendChild(dummyInputContainer);
      // otherwise prefill all data
      if (selectedSubmission.submission._id) {
        const userPackageSelect = document.getElementById("user-package-select-dropdown") as HTMLSelectElement;
        userPackageSelect.value = selectedSubmission.submission.selectedPackage;
        userPackageSelect.dispatchEvent(new Event('change', { bubbles: true }));
        itemSubmissionsSelect.value = selectedSubmission.submission.size;
        if (selectedSubmission.submission.selectedStyle) {
          itemStylesSelect.value = selectedSubmission.submission.selectedStyle;
        }
        artworkSelect.value = selectedSubmission.submission.selectedArtwork;
        const artworkPreview = Array.from(document.getElementsByClassName("artwork-preview"));
        artworkPreview.forEach((ap: any) => {
          ap.setAttribute("src", selectedSubmission.submission.selectedArtwork);
        });
        updateInput("firstname", selectedSubmission.submission.address.firstName);
        updateInput("lastname", selectedSubmission.submission.address.lastName);
        updateInput("company", selectedSubmission.submission.address.company);
        updateInput("address", selectedSubmission.submission.address.address1);
        updateInput("apt__suite__etc", selectedSubmission.submission.address.address2);
        updateInput("city", selectedSubmission.submission.address.city);
        updateSelect(SHIPPING_STATE_FIELD_KEY, selectedSubmission.submission.address.state);
        updateInput("zip", selectedSubmission.submission.address.zip);
        // eslint-disable-next-line max-len
        updateInput("phone", selectedSubmission.submission.address.phone ? selectedSubmission.submission.address.phone : '');
        updateSelect(SHIPPING_COUNTRY_FIELD_KEY, selectedSubmission.submission.address.country);
        updateCheckbox("newsletter_subscription", selectedSubmission.submission.newsletterSubscription);
      }
    } else if (selectedPackage) {
      selectedPackageInput.value = selectedPackage.title;

      if (selectedPackage.selectedSize) {
        itemSubmissionsSelect.value = selectedPackage.selectedSize;
      }
      if (selectedPackage.selectedStyle) {
        itemStylesSelect.value = selectedPackage.selectedStyle;
      }

      if (selectedPackage.selectedArtwork) {
        artworkSelect.value = selectedPackage.selectedArtwork;
        const artworkPreview = Array.from(document.getElementsByClassName("artwork-preview"));
        artworkPreview.forEach((ap: any) => {
          ap.setAttribute("src", props?.selectedPackage?.selectedArtwork);
        });
      }
    }

    if (props.readOnly) {
      const userPackageSelect = document.getElementById("user-package-select-dropdown") as HTMLSelectElement;
      userPackageSelect.disabled = true;
      userPackageSelect.style.opacity = ".5";
      disableInput("firstname");
      disableInput("lastname");
      disableInput("company");
      disableInput("address");
      disableInput("apt__suite__etc");
      disableInput("city");
      disableSelect(SHIPPING_STATE_FIELD_KEY);
      disableInput("zip");
      disableInput("phone");
      disableSelect(SHIPPING_COUNTRY_FIELD_KEY);
      disableSelect("item_submissions");
      disableSelect("item_submissions_artwork");
    }
  }

  const cancelDropDown = (ev: any) => {
    ev.preventDefault();
  }

  const sendSubmissionConfirmationEmail = (
    groupOrderId: string,
    recipient: string[],
    storeName: string,
    storeLogo: string,
    storeUrl: string,
    selectedPackage: string,
    firstName: string,
    lastName: string,
    company: string,
    streetAddress: string,
    city: string,
    state: string,
    country: string,
    postalCode: string,
    phone: string,
    selectedStyle?: string,
    selectedSize?: string,
    artwork?: string,
    artworkFileName?: string,
    apartmentNumber?: string,
    memberId?: string,

  ) => {
    const storeSite = process.env.REACT_APP_ENV === "production" ?
      "https://merchologysolutions.com" :
      "https://staging.merchologysolutions.com";

    const email = {
      templateId: SUBMISSION_CONFIRMATION,
      subject: `You’re In. Your ${storeName} Merchology Order Details Inside!`,
      text: 'Stay tuned for shipping details.',
      templateContent: [
        {
          name: 'groupOrderId',
          content: groupOrderId
        },
        {
          name: 'storeLogo',
          content: storeLogo
        },
        {
          name: 'storeUrl',
          content: `${storeSite}/${storeUrl}`
        },
        {
          name: 'memberEmail',
          content: recipient[0]
        },
        {
          name: 'selectedPackage',
          content: selectedPackage
        },
        {
          name: 'style',
          content: selectedStyle
        },
        {
          name: 'size',
          content: selectedSize
        },
        {
          name: 'artwork',
          content: artwork
        },
        {
          name: 'firstName',
          content: firstName
        },
        {
          name: 'lastName',
          content: lastName
        },
        {
          name: 'company',
          content: company
        },
        {
          name: 'streetAddress',
          content: streetAddress
        },
        {
          name: 'apartmentNumber',
          content: apartmentNumber
        },
        {
          name: 'city',
          content: city
        },
        {
          name: 'state',
          content: state
        },
        {
          name: 'country',
          content: country
        },
        {
          name: 'postalCode',
          content: postalCode
        },
        {
          name: 'phone',
          content: phone
        },
        {
          name: 'artworkName',
          content: artworkFileName
        },
        {
          name: 'groupOrderId',
          content: groupOrderId
        },
        {
          name: 'memberId',
          content: memberId
        },
      ]
    }
    sendEmail(email, recipient);
  }

  const onFormSubmit = () => {
    //Avoid double submission on double click

    if (submitting) return;
    submitting = true;
    const kvpairs = [];
    const form = document.getElementsByClassName("hs-form")[0] as HTMLFormElement;
    // eslint-disable-next-line max-len
    const newsletterSubscriptionCheckbox = document.querySelector(`#newsletter_subscription-${formId}_1`) as HTMLInputElement;

    if (isEmpty(form) || isEmpty(form.elements)) {
      return;
    }

    for (let i = 0; i < form.elements.length; i++) {
      const e = form.elements[i] as HTMLFormElement;
      kvpairs.push(e.name + "=" + e.value);
    }
    const submission = { address: {} } as ISubmissionProps;
    let email = "";
    for (const v of kvpairs) {
      const key = v.split("=")[0];
      const value = v.split("=")[1];

      switch (key) {
        case "email":
          email = value.toLowerCase();
          break;
        case "selected_package":
          submission.selectedPackage = value;
          break;
        case "item_submissions":
          submission.size = value;
          break;
        case "styles":
          submission.selectedStyle = value;
          break;
        case "item_submissions_artwork":
          submission.selectedArtwork = value;
          break;
        case "firstname":
          submission.address.firstName = value;
          break;
        case "lastname":
          submission.address.lastName = value;
          break;
        case "company":
          submission.address.company = value;
          break;
        case "address":
          submission.address.address1 = value;
          break;
        case "apt__suite__etc":
          submission.address.address2 = value;
          break;
        case SHIPPING_STATE_FIELD_KEY:
          submission.address.state = value;
          break;
        case "city":
          submission.address.city = value;
          break;
        case SHIPPING_COUNTRY_FIELD_KEY:
          submission.address.country = value;
          break;
        case "zip":
          submission.address.zip = value;
          break;
        case "phone":
          submission.address.phone = value;
          break;
        case "newsletter_subscription":
          submission.newsletterSubscription = newsletterSubscriptionCheckbox ?
            newsletterSubscriptionCheckbox.checked : false;
          break;
      }
    }

    const splitted = submission.selectedArtwork?.split("/");
    const artworkFileName = splitted ? splitted[splitted.length - 1] : "";

    axios.post('/.netlify/functions/addSubmission', {
      email,
      submission,
      groupOrderId: order ? order.groupOrderId : selectedPackage.groupOrderId
    }).then(async (res: any) => {
      if (res && res.data && res.data.newMember) {
        try {
          await axios.post('/.netlify/functions/uploadGroupMember', {
            params: {
              emailArray: [email],
              groupOrderId: order ? order.groupOrderId : selectedPackage.groupOrderId,
              verificationCode: order ? order.verificationCode === true : false,
              submissionId: res.data.submissionId
            },
          })
        } catch (err) {
          console.error(err);
        }
      }

      // Fetch memberId from recently created/updated member
      const response = await axios.post('/.netlify/functions/fetchMemberByEmail', {
        email: email,
        groupOrderId: order ? order.groupOrderId : selectedPackage.groupOrderId,
      });

      const memberId = response?.data?.memberId;

      if (props.onCloseModal) {
        props.onCloseModal();
      }
      if (props.submissionUpdate) {
        const submissionToUpdate: any = submission;
        if (res.data.id) {
          // creating a new submission will return an id, so we need to set the submission id based on that
          submissionToUpdate._id = res.data.id;
        } else {
          // otherwise, use the existing id
          submissionToUpdate._id = selectedSubmission.submission._id;
        }
        props.submissionUpdate(email, submissionToUpdate, false);
      }
      /* run the form submitted logic here instead of in the hubspot form 
      because the hubspot onFormSubmit and onFormSubmitted event run concurrently,
      so any error would fail silently.*/
      if (props.onFormSubmitted) {
        props.onFormSubmitted();
      }
      submitting = false;
      // should the send email funciton be inside of the if statement?
      sendSubmissionConfirmationEmail(
        order ? order.groupOrderId : selectedPackage.groupOrderId,
        [email],
        order.name,
        order.storeLogo,
        order.storeUrl,
        submission.selectedPackage,
        submission.address.firstName,
        submission.address.lastName,
        submission.address.company,
        submission.address.address1,
        submission.address.city,
        submission.address.state,
        submission.address.country,
        submission.address.zip,
        submission.address.phone,
        submission.selectedStyle,
        submission.size,
        submission.selectedArtwork,
        artworkFileName,
        submission.address.address2,
        memberId
      );
    })
      .catch((err: unknown) => {
        console.error(err);
        const errorMessage = document.getElementById("error-message") as HTMLElement;
        if (errorMessage) {
          errorMessage.innerHTML = `
                An error occurred adding the submission, please try again or contact support 
                if the issue persists.`;
        } else {
          alert(`
                An error occurred adding the submission, please try again or contact support 
                if the issue persists.`
          );
        }
      })
  }

  const handleSubmissionDeletion = () => {
    axios.all([
      axios.post('/.netlify/functions/deleteSubmission', { submissionId: selectedSubmission.id }),
      axios.post('/.netlify/functions/updateMember', { submissionId: selectedSubmission.id })
    ]);
    if (props.onCloseModal) {
      props.onCloseModal();
    }
    if (props.submissionUpdate) {
      props.submissionUpdate(selectedSubmission.email, {} as ISubmissionProps, true);
    }
  }

  return <>
    <style>
      {`
                #label-email-${formId}_1,
                #label-firstname-${formId}_1, 
                #label-lastname-${formId}_1, 
                #label-company-${formId}_1, 
                #label-address-${formId}_1, 
                #label-apt__suite__etc-${formId}_1,
                #label-city-${formId}_1,
                #label-${SHIPPING_STATE_FIELD_KEY}-${formId}_1,
                #label-zip-${formId}_1,
                #label-phone-${formId}_1 {
                    font-size: 14px;
                    line-height: 16px;
                    color: rgba(75, 75, 85, 0.5);
                    font-style: normal;
                    font-weight: 500;
                    position: absolute;
                    top: 14px;
                    left: 15px;
                }

                #newsletter_subscription-${formId}_1 {
                  position: absolute;
                  left: -300px;
                  opacity: 0.01;
                  box-shadow: none;
                  border-color: #bbb;
                  background-color: #f00;
                  color: #f00;
                }

                #newsletter_subscription-${formId}_1 + span {
                  position: relative;
                  padding-left: 2.3em;
                  font-size: 1.05em;
                  line-height: 1.7;
                  cursor: pointer;
                }

                /* checkbox aspect */
                #newsletter_subscription-${formId}_1 + span:before {
                  content: '';
                  position: absolute;
                  left: 0;
                  top: 0;
                  width: 20px;
                  height: 20px;
                  border: 1px solid #aaa;
                  background: #FFF;
                  border-radius: 6px;
                  box-shadow: inset 0 1px 3px rgba(0,0,0, .1), 0 0 0 rgba(203, 34, 237, .2);
                }

                /* checked mark aspect */
                #newsletter_subscription-${formId}_1 + span:after {
                  content: '⅃';
                  position: absolute;
                  top: 8px;
                  left: 5px;
                  font-size: 16px;
                  color: #FF671D;
                  line-height: 0;
                  transform: rotate(45deg);
                }

                /* checked mark aspect changes */
                #newsletter_subscription-${formId}_1:not(:checked) + span:after {
                  opacity: 0;
                }

                #newsletter_subscription-${formId}_1:checked + span:after {
                  opacity: 1;
                }
                
                #label-${SHIPPING_COUNTRY_FIELD_KEY}-${formId}_1,
                #label-${SHIPPING_STATE_FIELD_KEY}-${formId}_1{
                    position: absolute;
                    top: 0;
                    font-weight: 500;
                    font-size: 11px;
                    line-height: 13px;
                    color: rgba(75, 75, 85, 0.5);
                    margin-top: 7px;
                }
                #label-${SHIPPING_COUNTRY_FIELD_KEY}-${formId}_1 {
                    margin-left: 15px;
                }

                #label-select-package-dropdown{
                  position: absolute;
                  top: 35px;
                  font-weight: 500;
                  font-size: 11px;
                  line-height: 13px;
                  color: rgba(75, 75, 85, 0.5);
                  margin-left: 15px;
                  margin-top: 7px;
                }

                .shipping-form-container form label#label-selected_package-${formId}_1 {
                    display: inline-block;
                    font-weight: bold;
                    font-size: 14px;
                    margin-bottom: 12px;
                }

                #selected_package-${formId}_1 {
                    padding-bottom: 0;
                }

                @media screen and (max-width: 768px) {
                    #label-email-${formId}_1,
                    #label-firstname-${formId}_1, 
                    #label-lastname-${formId}_1, 
                    #label-company-${formId}_1, 
                    #label-address-${formId}_1, 
                    #label-apt__suite__etc-${formId}_1,
                    #label-city-${formId}_1,
                    #label-${SHIPPING_STATE_FIELD_KEY}-${formId}_1,
                    #label-zip-${formId}_1,
                    #label-phone-${formId}_1 {
                        font-size: 12px;
                    }
                }
                
           `}
    </style>
    <Card style={{ minHeight: "420px", minWidth: "420px", maxWidth: "420px" }}>
      {loading &&
        <Loading isLoading={true} />
      }
      { /* HERE:SOL-158 */ }
      <div className={`shipping-form-container ${loading ? "loading" : ""}`}>
        <h2>Your Group Order Details</h2>
        <HubspotForm
          formId={formId}
          hubspotPortalId={portalId}
          indexId="1"
          onValidate={handleValidateGOTShippingForm}
          onFormReady={onFormReady}
          onFormSubmit={onFormSubmit}
          readOnly={props.readOnly} />
        
        {!props.readOnly && order && selectedSubmission && selectedSubmission.id && (
          <div className="px-30 -mt-2.5 mb-5">
            <CTA
              size="standard"
              type="secondary"
              onClick={() => { handleSubmissionDeletion() }}
              stretch={true}
            >
              Delete Submission
            </CTA>
          </div>
        )}
      </div>
    </Card>
  </>
}