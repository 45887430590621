import axios from "axios";
import React from "react";
import { useState, useEffect } from "react";
import { MdHelpOutline, MdClear, MdErrorOutline, MdAddToPhotos } from "react-icons/md";
import { toast } from "react-toastify";
import { cloudinaryApplyWidthHeight } from "@merchstores/shared/components/Cloudinary";
import { Tooltip } from "../Tooltip";
import { ICloudinaryFileInputProps } from "./index";
import "./styles.scss";

export const CloudinaryFileInput = (props: ICloudinaryFileInputProps) => {

  const [, setDummyState] = useState(undefined);
  const [fileInput, setFileInput] = useState(undefined);
  const [fileInput1, setFileInput1] = useState(undefined);
  const [fileInput2, setFileInput2] = useState(undefined);
  const [fileInput3, setFileInput3] = useState(undefined);

  useEffect(() => {
    setFileInput(document.getElementById(`${props.name}-hidden-input`));
    setFileInput1(document.getElementById(`${props.name}-1-hidden-input`));
    setFileInput2(document.getElementById(`${props.name}-2-hidden-input`));
    setFileInput3(document.getElementById(`${props.name}-3-hidden-input`));
  }, [fileInput, fileInput1, fileInput2, fileInput3, props.name])

  const handleFileDelete = (index: number) => {
    props.content.splice(index, 1);
    props.updateContent(props.content);
    setDummyState({});
  }
  const handleFileInput = async (e: any) => {
    if (e.target.files && e.target.files[0]) {
      if (e.target.files.length) {
        const formData = new FormData();
        formData.append("file", e.target.files[0]);
        formData.append("upload_preset", props.cloudinarySource ? props.cloudinarySource : "group-order-tool");
        axios.post("https://api.cloudinary.com/v1_1/merchology/image/upload", formData)
          .then(resp => {
            props.content.push(resp.data.secure_url);
            props.updateContent(props.content);
            setDummyState({});
            if (fileInput){ 
              fileInput.removeEventListener("change", handleFileInput);
              fileInput.value = null;
            }
          })
          .catch(err => {
            console.error(err);
            toast.error('An error occured uploading your image, please try again.');
            if (fileInput){ 
              fileInput.removeEventListener("change", handleFileInput);
              fileInput.value = null;
            }
          })
      }
    }
  }

  const maxFiles = props.content.length === props.maxFiles;
  return <>
    <div className={`flex flex-col w-full mb-5 ${props.classes ? props.classes : ''} cloudinary-file-container ${props.disabled ? "opacity-50" : ""}`}>
      <div className="flex justify-between mb-2">
        <div className="flex items-center">
          <label htmlFor={props.name} className="text-sm text-merch-dark-gray font-bold">{props.label}</label>
			{props.helpText2 && (
			<div className="ml-1 text-xs text-merch-dark-gray opacity-50">{props.helpText2}</div>
			)}
          {props.tooltip && (
            <Tooltip content={props.tooltip}>
              <div className="opacity-50 ml-2">
                <MdHelpOutline />
              </div>
            </Tooltip>
          )}
        </div>
        {props.helpText && (
          <div className="text-xs italic text-merch-dark-gray opacity-50 mr-3">{props.helpText}</div>
        )}
      </div>

      {props.type === "one" ? (
        <div 
          className={
            `bg-white text-sm font-medium group-order-form-select py-3 border 
            ${props.error ? "border-error-red" : "border-gray-300"} rounded-md 
            relative ${maxFiles ? "cursor-default" : "cursor-pointer"}`
          }
        >
          <input 
            type="file" 
            accept="image/png, image/jpeg, image/svg" 
            id={`${props.name}-hidden-input`} 
            name={props.name} 
            className="hidden bg-right-top group-order-form-select p-3 border border-gray-300 rounded-md"
          />
          <div>
            <div>
              <div className={`${props.name}-select-item px-3 w-full flex justify-between items-center`} onClick={() => {
                if (fileInput && !maxFiles && !props.disabled) {
                  fileInput.addEventListener("change", handleFileInput)
                  fileInput.click();
                }
              }}>
                <div className="opacity-50">
                  {props.placeholder}
                </div>
                <div className={`text-xs ${maxFiles ? "text-merch-dark-gray opacity-50" : "text-merch-orange"} font-bold`}>Add File</div>
              </div>
            </div>
          </div>
        </div>
      ) :
        (
          <div className="flex justify-around">
            <input 
              type="file" 
              accept="image/png, image/jpeg, image/svg" 
              id={`${props.name}-1-hidden-input`} 
              name={`${props.name}-1`} 
              className="hidden bg-right-top group-order-form-select p-3 border border-gray-300 rounded-md" 
              onChange={() => handleFileInput}
            />
            <input 
              type="file" 
              accept="image/png, image/jpeg, image/svg" 
              id={`${props.name}-2-hidden-input`} 
              name={`${props.name}-2`} 
              className="hidden bg-right-top group-order-form-select p-3 border border-gray-300 rounded-md" 
              onChange={() => handleFileInput}
            />
            <input 
              type="file" 
              accept="image/png, image/jpeg, image/svg" 
              id={`${props.name}-3-hidden-input`} 
              name={`${props.name}-3`} 
              className="hidden bg-right-top group-order-form-select p-3 border border-gray-300 rounded-md" 
              onChange={() => handleFileInput}
            />
            <div 
              className={
                `w-100 h-100 sm:w-150 sm:h-150 bg-white text-sm font-medium 
                group-order-form-select py-3 border border-gray-300 rounded-md 
                relative ${maxFiles ? "cursor-default" : "cursor-pointer"}`
              }
            >
              <div className={`${props.name}-select-item w-full h-full flex justify-between items-center image-loader-container`} onClick={() => {
                if (!(props.content && props.content[0]) && fileInput1 && !maxFiles && !props.disabled) {
                  fileInput1.addEventListener("change", handleFileInput)
                  fileInput1.click();
                }
              }}>
                <div className={`${props.content && props.content[0] ? '' : 'm-auto'} w-100 sm:w-150 flex justify-center items-center h-full`}>
                  {props.content && props.content[0] ? 
                    (<img className="rounded-md p-0.5 h-auto" 
                    src={cloudinaryApplyWidthHeight(props.content[0], 150, 150)} 
                    alt="Package 1"></img>) : <div className="opacity-50"><MdAddToPhotos /></div>
                  }
                </div>
              </div>
              {props.content && props.content[0] ? (
                <div className="rounded-full bg-gray-200 text-12 absolute top-2 right-2" 
                onClick={() => { handleFileDelete(0) }}><MdClear /></div>
              ) : ''}
            </div>
            <div 
              className={`w-100 h-100 sm:w-150 sm:h-150 bg-white text-sm font-medium 
                group-order-form-select py-3 border border-gray-300 rounded-md relative 
                ${maxFiles ? "cursor-default" : "cursor-pointer"}`
              }>
              <div className={`${props.name}-select-item w-full h-full flex justify-between items-center image-loader-container`} onClick={() => {
                if (!(props.content && props.content[1]) && fileInput2 && !maxFiles && !props.disabled) {
                  fileInput2.addEventListener("change", handleFileInput)
                  fileInput2.click();
                }
              }}>
                <div className={`${props.content && props.content[1] ? '' : 'm-auto'} w-100 sm:w-150 flex justify-center items-center h-full`}>
                  {props.content && props.content[1] ? (
                    <img className="rounded-md p-0.5 h-auto" 
                    src={cloudinaryApplyWidthHeight(props.content[1], 150, 150)} 
                    alt="Package 2"></img>
                  ) : <div className="opacity-50"><MdAddToPhotos /> </div>}
                </div>
              </div>
              {props.content && props.content[1] ? (
                <div className="rounded-full bg-gray-200 text-12 absolute top-2 right-2" 
                onClick={() => { handleFileDelete(1) }}><MdClear /></div>
              ) : ''}
            </div>
            <div 
              className={`w-100 h-100 sm:w-150 sm:h-150 bg-white text-sm font-medium 
                group-order-form-select py-3 border border-gray-300 rounded-md relative 
                ${maxFiles ? "cursor-default" : "cursor-pointer"}`
              }
            >
              <div className={`${props.name}-select-item w-full h-full flex justify-between items-center image-loader-container`} onClick={() => {
                if (!(props.content && props.content[2]) && fileInput3 && !maxFiles && !props.disabled) {
                  fileInput3.addEventListener("change", handleFileInput)
                  fileInput3.click();
                }
              }}>
                <div className={`${props.content && props.content[2] ? '' : 'm-auto'} w-100 sm:w-150 flex justify-center items-center h-full`}>
                  {props.content && props.content[2] ? (
                    <img 
                      className="rounded-md p-0.5 h-auto" 
                      src={cloudinaryApplyWidthHeight(props.content[2], 150, 150)} 
                      alt="Package 3">
                    </img>
                  ) : <div className="opacity-50"><MdAddToPhotos /> </div>}
                </div>
              </div>
              {props.content && props.content[2] ? (
                <div 
                  className="rounded-full bg-gray-200 text-12 absolute top-2 right-2" 
                  onClick={() => { handleFileDelete(2) }}><MdClear /></div>
              ) : ''}
            </div>
          </div>
        )}
      {props.content.map((file: any, index: number) => {
        return (
          <React.Fragment key={`${props.name}-file-item-${index}`}>
            {props.type === "one" && (
              <div className="flex items-center mt-1 justify-between">
                <div className="flex items-center">
                  <div className="flex items-center h-7 w-7 mr-2">
                    { file && <img src={cloudinaryApplyWidthHeight(file, 28, 28)} alt={file.split('/').pop()}></img>}
                  </div>
                  <div className="font-medium text-xs">
                  { file && file.split('/').pop() }
                  </div>
                </div>
                <div className="opacity-50" onClick={() => { 
                    if (!props.disabled) {
                        handleFileDelete(index)
                    }
                }}>
                  <MdClear />
                </div>
              </div>
            )}
          </React.Fragment>
        )
      })}
      {props.error &&
        <div className="flex items-center">
          {props.error && <span className="error">{props.label} is required</span>}
          <div className="mt-1 ml-1 opacity-50">
            <MdErrorOutline />
          </div>
        </div>}
    </div>
  </>
}