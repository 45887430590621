import { useState } from "react";
import { isMobile } from 'react-device-detect';
import { MdAdd } from "react-icons/md";
import { IMerchStore } from "@merchstores/admin/components/MerchStore";
import { ButtonType, CTA } from "@merchstores/shared/elements/Cta";
import { GiftCardModal } from "../../MerchStoreGiftCards";

interface Props {
    merchStore: IMerchStore;
    type?: ButtonType;
}

const BuyGiftCardButton = (props: Props): JSX.Element => {
    const { merchStore, type = 'tertiary' } = props;
    const [isBuyGiftCardModalOpen, setIsBuyGiftCardModalOpen] = useState(false);

    const toggleBuyGiftCardsModal = () => {
        setIsBuyGiftCardModalOpen(!isBuyGiftCardModalOpen);
    }

    return (
        <>
            <CTA
                type={type}
                size='standard'
                icon={<MdAdd size="20px" />}
                onClick={toggleBuyGiftCardsModal}
            >
                {isMobile ? "" : "Buy Gift Cards"}
            </CTA>
            <GiftCardModal
                isMobile={isMobile}
                merchStore={merchStore}
                open={isBuyGiftCardModalOpen}
                storeCode={merchStore.storeCode}
                storeSubdomain={merchStore.subdomain}
                onClose={toggleBuyGiftCardsModal}
            />
        </>
    );
};

export default BuyGiftCardButton;