import axios from "axios";
import { GET_SHOPIFY_ORDERS, GET_SHOPIFY_ORDER_BY_ID } from "./routes";
import {
  IFetchShopifyOrderParams,
  IShopifyFetchOrderData,
  IShopifyOrdersParams,
  IShopifyOrdersData,
} from "../types/shopifyOrder";

const fetchShopifyOrders = async (
  params: IShopifyOrdersParams
): Promise<IShopifyOrdersData> => {
  if (!params.storeCode) {
    throw new Error("storeCode parameter is required");
  }
  const result = await axios.post(GET_SHOPIFY_ORDERS, params);
  return result.data;
};

const fetchShopifyOrder = async (
  params: IFetchShopifyOrderParams
): Promise<IShopifyFetchOrderData> => {
  if (!params.storeCode) {
    throw new Error("storeCode parameter is required");
  }
  const response = await axios.post(GET_SHOPIFY_ORDER_BY_ID, params);
  return response.data;
};

export { fetchShopifyOrders, fetchShopifyOrder };
