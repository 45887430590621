import Modal from 'react-responsive-modal';

import { Formik, FormikHelpers, FormikProps } from 'formik';
import { useEffect, useState, useContext } from 'react';
import { MdDelete } from 'react-icons/md';

import { GroupOrderAddress, HubsportCountry, HubsportState } from '@merchstores/admin/types/address';
import { DeleteShippingAddressModal } from './DeleteShippingAddressModal';
import { FormSelect } from '@merchstores/shared/elements/FormSelect';
import { FormikInput } from '@merchstores/shared/elements/Formik/FormikInput';
import { Checkbox } from '@merchstores/shared/elements/Checkbox';
import { StoreContext } from '@merchstores/admin/context/Store';
import { CTA } from '@merchstores/shared/elements/Cta';

import useAddressCountriesAndStates from '@merchstores/admin/hooks/address/useAddressCountriesAndStates';
import useUpdateShippingAddress from '@merchstores/admin/hooks/address/useUpdateShippingAddress';
import useAddShippingAddress from '@merchstores/admin/hooks/address/useAddShippingAddress';

import '@merchstores/admin/components/AdminModal/AdminModal.scss';

export interface Props {
    isOpen: boolean,
    groupOrderId: string;
    setIsOpen: (isOpen: boolean) => void;
}

const DEFAULT_TOOLTIP_TEXT = 'This address will be the default shipping address selection in the ' +
    'member package selection and shipping. This is only the default option, the member will be ' +
    'able to select any location from the existing list.';

const ENABLE_STATE_COUNTRY = 'United States';

function getCtaButtonTexts(isEdit: boolean) {
    return isEdit ?
        { default: 'Update Address', loading: 'Updating...' } :
        { default: 'Add Address', loading: 'Saving...' };
}

function buildCountriesOptions(countries: Array<HubsportCountry>) {
    return countries?.map((country) => (
        { value: country.value, displayText: country.label }
    ));
}

function buildStatesOptions(states: Array<HubsportState>) {
    return states?.map((country) => (
        { value: country.value, displayText: country.label }
    ));
}

function validate(values: GroupOrderAddress) {
    const errors: Record<string, string> = {};
    const requiredFieldMap = new Map([
        ['locationDisplayName', 'Location Name'],
        ['company', 'Company'],
        ['address1', 'Street Address'],
        ['phone', 'Phone'],
        ['zip', 'Zip'],
        ['city', 'City'],
        ['state', 'State'],
        ['country', 'Country'],
    ]);
    requiredFieldMap.forEach((value, key) => {
        if (
            !values[key as keyof GroupOrderAddress] &&
            (key !== 'state' || key === 'state' && values.country === ENABLE_STATE_COUNTRY)
        ) {
            errors[key] = `${value} is required`;
        }
    });
    return errors;
}

export function ShippingAddressModal(props: Props): JSX.Element {
    const { isOpen, groupOrderId, setIsOpen } = props;
    const { data: addressData } = useAddressCountriesAndStates();
    const { state: { selectedShippingAddress: address } } = useContext(StoreContext);

    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [currentAddress, setCurrentAddress] = useState({});

    const {
        isLoading: addShippingAddressLoading,
        mutate: addShippingAddress,
    } = useAddShippingAddress();

    const {
        isLoading: updateShippingAddressLoading,
        mutate: updateShippingAddress,
    } = useUpdateShippingAddress();

    const isLoading = !!(updateShippingAddressLoading || addShippingAddressLoading);
    const countries = buildCountriesOptions(addressData?.countries || [])
    const states = buildStatesOptions(addressData?.states || [])
    const ctaTexts = getCtaButtonTexts(!!address);

    function onCloseModal() {
        setIsOpen(false);
    }

    function handleFormSubmit(
        values: GroupOrderAddress,
        formikHelpers: FormikHelpers<GroupOrderAddress>
    ) {
        formikHelpers.setSubmitting(true);
        if (currentAddress) {
            updateShippingAddress({ ...currentAddress, ...values });
        } else {
            addShippingAddress({ ...values, groupOrderId, isDeleted: false });
        }
        formikHelpers.setSubmitting(false);
        formikHelpers.resetForm();
        setIsOpen(false);
    }

    function handleRemoveShippingAddress() {
        setIsDeleteModalOpen(true);
        setIsOpen(false);
    }

    useEffect(() => {
        if (props.isOpen !== isOpen) {
            setIsOpen(props.isOpen);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isOpen]);

    useEffect(() => {
        setCurrentAddress(address)
    }, [address]);

    return <>
        <Modal
            center
            open={isOpen}
            onClose={onCloseModal}
            classNames={{ modal: 'admin-modal' }}
        >
            <p className='text-xl font-bold pb-7'>
                {currentAddress ? 'Edit' : 'Add'} Address
            </p>
            <Formik
                validate={validate}
                onSubmit={handleFormSubmit}
                initialValues={currentAddress || {}}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleSubmit,
                    handleChange,
                    isSubmitting,
                    setFieldValue,
                }: FormikProps<GroupOrderAddress>) => (
                    <form onSubmit={handleSubmit}>
                        <FormikInput
                            type='text'
                            id='company'
                            name='company'
                            label='Company'
                            value={values?.company}
                            error={touched?.company ? errors?.company : null}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            defaultValue={values?.company}
                        />
                        <FormikInput
                            type='text'
                            id='locationDisplayName'
                            name='locationDisplayName'
                            label='Location Name'
                            value={values?.locationDisplayName}
                            error={touched?.locationDisplayName ? errors?.locationDisplayName : null}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            defaultValue={values?.locationDisplayName}
                        />
                        <FormikInput
                            type='text'
                            id='address1'
                            name='address1'
                            label='Street Address'
                            value={values?.address1}
                            error={touched?.address1 ? errors?.address1 : null}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            defaultValue={values?.address1}
                        />
                        <FormikInput
                            type='text'
                            id='address2'
                            name='address2'
                            label='Apt, Suite, etc.'
                            value={values?.address2}
                            error={touched?.address2 ? errors?.address2 : null}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            defaultValue={values?.address2}
                        />
                        <FormikInput
                            type='text'
                            id='city'
                            name='city'
                            label='City'
                            value={values?.city}
                            error={touched?.city ? errors?.city : null}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            defaultValue={values?.city}
                        />
                        <FormSelect
                            label='Country'
                            name='country'
                            errors={touched?.country ? { state: { message: errors?.country } } : {}}
                            register={handleChange}
                            setValue={(field: string, value: string) => {
                                setFieldValue(field, value);
                                if (value !== ENABLE_STATE_COUNTRY) {
                                    setFieldValue('state', null);
                                }
                            }}
                            options={countries || []}
                            selectStyle='two'
                            classes='mb-5'
                            default={values?.country}
                        />
                        {values?.country === ENABLE_STATE_COUNTRY &&
                            <FormSelect
                                label='State'
                                name='state'
                                errors={touched?.state ? { state: { message: errors?.state } } : {}}
                                register={handleChange}
                                setValue={(field: string, value: string) => {
                                    setFieldValue(field, value)
                                }}
                                options={states || []}
                                selectStyle='two'
                                classes='mb-5'
                                default={values?.state}
                                disabled={values?.country !== ENABLE_STATE_COUNTRY}
                            />
                        }
                        <FormikInput
                            type='zip'
                            id='zip'
                            name='zip'
                            label='Postal Code'
                            value={values?.zip}
                            error={touched?.zip ? errors?.zip : null}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            defaultValue={values?.zip}
                        />
                        <FormikInput
                            type='phone'
                            id='phone'
                            name='phone'
                            label='Phone'
                            value={values?.phone}
                            error={touched?.phone ? errors?.phone : null}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            defaultValue={values?.phone}
                        />
                        <Checkbox
                            label='Set as default address'
                            className='items-center my-6'
                            onChange={(value: boolean) => {
                                setFieldValue('isDefault', value)
                            }}
                            tooltip={DEFAULT_TOOLTIP_TEXT}
                            isSelected={values?.isDefault}
                        />
                        <div className='flex'>
                            <CTA
                                type='primary'
                                size='standard'
                                formSubmit={true}
                                classes='flex-1'
                                disabled={isLoading || Object.keys(errors).length > 0}
                            >
                                {isSubmitting ? ctaTexts.loading : ctaTexts.default}
                            </CTA>
                            {!!address && (
                                <CTA
                                    size='small'
                                    type='secondary'
                                    icon={<MdDelete />}
                                    classes='ml-3'
                                    onClick={handleRemoveShippingAddress}
                                />
                            )}
                        </div>
                    </form>
                )}
            </Formik>
        </Modal>
        <DeleteShippingAddressModal
            isOpen={isDeleteModalOpen}
            setIsOpen={setIsDeleteModalOpen}
            shippingAddressId={address?._id || ''}
        />
    </>
}