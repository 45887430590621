
const Metafield = {
  "name": "Metafield",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "createdAt": "DateTime",
    "description": "String",
    "id": "ID",
    "key": "String",
    "namespace": "String",
    "parentResource": "MetafieldParentResource",
    "updatedAt": "DateTime",
    "value": "String",
    "valueType": "MetafieldValueType"
  },
  "implementsNode": true
};
export default Metafield;