
const Page = {
  "name": "Page",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "body": "HTML",
    "bodySummary": "String",
    "createdAt": "DateTime",
    "handle": "String",
    "id": "ID",
    "seo": "SEO",
    "title": "String",
    "updatedAt": "DateTime",
    "url": "URL"
  },
  "implementsNode": true
};
export default Page;