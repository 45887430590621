/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useReducer } from "react";

import { IMerchStoreGiftCardsStatus } from "../types/merchstore";
import { GroupOrderAddress } from "../types/address";
import { useEffect } from "react";

enum StoreAction {
  SET_GIFT_CARDS_STATUS = "setGiftCardsStatus",
  SET_SHIPPING_ADDRESSES = "setShippingAddresses",
  SET_SELECTED_SHIPPING_ADDRESS = "setSelectedShippingAddress",
  SET_IS_SHIPPING_ADDRESSES_LOADING = "setIsShippingAddressesLoading",
}

type State = {
  giftCardsStatus: IMerchStoreGiftCardsStatus | null;
  selectedShippingAddress: GroupOrderAddress | null;
  shippingAddresses: Array<GroupOrderAddress>;
  isShippingAddressesLoading: boolean;
};

type Action = {
  type: "setGiftCardsStatus";
  payload: {
    giftCardsStatus: IMerchStoreGiftCardsStatus;
  };
} |
{
  type: "setShippingAddresses";
  payload: {
    shippingAddresses: Array<GroupOrderAddress>;
  };
} |
{
  type: "setIsShippingAddressesLoading";
  payload: {
    isShippingAddressesLoading: boolean;
  };
} |
{
  type: "setSelectedShippingAddress";
  payload: {
    setSelectedShippingAddress: GroupOrderAddress;
  };
};

interface Props {
  children: JSX.Element
}

const initialState: State = {
  giftCardsStatus: null,
  shippingAddresses: [],
  selectedShippingAddress: null,
  isShippingAddressesLoading: false,
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case StoreAction.SET_GIFT_CARDS_STATUS:
      return { ...state, giftCardsStatus: action.payload.giftCardsStatus };

    case StoreAction.SET_SHIPPING_ADDRESSES:
      return { ...state, shippingAddresses: action.payload.shippingAddresses };

    case StoreAction.SET_SELECTED_SHIPPING_ADDRESS:
      return { ...state, selectedShippingAddress: action.payload.setSelectedShippingAddress };

    case StoreAction.SET_IS_SHIPPING_ADDRESSES_LOADING:
      return { ...state, isShippingAddressesLoading: action.payload.isShippingAddressesLoading };

    default:
      return { ...state };
  }
};

const StoreContext = React.createContext({
  state: initialState,
  dispatch: () => { },
  setGiftCardsStatus: () => { },
  setShippingAddresses: () => { },
  setSelectedShippingAddress: () => { },
});

const StoreContextProvider = (props: Props): JSX.Element => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  const setGiftCardsStatus = (giftCardsStatus: IMerchStoreGiftCardsStatus): void => {
    dispatch({
      type: StoreAction.SET_GIFT_CARDS_STATUS,
      payload: { giftCardsStatus }
    });
  };

  const setShippingAddresses = (shippingAddresses: Array<GroupOrderAddress>): void => {
    dispatch({
      type: StoreAction.SET_SHIPPING_ADDRESSES,
      payload: { shippingAddresses }
    });
  };

  const setSelectedShippingAddress = (setSelectedShippingAddress: GroupOrderAddress): void => {
    dispatch({
      type: StoreAction.SET_SELECTED_SHIPPING_ADDRESS,
      payload: { setSelectedShippingAddress }
    });
  };

  const setIsShippingAddressesLoading = (isShippingAddressesLoading: Array<GroupOrderAddress>): void => {
    dispatch({
      type: StoreAction.SET_IS_SHIPPING_ADDRESSES_LOADING,
      payload: { isShippingAddressesLoading }
    });
  };

  return (
    <StoreContext.Provider
      value={{
        state,
        dispatch,
        setGiftCardsStatus,
        setShippingAddresses,
        setSelectedShippingAddress,
        setIsShippingAddressesLoading
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};

export { StoreContextProvider, StoreContext };
