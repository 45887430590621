import { UseQueryResult, useQuery } from "react-query";
import { useContext } from "react";

import { GroupOrderAddress } from "@merchstores/admin/types/address";
import { StoreContext } from "@merchstores/admin/context/Store";
import { QueryKey } from "@merchstores/admin/types/reactQuery";

import { fetchShippingAddresses } from "../../api/hubspot";


export default function useFetchShippingAddresses(
    groupOrderId: string,
): UseQueryResult<Array<GroupOrderAddress>> {
    const { setShippingAddresses, setIsShippingAddressesLoading } = useContext(StoreContext)
    return useQuery(
        QueryKey.SHIPPING_ADRESSES,
        () => {
            setIsShippingAddressesLoading(true);
            return fetchShippingAddresses(groupOrderId);
        },
        {
            onSuccess(data) {
                setShippingAddresses(data);
                setIsShippingAddressesLoading(false);
                return data;
            },
        }
    );
}
