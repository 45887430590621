import { moneyFormat } from "@merchstores/shared/components/Money";

interface OrderSummary {
  itemsQuantity: number;
  subtotalAmount: string;
  shippingDescription: string;
  shippingAmount: string;
  taxesAmount: string;
  totalAmount: string;
}

interface DetailRowProps {
  title: string;
  value: string;
  description?: string;
}

interface Props {
  summary: OrderSummary;
}

const DetailRow = (props: DetailRowProps) => {
  const { title, description, value } = props;

  return (
    <div className="flex justify-between items-center pb-5">
      <div className="flex">
        <div className="text-sm font-bold mr-2 text-gray-400">{title}</div>
        <div className="text-sm text-gray-400">{description || ''}</div>
      </div>
      <div className="text-sm">{value}</div>
    </div>
  );
};

const PaymentDetails = (props: Props): JSX.Element => {
  const { summary } = props;

  return (
    <div className="bg-gray-50 p-8 lg:min-w-order-sidebar rounded-tr-24 rounded-br-24 border-l" >
      <div className="text-2xl pb-5 font-bold">Payment Details</div>
      <DetailRow
        title="Subtotal:"
        description={`${summary.itemsQuantity} item`}
        value={moneyFormat(summary.subtotalAmount)}
      />
      <DetailRow
        title="Shipping:"
        description={summary.shippingDescription}
        value={moneyFormat(summary.shippingAmount)}
      />
      <DetailRow
        title="Sales Tax:"
        value={moneyFormat(summary.taxesAmount)}
      />
      <div className="flex border-t pt-5 justify-between items-center">
        <div className="text-sm font-bold">Total:</div>
        <div className="text-lg font-bold">{moneyFormat(summary.totalAmount)}</div>
      </div>
    </div>
  );
};

export default PaymentDetails;