export interface OrderStatusOption {
    value: string;
    displayText: string;
}

export const draftOrderOption = {
    value: "draft", 
    displayText: "Draft"
}

export const activeOrderOption = {
    value: "active", 
    displayText: "Active"
}

export const closeOrderOption = {
    value: "closed", 
    displayText: "Closed"
}


export function newOrderStatusOptions(): OrderStatusOption[]
{
    return [
        draftOrderOption, 
        activeOrderOption,
        closeOrderOption
    ];
}

export function buildOrderStatusOptions(orderStatus : string): OrderStatusOption[]
{
    const isActiveOrClosed = ['active', 'closed'].includes(orderStatus);

    if (isActiveOrClosed)
    {
        return [
            activeOrderOption, 
            closeOrderOption
        ];
    }

    return [
        draftOrderOption, 
        activeOrderOption,
        closeOrderOption
    ]
}

