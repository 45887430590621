import { useState } from "react";
import { Link } from "react-router-dom";
import { Card } from "@merchstores/shared/elements/Card";
import { FormInput } from "@merchstores/shared/elements/FormInput";
import { LoginHeader } from "../LoginHeader";
import { useForm, useWatch } from "react-hook-form";
import { storefrontClient } from "../../../App";
import { recoverPasswordMutation } from "../../../queries/storefront-graphql";
import { useEffect } from "react";
import { CTA } from "@merchstores/shared/elements/Cta";

export const RecoverPassword = () => {

    const [passwordSent, setPasswordSent] = useState(false);
    const [formDisabled, setFormDisabled] = useState(true);


    const { register, handleSubmit, setError, formState: { errors }, reset, control } = useForm();
    const email = useWatch({
        control,
        name: "email",
        defaultValue: ""
    });

    useEffect(() => {
        if (email !== "") {
            setFormDisabled(false);
        } else {
            setFormDisabled(true);
        }
    }, [email]);

    const onSubmit = (ev: any) => {
        recoverPassword(ev.email);
    }

    const recoverPassword = (email: string) => {
        storefrontClient.send(recoverPasswordMutation(email))
            .then(({ data }: any) => {
                if (data.customerRecover && data.customerRecover.customerUserErrors.length) {
                    setError("email", { type: "manual", message: "Please enter a valid email address" });
                } else {
                    reset();
                    setPasswordSent(true);
                }
            })
    }

    return (
        <div className="w-full h-full flex justify-center items-center ">
            <div className="flex justify-center items-center login-wrapper password-recover">
                <Card>
                    <LoginHeader />
                    <div className="login-body">
                        <h3 className="mb-4 font-bold">Recover Password</h3>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="flex flex-col grid grid-flow-row auto-rows-max gap-30">
                                <FormInput name="email" type="email" register={register} errors={errors} label="Email" required={true} />
                                <div className="flex w-full">
                                    <CTA size="standard" type="primary" formSubmit={true} classes="w-full h-11" disabled={formDisabled}>Submit</CTA>
                                </div>
                                {passwordSent && <span className="success">Please check your email to reset your password</span>}
                            </div>
                            <div className="flex w-full justify-center mt-24">
                                <Link to="/login">Sign in</Link>
                            </div>
                        </form>
                    </div>
                </Card>
            </div>
        </div>

    )
}