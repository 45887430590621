/** 
 * this email validation leverages the built in email validation of the HTML5 email input type, with a fallback to
 * a very basic email regex. It's not bulletproof, but if it wouldn't pass the email input's validation,
 *  it's good enough for our use case 
 */

export function isValidEmail(email: string): boolean {
  const input = document.createElement('input');
  input.type = 'email';
  input.required = true;
  input.value = email;
  return typeof input.checkValidity === 'function' ? input.checkValidity() : /\S+@\S+\.\S+/.test(email);
}
