import axios from "axios";
import { useState, useEffect, useContext } from "react";
import { Context } from "../../context";
import { BigNumber } from "bignumber.js";
import { IMerchStoreOrder } from "@merchstores/admin/types/merchstoreOrder";
import { IVariantQuantityDataEntry } from "@merchstores/admin/types/shopifyIndividualOrder";
import { getLocalDateFullMonthText } from "@merchstores/admin/components/DateTime";
import { moneyFormat } from "@merchstores/shared/components/Money";
import { userHasRole, Role, ROLE_SUPERUSER, ROLE_MERCHOLOGIST } from "@merchstores/admin/context/Roles";
import { CTA } from "@merchstores/shared/elements/Cta";
import "./styles.scss";
import { TrackingLink } from "./TrackingLink";
import { IShopifyOrder } from "@merchstores/admin/types/shopifyOrder";
import { toast } from 'react-toastify';

export interface IOrderDetailsSummaryProps {
  order: IMerchStoreOrder;
  individualOrder: IShopifyOrder;
  userRole: Role;
  closeDate: string;
  updateOrderDetails: () => void;
  variantQuantityEditActive: boolean;
  variantQuantityData: IVariantQuantityDataEntry[];
  canEditOrder: boolean;
  trackingCode?: string;
}

interface ILineItem {
  price:string, 
  quantity: number
}

interface ISummarizeResult {
  itemCount: number,
  subtotal: BigNumber,
  tax: BigNumber,
  total: BigNumber
}

function summarizeLineItems(
  lineItems: ILineItem[], 
  shipping: string, 
  taxRate: string
): ISummarizeResult {
  let itemCount = 0;
  let subtotal = new BigNumber("0");
  const currentShipping = new BigNumber(shipping);
  const currentTax = new BigNumber(taxRate).dividedBy(100);

  lineItems.forEach( (lineItem: ILineItem) => {
    itemCount += lineItem.quantity;
    const linePrice = new BigNumber(lineItem.price);
    const lineSubtotal = linePrice.multipliedBy(lineItem.quantity)
    subtotal = subtotal.plus(lineSubtotal);
  })
  const tax = subtotal.plus(currentShipping).times(currentTax)
  const total = subtotal.plus(currentShipping).plus(tax);

  return {
    itemCount,
    subtotal,
    tax,
    total
  }
}

function onClickCancelOrder(order: IMerchStoreOrder) {
  const cancelConfirmation = window.confirm("Are you sure you want to cancel this order?");
  if (cancelConfirmation) {
    axios.post('/.netlify/functions/cancelMerchStoreOrder', {
      orderId: order.id
    })
      .then(res => {
        if (res?.status === 200) {
          window.location.reload();
          toast.success('Order cancelled.');
        } else {
          toast.error('Error cancelling order.');
        }
      })
      .catch(err => {
        alert(`An error ocurred cancelling the order, please try again.`);
        console.error('An error ocurred cancelling the order: ', err);
      })
  }
}

export const OrderDetailsSummary: React.FC<IOrderDetailsSummaryProps> = (props: IOrderDetailsSummaryProps) => {
  const {
    order,
    individualOrder,
    closeDate,
    updateOrderDetails,
    variantQuantityEditActive,
    variantQuantityData,
    canEditOrder,
    trackingCode
  } = props;

  const [pendingProcessing, setPendingProcessing] = useState(false);
  const [taxRate, setTaxRate] = useState("")
  const [itemCount, setItemCount] = useState("");
  const [subtotal, setSubtotal] = useState("");
  const [total, setTotal] = useState("");
  const [shipping, setShipping] = useState("");
  const [tax, setTax] = useState("");
  const { userRole } = useContext(Context);
  const userHasCancelPerms = userHasRole(new Role(userRole), [ROLE_SUPERUSER, ROLE_MERCHOLOGIST]);

  useEffect(() => {
    const summarizeResult = summarizeLineItems(variantQuantityData, shipping, taxRate);

    setTotal(summarizeResult.total);
    setSubtotal(summarizeResult.subtotal.toFormat(2));
    setTax(summarizeResult.tax.toFormat(2));
    setItemCount(summarizeResult.itemCount);

    if (individualOrder && individualOrder.currentTotalPriceSet) {
      const orderSubtotalPrice = new BigNumber(
        individualOrder.currentSubtotalPriceSet?.presentmentMoney.amount ?? '0.0'
      );

      setSubtotal(orderSubtotalPrice.toString());

      const orderTotalShipping = new BigNumber(
        individualOrder.totalShippingPriceSet?.presentmentMoney.amount ?? '0.0'
      );

      setShipping(orderTotalShipping.toString());

      const orderTotalTax = new BigNumber(
        individualOrder.currentTotalTaxSet?.presentmentMoney.amount ?? '0.0'
      );

      setTax(orderTotalTax.toString());
      const priceBeforeTax = orderSubtotalPrice.plus(orderTotalShipping);
      const taxRate = orderTotalTax.dividedBy(priceBeforeTax).multipliedBy(100).toString()
      setTaxRate(taxRate);
      setTotal(individualOrder.currentTotalPriceSet?.presentmentMoney.amount)
      let itemTotal = 0;

      const lineItems = individualOrder?.lineItems ?? [];

      lineItems.forEach((lineItem: { currentQuantity: number }) => {
        itemTotal += lineItem.currentQuantity;
      })

      setItemCount(itemTotal);
    }

    const pendingProcessingTag = individualOrder.tags.find(tag => tag === "close_date=pending")

    if (pendingProcessingTag)
    {
      setPendingProcessing(true);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [individualOrder])

  return (
    <div
      // eslint-disable-next-line max-len
      className="order-details-sidebar-menu p-8 bg-gray-50 lg:min-w-order-sidebar flex flex-col items-start rounded-tr-24 rounded-br-24"
    >
      <div className="w-full">
        { individualOrder.cancelledAt ?
          null : 
        <>
          <div className="w-full text-sm text-merch-dark-gray font-bold mb-5">
            <div className='title w-full pb-20'>Payment Details</div>
          </div>
          <div className='flex grid grid-cols-3 gap-4 pb-30 mb-30 border-b item-text'>
            <div className='font-bold'>Subtotal:</div>
            <div>{ itemCount ? `${itemCount} Items` : ``}</div>
            <div className='text-right item-value'>${subtotal}</div>
            <div className='font-bold'>Shipping:</div>
            <div>{order.shippingTitle}</div>
            <div className='text-right item-value'>${shipping}</div>
            <div className='font-bold'>Sales Tax:</div>
            <div></div>
            <div className='text-right item-value'>${tax}</div>
          </div>
          <div className='flex justify-between mb-25 item-text item-value font-bold'>
            <div>Total:</div>
            <div className='text-lg'>{ moneyFormat(total) }</div>
          </div>
        </>}
        {canEditOrder && (
          <div className="flex w-full">
            <CTA
              disabled={!variantQuantityEditActive}
              formSubmit={false}
              size="standard"
              type="primary"
              classes='w-full'
              onClick={updateOrderDetails}>
              Update Order
            </CTA>
          </div>
        )}

        { trackingCode &&
          <TrackingLink trackingCode={trackingCode}/>
        }

        <div className='flex justify-center flex-col text-center mt-20'>
          { closeDate && !pendingProcessing &&
            <div className='item-text mb-2'>
              This order was placed on:
              <div className='text-lg font-bold item-value mt-2'>
                {getLocalDateFullMonthText(closeDate)}
              </div>
            </div>
          }

          { !individualOrder.cancelledAt && closeDate && pendingProcessing &&
            <div className='item-text mb-2'>
              This order will process automatically on:
              <div className='text-lg font-bold item-value mt-2'>
                {getLocalDateFullMonthText(closeDate)}
              </div>
            </div>
          }

          { !individualOrder.cancelledAt && closeDate && pendingProcessing && userHasCancelPerms &&
            <CTA
              formSubmit={false}
              size="standard"
              type="secondary"
              classes='w-full mt-3'
              onClick={() => onClickCancelOrder(order)}>
              Cancel Order
            </CTA>
          }

          { individualOrder.cancelledAt &&
            <div className='item-text mb-2'>
              This order was cancelled on:
              <div className='text-lg font-bold item-value mt-2'>
                {getLocalDateFullMonthText(individualOrder.cancelledAt)}
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  )
}